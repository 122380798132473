import * as pako from 'pako'

export function deflate(input: Uint8Array) {
  return btoa(String.fromCodePoint(...pako.deflate(input)))
}

export function inflate(output: string, duration?): number[] {
  const array = Uint8Array.from(
    [...atob(output)].map((index) => index.codePointAt(0) || 0)
  )
  const inflatedProgressmap = pako.inflate(array)
  const map = [...inflatedProgressmap]
  console.log('inflating map with a duration of ' + duration)
  return duration ? map.slice(0, Math.floor(duration) + 1) : map
}

export function createProgressMap(duration = 0) {
  return Array.from({
    length: Math.floor(duration) + 1,
  }).fill(0) as number[]
}

export function getYouTubeID(url: string) {
  let ID = ''
  const urls = url
    .replaceAll(/(>|<)/gi, '')
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
  ID = urls[2] === undefined ? url : urls[2].split(/[^\w-]/i)[0]
  return ID
}

export function getVimeoID(url: string) {
  const regex =
    /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/([a-z]*\/)*(\d{6,11})\??.*/
  const match = url.match(regex)

  return match && match[5] ? match[5] : url
}

export function isYouTubeVideoURL(url: string) {
  return url.includes('youtube') || url.includes('youtu.be')
}

export function isVimeoVideoURL(url: string) {
  return url.includes('vimeo')
}
