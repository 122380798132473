/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable unicorn/no-useless-undefined */
import {
  DeleteOutlined,
  EditOutlined,
  DownOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  BarChartOutlined,
} from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import {
  Checkbox,
  Button,
  Form,
  Input,
  notification,
  PageHeader,
  Popconfirm,
  Select,
  Table,
  Tag,
  CheckboxOptionType,
  Tooltip,
  Alert,
  Divider,
  Space,
} from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import { useForm } from 'antd/lib/form/Form'
import Modal from 'antd/lib/modal/Modal'
import { ColumnsType } from 'antd/lib/table'
import { ObjectId } from 'bson'
import dayjs, { Dayjs, extend } from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { groupBy } from 'lodash-es'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  CreateUserMutation,
  UpdateUserMutation,
  DeleteUserMutation,
  UsersQuery,
  CertificationTypesQuery,
  FieldsQuery,
  FieldParent,
  ChannelsQuery,
} from 'apps/lms-front/src/generated/graphql'
import { useAuth } from 'apps/lms-front/src/modules/auth/hooks/use-auth'
import { useBranch } from 'apps/lms-front/src/modules/auth/hooks/use-branch'

import { AbilityContext, Can } from '../../../auth/components/Can'
import { useHierarchyTree } from '../../../branch/hooks/use-hierarchy-tree'
import { setSessionStorageItem } from '../../../core/utils/session-storage'
import { ActionButtonWrapper } from '../../../shared/components/action-button-wrapper/ActionButtonWrapper'
import { CustomFieldsFormSection } from '../../../shared/components/custom-fields-form-section/CustomFieldsFormSection'
import DatePicker from '../../../shared/components/date-picker/DatePicker'
import { DropdownButton } from '../../../shared/components/dynamic-dropdown-button/DropdownButton'
import { InputSearch } from '../../../shared/components/input-search/InputSearch'
import { TreeSelect } from '../../../shared/components/tree-select/TreeSelect'
import { errorNotifierFn } from '../../../shared/helpers/error-notifier'
import { reduceDuplicateLabels } from '../../../shared/helpers/reduce-duplicate-labels'

import CREATE_USER_MUTATION from './../../mutations/create-user.graphql'
import DELETE_USER_MUTATION from './../../mutations/delete-user.graphql'
import UPDATE_USER_MUTATION from './../../mutations/update-user.graphql'
import CERTIFICATION_TYPES_QUERY from './../../queries/certification-types.graphql'
import CHANNELS_QUERY from './../../queries/channels.graphql'
import FIELDS_QUERY from './../../queries/fields.graphql'
import USERS_QUERY from './../../queries/users.graphql'

extend(utc)
extend(timezone)
extend(relativeTime)
extend(isSameOrAfter)

interface UserRow {
  key: string
  name: string
  email: string
  created?: Date
  lastActive?: Date
  disabled?: boolean | null
  enableFrom?: Date
  enableUntil?: Date
  channels: UsersQuery['fetchUsers']['results'][0]['channels']
  certificationType: UsersQuery['fetchUsers']['results'][0]['certificationType']
  branch_id?: string
}

export const Users = () => {
  const auth = useAuth()
  const navigate = useNavigate()
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [searchTerm, setSearchTerm] = useQueryParam('q', StringParam)
  const [hierarchyFilter, setHierarchyFilter] = useQueryParam(
    'section',
    StringParam
  )
  const [sortField, setSortField] = useState<string>()
  const [sortOrder, setSortOrder] = useState<number>()
  const [userModalVisible, setUserModalVisible] = useState(false)
  const [deletePopConfirmIndex, setDeletePopConfirmIndex] = useState<string>()
  const [deletePopConfirmVisible, setDeletePopConfirmVisible] = useState(false)
  const [updateSubject, setUpdateSubject] =
    useState<UsersQuery['fetchUsers']['results'][0]>()
  const [form] = useForm()

  const disabled = Form.useWatch('disabled', form)
  const branches = Form.useWatch('branches', form)

  const branch = useBranch()
  const ability = useContext(AbilityContext)

  const { data: treeData, loading: treeLoading } = useHierarchyTree({
    all: ability.can(PermissionAction.READ, PermissionObjectType.HIERARCHY),
  })

  const { data, loading } = useQuery<UsersQuery>(USERS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      query: searchTerm,
      section_id: hierarchyFilter || undefined,
      page,
      limit: pageSize,
      sort_by: sortField,
      sort_order: sortOrder,
    },
  })

  const { data: certificationTypes } = useQuery<CertificationTypesQuery>(
    CERTIFICATION_TYPES_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      skip: ability.cannot(
        PermissionAction.READ,
        PermissionObjectType.CERTIFICATION_TYPE
      ),
    }
  )

  const { data: channels } = useQuery<ChannelsQuery>(CHANNELS_QUERY, {
    fetchPolicy: 'cache-and-network',
    skip: ability.cannot(PermissionAction.READ, PermissionObjectType.CHANNEL),
  })

  const { data: fields } = useQuery<FieldsQuery>(FIELDS_QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  const [createUser, { loading: creating }] =
    useMutation<CreateUserMutation>(CREATE_USER_MUTATION)

  const [updateUser, { loading: updating }] =
    useMutation<UpdateUserMutation>(UPDATE_USER_MUTATION)

  const [deleteUser, { loading: deleting }] =
    useMutation<DeleteUserMutation>(DELETE_USER_MUTATION)

  const processedBranches = useMemo(() => {
    return (
      branches
        ?.map((branch) => {
          const section = treeData.find(
            (node) => node.id === branch?.section_id
          )
          if (!section) return null
          return {
            branch_id: section.branch_id,
            section_id:
              section.branch_id === section.id ? undefined : section.id,
            role_id: branch.role_id || null,
          }
        })
        .filter(Boolean) || []
    )
  }, [branches, treeData])

  useEffect(() => {
    if (updateSubject) {
      setUserModalVisible(true)
    }
  }, [updateSubject])

  useEffect(() => {
    if (userModalVisible) form.resetFields()
  }, [userModalVisible, form])

  const selectedUser = useMemo(() => {
    if (!updateSubject) return
    let role
    const _branch = updateSubject?.branches?.find((b) =>
      new ObjectId(b.branch_id).equals(branch?._id)
    )
    if (_branch) role = _branch.role_id
    return {
      ...updateSubject,
      role,
    }
  }, [updateSubject, branch?._id])

  const userRoleOptions = useMemo(() => {
    return Object.values(
      groupBy(
        data?.fetchUserRoles.map((role) => ({
          ...role,
          branch_name:
            role.branch_name === ''
              ? role.level >= 100
                ? t({
                    id: 'settings.users.form.label.pro',
                    message: 'Pro',
                  })
                : t({
                    id: 'settings.users.form.label.default',
                    message: 'Standaard',
                  })
              : role.branch_name,
        })),
        ({ branch_name }) => branch_name
      )
    ).map((roles) => {
      const branch =
        roles[0].branch_name ||
        t({
          id: 'settings.users.form.label.default',
          message: 'Standaard',
        })
      return {
        label: branch,
        options: roles.map((userRole) => ({
          value: userRole._id,
          label: userRole.name,
          disabled: userRole.level < (auth.user?.role_level ?? 9999),
        })),
      }
    })
  }, [data?.fetchUserRoles, auth.user?.role_level])

  const users = useMemo(() => {
    return (data?.fetchUsers.results || []).map<UserRow>(
      (item: UsersQuery['fetchUsers']['results'][0]) => ({
        key: item._id,
        name: `${item.firstName} ${item.lastName}`,
        email: item.email,
        created: item.enableFrom || item.created,
        lastActive: item.lastActive,
        disabled: item.disabled,
        certificationType: item.certificationType,
        channels: item.channels,
        branch_id: item.branches?.length
          ? item.branches[0].branch_id
          : undefined,
        enableFrom: item.enableFrom,
        enableUntil: item.enableUntil,
      })
    )
  }, [data])

  const columns: ColumnsType<UserRow> = [
    {
      title: t({
        id: 'settings.users.table.name',
        message: 'Naam',
      }),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      fixed: 'left',
      render: (text: string, item: UserRow) => (
        <Space>
          {text}
          {item.certificationType
            ?.reduce<{ _id: string; name: string }[]>(reduceDuplicateLabels, [])
            .map((type) => (
              <Tag key={type._id} color="blue">
                {type.name}
              </Tag>
            ))}
          {item.disabled && dayjs().isBefore(item.enableFrom) && (
            <Tag color="green">
              <Trans id="tag.planned">Gepland</Trans>
            </Tag>
          )}
          {!item.disabled &&
            !dayjs().isBefore(item.enableFrom) &&
            dayjs().isBefore(item.enableUntil) && (
              <Tag color="orange">
                <Trans id="tag.temp_active">Tijdelijk actief</Trans>
              </Tag>
            )}
          {item.disabled && !dayjs().isBefore(item.enableFrom) && (
            <Tag color="red">
              <Trans id="tag.inactive">Inactief</Trans>
            </Tag>
          )}
        </Space>
      ),
    },
    {
      title: t({
        id: 'settings.users.table.email',
        message: 'E-mail',
      }),
      dataIndex: 'email',
      key: 'email',
      sorter: true,
    },
    {
      title: t({
        id: 'settings.users.table.created',
        message: 'Registratie',
      }),
      dataIndex: 'created',
      key: 'created',
      sorter: true,
      render: (text: string) =>
        dayjs(text).tz('Europe/Brussels').format('DD/MM/YYYY'),
      // render: (text: string) => dayjs(text).tz('Europe/Brussels').fromNow(),
    },
    {
      title: t({
        id: 'settings.users.table.enabled_until',
        message: 'Non-actief op',
      }),
      dataIndex: 'enableUntil',
      key: 'enableUntil',
      sorter: true,
      render: (text: string) =>
        text ? dayjs(text).tz('Europe/Brussels').format('DD/MM/YYYY') : '',
      // render: (text: string) => dayjs(text).tz('Europe/Brussels').fromNow(),
    },
    {
      title: t({
        id: 'settings.users.table.last_active',
        message: 'Laatst actief',
      }),
      dataIndex: 'lastActive',
      key: 'lastActive',
      sorter: true,
      render: (text: string) =>
        text && dayjs(text).tz('Europe/Brussels').fromNow(),
    },
    {
      title: t({
        id: 'settings.users.table.actions',
        message: 'Acties',
      }),
      key: 'operation',
      fixed: 'right',
      width: 160,
      render: (_: string, record: UserRow) => (
        <ActionButtonWrapper>
          <Can I={PermissionAction.READ} a={PermissionObjectType.REPORT}>
            <Tooltip
              title={t({
                id: 'settings.users.table.reports',
                message: 'Rapporten',
              })}
            >
              <Button
                onClick={() => {
                  if (record.branch_id)
                    sessionStorage.setItem('aa_report_filter', record.branch_id)
                  navigate(
                    `/reports/users?query=${encodeURIComponent(
                      record.name
                    )}&branch_id=${encodeURIComponent(
                      record.branch_id || branch?._id
                    )}&expand=${record.key}`
                  )
                }}
                shape="circle"
                icon={<BarChartOutlined />}
              />
            </Tooltip>
          </Can>
          <Can I={PermissionAction.UPDATE} a={PermissionObjectType.USER}>
            <Tooltip
              title={t({
                id: 'actions.edit',
                message: 'Bewerken',
              })}
            >
              <Button
                onClick={() => {
                  setUpdateSubject(
                    data?.fetchUsers.results.find((user) =>
                      new ObjectId(user._id).equals(record.key)
                    )
                  )
                }}
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>
          </Can>
          <Can I={PermissionAction.DELETE} a={PermissionObjectType.USER}>
            <Tooltip
              title={t({
                id: 'actions.delete',
                message: 'Verwijderen',
              })}
            >
              <Popconfirm
                placement={'left'}
                title={t({
                  id: 'settings.users.delete.confirm',
                  message:
                    'Ben je zeker dat je deze gebruiker wil verwijderen?',
                })}
                open={
                  deletePopConfirmIndex === record.key &&
                  deletePopConfirmVisible
                }
                okType="danger"
                okText={t({
                  id: 'actions.delete',
                  message: 'Verwijderen',
                })}
                cancelText={t({
                  id: 'actions.cancel',
                  message: 'Annuleren',
                })}
                okButtonProps={{ loading: deleting }}
                onConfirm={() => {
                  deleteUser({
                    variables: {
                      id: record.key,
                    },
                    refetchQueries: ['users'],
                  })
                    .then(() => {
                      notification.success({
                        message: t({
                          id: 'settings.users.delete.success',
                          message: 'Gebruiker succesvol verwijderd',
                        }),
                      })
                    })
                    .catch(errorNotifierFn)
                    .finally(() => setDeletePopConfirmVisible(false))
                }}
                onCancel={() => setDeletePopConfirmVisible(false)}
              >
                <Button
                  onClick={() => {
                    setDeletePopConfirmIndex(record.key)
                    setDeletePopConfirmVisible(true)
                  }}
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Tooltip>
          </Can>
        </ActionButtonWrapper>
      ),
    },
  ]

  const processFields = (fields) => {
    const { role, branches, ...rest } = fields

    const enableFrom = rest.enableFrom
      ? dayjs(rest.enableFrom).startOf('day').toDate()
      : undefined

    const enableUntil = rest.enableUntil
      ? dayjs(rest.enableUntil).startOf('day').toDate()
      : undefined

    return {
      ...rest,
      enableFrom,
      enableUntil,
      role: role || (branches ? undefined : null),
      branches: processedBranches,
    }
  }

  const handleCreation = async () => {
    try {
      const fields = await form.validateFields()
      const processedData = processFields(fields)
      await createUser({
        variables: processedData,
        refetchQueries: ['users'],
      })

      notification.success({
        message: t({
          id: 'settings.users.create.success',
          message: 'Gebruiker succesvol aangemaakt',
        }),
      })

      setUserModalVisible(false)
    } catch (error) {
      if (error instanceof Error) {
        notification.error({
          message: error.message,
        })
      } else {
        notification.error({
          message: t({
            id: 'error.general',
            message: 'Er is een fout opgetreden. Probeer later opnieuw.',
          }),
        })
      }
    }
  }

  const handleUpdate = async (
    value: unknown & {
      role: unknown
      branches: {
        section_id: string
        role_id: string
      }[]
      enableUntil: Dayjs
      enableFrom: Dayjs
    }
  ) => {
    try {
      const processedData = processFields(value)
      await updateUser({
        variables: {
          id: updateSubject?._id,
          ...processedData,
          enableUntil: processedData.enableUntil || null,
          enableFrom: processedData.enableFrom || null,
        },
        refetchQueries: ['users'],
      })

      notification.success({
        message: t({
          id: 'settings.users.update.success',
          message: 'Gebruiker succesvol gewijzigd',
        }),
      })

      setUserModalVisible(false)
    } catch (error) {
      if (error instanceof Error) {
        notification.error({
          message: error.message,
        })
      } else {
        notification.error({
          message: t({
            id: 'error.general',
            message: 'Er is een fout opgetreden. Probeer later opnieuw.',
          }),
        })
      }
    }
  }

  const handleEnableDateChange = async (form: FormInstance<unknown>) => {
    const today = dayjs().startOf('day')
    const from = form.getFieldValue('enableFrom')?.startOf('day')
    const until = form.getFieldValue('enableUntil')?.startOf('day')

    const shouldBeEnabled =
      (from && until && today.isSameOrAfter(from) && today.isBefore(until)) ||
      (from && !until && today.isSameOrAfter(from)) ||
      (!from && until && today.isBefore(until))

    const shouldBeDisabled =
      (from && until && (today.isBefore(from) || today.isSameOrAfter(until))) ||
      (from && !until && today.isBefore(from)) ||
      (!from && until && today.isSameOrAfter(until))

    if (shouldBeEnabled) {
      return form.setFieldValue('disabled', false)
    }

    if (shouldBeDisabled) {
      return form.setFieldValue('disabled', true)
    }
  }

  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={t({
          id: 'settings.users.title',
          message: 'Gebruikers',
        })}
        style={{ backgroundColor: '#FFF' }}
        extra={[
          <InputSearch
            key="1"
            defaultValue={searchTerm || ''}
            placeholder={t({
              id: 'settings.users.search',
              message: 'Zoeken op naam',
            })}
            onSearch={(value) => {
              setSearchTerm(value)
              setPage(1)
            }}
            style={{ width: 200 }}
          />,
          (ability.can(PermissionAction.READ, PermissionObjectType.BRANCH) ||
            ability.can(
              PermissionAction.READ,
              PermissionObjectType.BRANCH_HIERARCHY
            )) && (
            <TreeSelect
              key="2"
              placeholder={t({
                id: 'users.filter.hierarchy',
                message: 'Filter op afdeling',
              })}
              treeDefaultExpandAll={false}
              treeLine={true}
              showSearch
              treeDataSimpleMode
              style={{ width: 250 }}
              dropdownMatchSelectWidth={false}
              filterTreeNode={(input, option) =>
                (option.title as string)
                  ?.toLowerCase()
                  .includes(input.toLowerCase())
              }
              dropdownStyle={{
                maxHeight: 400,
                overflow: 'auto',
              }}
              treeData={treeData}
              loading={treeLoading}
              allowClear={true}
              treeNodeLabelProp="label"
              value={hierarchyFilter}
              onChange={(value) => {
                setSessionStorageItem('aa_report_filter', value)
                setHierarchyFilter(value)
                setPage(1)
              }}
            />
          ),
          <Can
            key="3"
            I={PermissionAction.CREATE}
            a={PermissionObjectType.USER}
          >
            <DropdownButton
              icon={<DownOutlined />}
              onClick={() => setUserModalVisible(true)}
              type="primary"
              menu={{
                onClick: ({ key }) => navigate(`/settings/${key}`),
                items: [
                  {
                    key: 'import-users',
                    label: t({
                      id: 'settings.users.import',
                      message: 'Gebruikers importeren',
                    }),
                    disabled: !ability.can(
                      PermissionAction.IMPORT,
                      PermissionObjectType.USER
                    ),
                  },
                  {
                    key: 'export-users',
                    label: t({
                      id: 'settings.users.export',
                      message: 'Gebruikers exporteren',
                    }),
                    disabled: !ability.can(
                      PermissionAction.EXPORT,
                      PermissionObjectType.USER
                    ),
                  },
                ],
              }}
            >
              <Trans id="settings.users.action.create">
                Gebruiker aanmaken
              </Trans>
            </DropdownButton>
          </Can>,
        ]}
      />
      <Table
        locale={{
          emptyText: t({
            id: 'settings.users.table.empty',
            message: 'Geen gebruikers gevonden.',
          }),
          cancelSort: t({
            id: 'table.sort.cancel',
            message: 'Klik om niet langer te sorteren.',
          }),
          triggerAsc: t({
            id: 'table.sort.asc',
            message: 'Klik om oplopend te sorteren.',
          }),
          triggerDesc: t({
            id: 'table.sort.desc',
            message: 'Klik om aflopend te sorteren.',
          }),
        }}
        scroll={{ x: 400 }}
        dataSource={users}
        loading={loading}
        columns={columns}
        showSorterTooltip={false}
        onChange={(_, __, sorterInfo) => {
          if (!('length' in sorterInfo)) {
            setSortField(String(sorterInfo.field))
            setSortOrder(
              sorterInfo.order === 'ascend'
                ? 1
                : sorterInfo.order === 'descend'
                ? -1
                : undefined
            )
          }
        }}
        pagination={{
          current: page,
          onChange: (page: number) => {
            setPage(page)
            document.body.scrollTop = 0 // For Safari
            document.documentElement.scrollTop = 0
          },
          onShowSizeChange(_, size) {
            setPage(1)
            setPageSize(size)
          },
          total: data?.fetchUsers.count,
        }}
      />
      <Modal
        forceRender
        title={
          updateSubject
            ? t({
                id: 'settings.users.update.title',
                message: 'Gebruiker wijzigen',
              })
            : t({
                id: 'settings.users.create.title',
                message: 'Nieuwe gebruiker aanmaken',
              })
        }
        open={userModalVisible}
        onOk={() => form.submit()}
        confirmLoading={creating || updating}
        onCancel={() => {
          setUserModalVisible(false)
        }}
        afterClose={() => setUpdateSubject(undefined)}
        cancelText={t({
          id: 'actions.cancel',
          message: 'Annuleren',
        })}
        okText={
          updateSubject
            ? t({
                id: 'actions.update',
                message: 'Wijzigen',
              })
            : t({
                id: 'actions.create',
                message: 'Aanmaken',
              })
        }
        width={640}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={updateSubject ? handleUpdate : handleCreation}
          initialValues={
            selectedUser
              ? {
                  ...selectedUser,
                  branches: selectedUser.branches?.map((branch) => {
                    return {
                      section_id: branch.section_id || branch.branch_id,
                      role_id: branch.role_id,
                    }
                  }),
                  certificationType: selectedUser?.certificationType?.map(
                    (type) => type._id
                  ),
                  channels: selectedUser?.channels?.map(
                    (channel) => channel._id
                  ),
                  meta: undefined,
                  enableFrom:
                    selectedUser.enableFrom && dayjs(selectedUser.enableFrom),
                  enableUntil:
                    selectedUser.enableUntil && dayjs(selectedUser.enableUntil),
                }
              : undefined
          }
          autoComplete="off"
        >
          <Form.Item
            label={t({
              id: 'settings.users.form.label.first_name',
              message: 'Voornaam',
            })}
            name="firstName"
            rules={[
              {
                required: true,
                message: t({
                  id: 'settings.users.form.validation.first_name',
                  message: 'Gelieve de voornaam in te vullen',
                }),
              },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item
            label={t({
              id: 'settings.users.form.label.last_name',
              message: 'Naam',
            })}
            name="lastName"
            rules={[
              {
                required: true,
                message: t({
                  id: 'settings.users.form.validation.last_name',
                  message: 'Gelieve de naam in te vullen',
                }),
              },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item
            label={t({
              id: 'settings.users.form.label.email',
              message: 'E-mailadres',
            })}
            name="email"
            rules={[
              {
                type: 'email',
                required: true,
                message: t({
                  id: 'settings.users.form.validation.email',
                  message: 'Gelieve het e-mailadres in te geven',
                }),
              },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>

          <Form.Item
            label={t({
              id: 'settings.users.form.label.password',
              message: 'Wachtwoord',
            })}
            name="password"
            rules={[
              {
                required: false,
                message: t({
                  id: 'settings.users.form.validation.password',
                  message: 'Gelieve het wachtwoord in te geven',
                }),
              },
            ]}
          >
            <Input.Password
              placeholder={
                updateSubject
                  ? t({
                      id: 'settings.users.form.placeholder.password',
                      message: 'Laat leeg om ongewijzigd te laten.',
                    })
                  : t({
                      id: 'settings.users.form.create.placeholder.password',
                      message:
                        'Laat leeg om een random wachtwoord te genereren.',
                    })
              }
              autoComplete="new-password"
            />
          </Form.Item>

          {!!channels?.fetchChannels?.length && (
            <Form.Item
              label={t({
                id: 'settings.users.form.label.channels',
                message: 'Kennisbronnen',
              })}
              name="channels"
            >
              <Checkbox.Group
                options={channels?.fetchChannels.map(
                  (type) =>
                    ({
                      label: type.name,
                      value: type._id as string,
                    }) as CheckboxOptionType
                )}
              />
            </Form.Item>
          )}

          {!!certificationTypes?.fetchCertificationTypes.filter(
            (type) =>
              !type.branch_id ||
              processedBranches?.some((a) =>
                new ObjectId(a.branch_id).equals(type.branch_id || '')
              )
          )?.length && (
            <Form.Item
              label={t({
                id: 'settings.users.form.label.certification_types',
                message: 'Certificeringstypes',
              })}
              name="certificationType"
              help={
                certificationTypes?.fetchCertificationTypes.filter(
                  (type) =>
                    processedBranches
                      ?.filter(Boolean)
                      .some((a) =>
                        new ObjectId(a.branch_id).equals(type.branch_id || '')
                      )
                ).length > 0 ? (
                  <p>
                    {t({
                      id: 'settings.users.form.help.certification_types',
                      message: '* op afdelingsniveau',
                    })}
                  </p>
                ) : null
              }
            >
              <Checkbox.Group
                options={certificationTypes?.fetchCertificationTypes
                  .filter(
                    (type) =>
                      !type.branch_id ||
                      processedBranches?.some((a) =>
                        new ObjectId(a.branch_id).equals(type.branch_id || '')
                      )
                  )
                  .map(
                    (type) =>
                      ({
                        label: `${type.name}${type.branch_id ? ' *' : ''}`,
                        value: type._id as string,
                      }) as CheckboxOptionType
                  )}
              />
            </Form.Item>
          )}

          <Can I={PermissionAction.ASSIGN} a={PermissionObjectType.ROLE}>
            <Can I={PermissionAction.READ} a={PermissionObjectType.BRANCH}>
              <Form.List name="branches">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          paddingTop: 24,
                          paddingBottom: 24,
                          borderTop: '1px solid #f0f0f0',
                        }}
                        key={key}
                      >
                        <Space
                          size={0}
                          style={{ width: '100%' }}
                          direction="vertical"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, 'section_id']}
                            label={t({
                              id: 'settings.users.form.label.branch',
                              message: 'Afdeling',
                            })}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            style={{ flex: 1 }}
                          >
                            <TreeSelect
                              treeDefaultExpandAll={true}
                              treeLine={true}
                              showSearch
                              treeDataSimpleMode
                              style={{ width: '100%' }}
                              dropdownMatchSelectWidth={false}
                              filterTreeNode={(input, option) =>
                                (option.title as string)
                                  ?.toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                              }}
                              treeData={treeData}
                              loading={treeLoading}
                              allowClear={false}
                              treeNodeLabelProp="label"
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'role_id']}
                            label={t({
                              id: 'settings.users.form.label.role',
                              message: 'Gebruikersrol',
                            })}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            style={{ flex: 1, margin: 0 }}
                          >
                            <Select
                              allowClear
                              options={[...userRoleOptions].sort((a, b) => {
                                const order = [
                                  t({
                                    id: 'settings.users.form.label.pro',
                                    message: 'Pro',
                                  }),
                                  t({
                                    id: 'settings.users.form.label.default',
                                    message: 'Standaard',
                                  }),
                                ]
                                return (
                                  order.indexOf(b.label) -
                                  order.indexOf(a.label)
                                )
                              })}
                            />
                          </Form.Item>
                        </Space>
                        <MinusCircleOutlined
                          style={{ padding: 8, paddingRight: 0 }}
                          onClick={() => remove(name)}
                        />
                      </div>
                    ))}
                    <Divider style={{ marginTop: 0, marginBottom: 24 }} />
                    <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        {' '}
                        <Trans id="settings.users.form.add_role">
                          Nieuwe rol toewijzen
                        </Trans>
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Can>
          </Can>
          <>
            <Divider style={{ margin: 24 }} />
            <CustomFieldsFormSection
              parent={FieldParent.User}
              fields={fields?.fetchFields}
              initialValues={selectedUser?.meta}
              admin={true}
            />
          </>

          <Divider style={{ margin: 24 }} />

          <Form.Item
            name="enableFrom"
            label={t({
              id: 'settings.users.form.label.enabled_from',
              message: 'Actief vanaf',
            })}
            tooltip={t({
              id: 'settings.users.form.tooltip.enabled_from',
              message: 'Op deze datum wordt de gebruiker geactiveerd.',
            })}
            rules={[
              {
                validator: async (_, value) => {
                  // check if this date is before the enableUntil date
                  if (
                    value &&
                    form.getFieldValue('enableUntil') &&
                    value.isAfter(form.getFieldValue('enableUntil'))
                  ) {
                    throw new Error(
                      t({
                        id: 'settings.users.form.validation.enabled_from',
                        message:
                          'Deze datum moet voor de "actief tot" datum zijn',
                      })
                    )
                  }
                },
              },
            ]}
          >
            <DatePicker
              allowClear={true}
              format="DD/MM/YYYY"
              style={{ width: '100%' }}
              onChange={() => handleEnableDateChange(form)}
            />
          </Form.Item>
          <Form.Item
            name="enableUntil"
            label={t({
              id: 'settings.users.form.label.enabled_until',
              message: 'Actief tot',
            })}
            tooltip={t({
              id: 'settings.users.form.tooltip.enabled_until',
              message: 'Op deze datum wordt de gebruiker gedeactiveerd.',
            })}
            rules={[
              {
                validator: async (_, value) => {
                  //check if this date is after the enableFrom date
                  if (
                    value &&
                    form.getFieldValue('enableFrom') &&
                    value.isBefore(form.getFieldValue('enableFrom'))
                  ) {
                    throw new Error(
                      t({
                        id: 'settings.users.form.validation.enabled_until',
                        message:
                          'Deze datum moet na de "actief vanaf" datum zijn',
                      })
                    )
                  }
                },
              },
            ]}
          >
            <DatePicker
              allowClear={true}
              format="DD/MM/YYYY"
              style={{ width: '100%' }}
              onChange={() => handleEnableDateChange(form)}
            />
          </Form.Item>
          <Form.Item
            name="disabled"
            valuePropName="checked"
            wrapperCol={{ sm: { offset: 8, span: 16 } }}
            rules={[
              {
                validator: async (_, value) => {
                  const today = dayjs().startOf('day')
                  const from = form.getFieldValue('enableFrom')?.startOf('day')
                  const until = form
                    .getFieldValue('enableUntil')
                    ?.startOf('day')

                  const shouldBeEnabled =
                    (from &&
                      until &&
                      today.isSameOrAfter(from) &&
                      today.isBefore(until)) ||
                    (from && !until && today.isSameOrAfter(from)) ||
                    (!from && until && today.isBefore(until))

                  const shouldBeDisabled =
                    (from &&
                      until &&
                      (today.isBefore(from) || today.isSameOrAfter(until))) ||
                    (from && !until && today.isBefore(from)) ||
                    (!from && until && today.isSameOrAfter(until))

                  if (shouldBeEnabled && !!value) {
                    throw new Error(
                      t({
                        id: 'settings.users.form.validation.enabled',
                        message:
                          'Deze gebruiker zou actief moeten zijn volgens de opgegeven data.',
                      })
                    )
                  }

                  if (shouldBeDisabled && !value) {
                    throw new Error(
                      t({
                        id: 'settings.users.form.validation.disabled',
                        message:
                          'Deze gebruiker zou gedeactiveerd moeten zijn volgens de opgegeven data.',
                      })
                    )
                  }
                },
              },
            ]}
          >
            <Checkbox>
              <Trans id="settings.users.form.label.deactivate_account">
                Account deactiveren
              </Trans>
            </Checkbox>
          </Form.Item>
          {!updateSubject && !disabled && (
            <Form.Item
              name="notify"
              valuePropName="checked"
              wrapperCol={{ sm: { offset: 8, span: 16 } }}
            >
              <Checkbox>
                <Trans id="settings.users.form.label.notify">
                  Gebruiker via e-mail op de hoogte brengen van account
                  (inclusief wachtwoord)
                </Trans>
              </Checkbox>
            </Form.Item>
          )}
          <Alert
            type="warning"
            showIcon={true}
            message={
              <Trans id="settings.users.form.warning">
                <strong>Let op</strong>: sommige browsers overschrijven
                automatisch naam- en e-mailvelden. Kijk dit formulier dus goed
                na voor je een wijziging doorvoert.
              </Trans>
            }
          />
          <Button hidden disabled={creating} type="primary" htmlType={'submit'}>
            <Trans id="actions.save">Opslaan</Trans>
          </Button>
        </Form>
      </Modal>
    </>
  )
}
