import styled from 'styled-components'

// Styled container
const Container = styled.div`
  width: 100%;
`

// Styled inner container with specific styles
const InnerContainer = styled.div`
  padding: 1rem 1.25rem;
  background-color: #2d3748; // bg-gray-800
  color: #f7fafc; // text-gray-100
  font-family: monospace; // font-mono
  font-size: 0.875rem; // text-sm
  line-height: normal; // leading-normal
  border-radius: 0.5rem; // rounded-lg
  shadow: lg; // shadow-lg
  overflow: hidden;
`

// Styled command line section
const CommandLine = styled.div`
  display: flex;
  margin-top: 1rem; // mt-4
`

// Styled typing area
const TypingArea = styled.p`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 0.5rem; // pl-2
  flex: 1;

  em {
    color: #48bb78; // text-green-400
    font-style: normal;
    margin-right: 0.5rem; // mr-2
  }
`

// Component
export const Terminal = ({ children }: { children: string }) => (
  <Container>
    <InnerContainer>
      <CommandLine>
        <TypingArea dangerouslySetInnerHTML={{ __html: children }} />
      </CommandLine>
    </InnerContainer>
  </Container>
)
