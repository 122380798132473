import { UploadOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'
import {
  Avatar,
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Space,
  Switch,
  Upload,
} from 'antd'
import dayjs, { extend } from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useState } from 'react'

import {
  FieldParent,
  FieldType,
  FieldsQuery,
} from 'apps/lms-front/src/generated/graphql'

import { getBase64 } from '../../utils/get-base64'
import {
  FileType,
  uploadValidator,
} from '../../validators/upload-file-validator'

extend(utc)
extend(timezone)
extend(relativeTime)
extend(isSameOrAfter)

type CustomFieldsFormSectionProps = {
  parent: FieldParent
  initialValues?: unknown
  fields?: FieldsQuery['fetchFields']
  admin: boolean
  readonly?: boolean
}

export const CustomFieldsFormSection = ({
  parent,
  initialValues: meta,
  fields,
  admin,
  readonly = false,
}: CustomFieldsFormSectionProps) => {
  return fields
    ?.filter((field) => field.parent === parent)
    .filter((field) => !field.hidden)
    .map((field) => {
      if (field.type === FieldType.Boolean) {
        return (
          <Form.Item
            key={field.key}
            label={field.name}
            name={['meta', field.key]}
            hidden={!field.public && !admin}
            valuePropName="checked"
            initialValue={meta ? !!meta[field.key] : false}
          >
            <Switch disabled={readonly} />
          </Form.Item>
        )
      }
      if (field.type === FieldType.TextLong)
        return (
          <Form.Item
            key={field.key}
            label={field.name}
            name={['meta', field.key]}
            hidden={!field.public && !admin}
            initialValue={meta ? meta[field.key] || undefined : undefined}
          >
            <Input.TextArea cols={3} disabled={readonly} />
          </Form.Item>
        )
      if (field.type === FieldType.Number)
        return (
          <Form.Item
            key={field.key}
            label={field.name}
            name={['meta', field.key]}
            hidden={!field.public && !admin}
            initialValue={
              meta
                ? meta[field.key]
                  ? Number.parseInt(meta[field.key])
                  : undefined
                : undefined
            }
          >
            <InputNumber disabled={readonly} />
          </Form.Item>
        )
      if (field.type === FieldType.Date)
        return (
          <Form.Item
            key={field.key}
            label={field.name}
            name={['meta', field.key]}
            hidden={!field.public && !admin}
            initialValue={
              meta
                ? meta[field.key]
                  ? dayjs(meta[field.key])
                  : undefined
                : undefined
            }
          >
            <DatePicker
              allowClear={true}
              format="DD/MM/YYYY"
              style={{ width: '100%' }}
              disabled={readonly}
            />
          </Form.Item>
        )

      if (field.type === FieldType.Thumbnail)
        return (
          <ThumbnailField
            field={field}
            meta={meta}
            admin={admin}
            readonly={readonly}
          />
        )
      return (
        <Form.Item
          key={field.key}
          label={field.name}
          name={['meta', field.key]}
          hidden={!field.public && !admin}
          initialValue={meta ? meta[field.key] || undefined : undefined}
        >
          <Input disabled={readonly} />
        </Form.Item>
      )
    })
}

export const ThumbnailField = ({
  field,
  meta,
  admin,
  readonly,
}: {
  field: FieldsQuery['fetchFields'][0]
  meta: unknown
  admin: boolean
  readonly: boolean
}) => {
  const [uploading, setUploading] = useState(false)

  const form = Form.useFormInstance()
  const watched_meta = Form.useWatch('meta', form)

  return (
    <Form.Item
      key={field.key}
      label={field.name}
      name={['meta', field.key]}
      hidden={!field.public && !admin}
      initialValue={meta ? meta[field.key] || undefined : undefined}
      rules={[
        {
          type: 'url',
        },
      ]}
      extra={
        <Upload
          showUploadList={false}
          action={`${
            import.meta.env.NX_BACKEND_URL
          }/api/files/upload/image/100/100`}
          beforeUpload={uploadValidator(5, [
            FileType.jpg,
            FileType.png,
            FileType.gif,
          ])}
          headers={{
            Authorization: `Bearer ${localStorage.getItem('aa_lms_at')}`,
            'x-academy-host': window.location.hostname,
          }}
          accept=".jpg,.jpeg,.png,.gif"
          onChange={(info) => {
            if (!uploading && info.file.status === 'uploading') {
              setUploading(true)
            } else if (info.file.status === 'done') {
              getBase64(info.file.originFileObj, () => {
                setUploading(false)
                form.setFieldsValue({
                  meta: {
                    ...watched_meta,
                    [field.key]: info.file.response.url,
                  },
                })
              })
            } else {
              setUploading(false)
            }
          }}
        >
          <Button loading={uploading}>
            <Space>
              <UploadOutlined />
              <Trans id="action.upload_thumb">Thumbnail uploaden</Trans>
            </Space>
          </Button>
        </Upload>
      }
    >
      <Input
        className="input-thumbnail"
        disabled={readonly || uploading}
        placeholder="https://example.com/image.jpg"
        addonBefore={
          isValidUrl(watched_meta?.[field.key]) && (
            <Avatar
              shape="square"
              src={
                isValidUrl(watched_meta?.[field.key])
                  ? watched_meta?.[field.key]
                  : undefined
              }
            />
          )
        }
      />
    </Form.Item>
  )
}

function isValidUrl(string) {
  try {
    new URL(string)
    return true
  } catch {
    return false
  }
}
