/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable unicorn/no-useless-undefined */
import {
  DeleteOutlined,
  EditOutlined,
  DownOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  LoadingOutlined,
  UploadOutlined,
  PaperClipOutlined,
  LinkOutlined,
} from '@ant-design/icons'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import {
  Button,
  Checkbox,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  notification,
  PageHeader,
  Popconfirm,
  Select,
  Space,
  Table,
  Tabs,
  Tag,
  Tooltip,
} from 'antd'
import { CheckboxOptionType } from 'antd/es/checkbox'
import { useForm } from 'antd/lib/form/Form'
import { ColumnsType } from 'antd/lib/table'
import { CompareFn } from 'antd/lib/table/interface'
import { UploadChangeParam } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import Upload from 'antd/lib/upload/Upload'
import { ObjectId } from 'bson'
import dayjs, { extend } from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import Fuse from 'fuse.js'
import { groupBy } from 'lodash-es'
import { useContext, useEffect, useMemo, useState } from 'react'
import { CompactPicker } from 'react-color'
import { useNavigate } from 'react-router-dom'

import {
  AuthMethod,
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  BranchesQuery,
  BranchRolesQuery,
  ChannelsQuery,
  CreateBranchMutation,
  DeleteBranchMutation,
  FieldParent,
  FieldsQuery,
  UpdateBranchMutation,
} from 'apps/lms-front/src/generated/graphql'

import { AbilityContext, Can } from '../../../auth/components/Can'
import { ActionButtonWrapper } from '../../../shared/components/action-button-wrapper/ActionButtonWrapper'
import { CustomFieldsFormSection } from '../../../shared/components/custom-fields-form-section/CustomFieldsFormSection'
import { DropdownButton } from '../../../shared/components/dynamic-dropdown-button/DropdownButton'
import { InputSearch } from '../../../shared/components/input-search/InputSearch'
import { errorNotifierFn } from '../../../shared/helpers/error-notifier'
import { getBase64 } from '../../../shared/utils/get-base64'
import { dateSort, defaultSort } from '../../../shared/utils/sort'
import {
  FileType,
  uploadValidator,
} from '../../../shared/validators/upload-file-validator'
import { FONTS } from '../../constants/fonts'

import BRANCH_ROLES_QUERY from './../../../branch/queries/branch-roles.graphql'
import CREATE_BRANCH_MUTATION from './../../mutations/create-branch.graphql'
import DELETE_BRANCH_MUTATION from './../../mutations/delete-branch.graphql'
import UPDATE_BRANCH_MUTATION from './../../mutations/update-branch.graphql'
import BRANCHES_QUERY from './../../queries/branches.graphql'
import CHANNELS_QUERY from './../../queries/channels.graphql'
import FIELDS_QUERY from './../../queries/fields.graphql'
import { SCIM } from './scim/Scim'

extend(utc)
extend(timezone)
extend(relativeTime)

interface BranchRow {
  key: string
  name: string
  hostnames: string[]
  created?: Date
  disabled?: boolean | null
}

export const Branches = () => {
  const [page, setPage] = useState(1)
  const navigate = useNavigate()
  const [uploading, setUploading] = useState<boolean>(false)
  const [landingImageUploading, setLandingImageUploading] =
    useState<boolean>(false)
  const [courseHeaderImageUploading, setCourseHeaderImageUploading] =
    useState<boolean>(false)
  const [certificateBackgroundUploading, setCertificateBackgroundUploading] =
    useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [branchModalVisible, setBranchModalVisible] = useState(false)
  const [deletePopConfirmIndex, setDeletePopConfirmIndex] = useState<string>()
  const [deletePopConfirmVisible, setDeletePopConfirmVisible] = useState(false)
  const [updateSubject, setUpdateSubject] =
    useState<BranchesQuery['fetchBranches'][0]>()
  const [form] = useForm()
  const enabledChannels: string[] = Form.useWatch(['channels', 'enabled'], form)

  const ability = useContext(AbilityContext)

  const { data, loading } = useQuery<BranchesQuery>(BRANCHES_QUERY)

  const { data: fields } = useQuery<FieldsQuery>(FIELDS_QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  const { data: branchRoles, loading: loadingBranchRoles } =
    useQuery<BranchRolesQuery>(BRANCH_ROLES_QUERY, {
      variables: {
        branch_id: updateSubject?._id,
        includeGlobalRoles: true,
      },
      fetchPolicy: 'cache-and-network',
    })

  const { data: channels } = useQuery<ChannelsQuery>(CHANNELS_QUERY, {
    fetchPolicy: 'cache-and-network',
    skip: ability.cannot(PermissionAction.READ, PermissionObjectType.CHANNEL),
  })

  const [createBranch, { loading: creating }] =
    useMutation<CreateBranchMutation>(CREATE_BRANCH_MUTATION)

  const [updateBranch, { loading: updating }] =
    useMutation<UpdateBranchMutation>(UPDATE_BRANCH_MUTATION)

  const [deleteBranch, { loading: deleting }] =
    useMutation<DeleteBranchMutation>(DELETE_BRANCH_MUTATION)

  useEffect(() => {
    if (updateSubject) {
      setBranchModalVisible(true)
    }
  }, [updateSubject])

  useEffect(() => {
    if (branchModalVisible) form.resetFields()
  }, [branchModalVisible, form])

  const branches = useMemo(() => {
    const fuse = new Fuse(data?.fetchBranches || [], {
      keys: ['name', 'hostnames'],
      findAllMatches: true,
    })

    const result = fuse.search(searchTerm)

    return (
      searchTerm.length > 1
        ? result.map((result) => result.item)
        : data?.fetchBranches || []
    ).map<BranchRow>((item: BranchesQuery['fetchBranches'][0]) => ({
      hostnames: item.hostnames,
      key: item._id,
      name: item.name,
      created: item.created,
      disabled: item.disabled,
    }))
  }, [data, searchTerm])

  const columns: ColumnsType<BranchRow> = [
    {
      title: t({
        id: 'settings.branches.table.name',
        message: 'Naam',
      }),
      dataIndex: 'name',
      key: 'name',
      sorter: defaultSort('name') as CompareFn<unknown>,
      fixed: 'left',
      render: (text: string, item: BranchRow) => (
        <Space>
          {text}
          {item.disabled && (
            <Tag color="red">
              <Trans id="branch.tag.disabled">Inactief</Trans>
            </Tag>
          )}
          {item.hostnames[0] && (
            <a
              href={`https://${item.hostnames[0]}`}
              target="_blank"
              rel="noreferrer"
            >
              <LinkOutlined />
            </a>
          )}
        </Space>
      ),
    },
    {
      title: t({
        id: 'settings.branches.table.created',
        message: 'Actief sinds',
      }),
      dataIndex: 'created',
      key: 'created',
      sorter: dateSort('created') as CompareFn<unknown>,
      render: (text: string) => dayjs(text).tz('Europe/Brussels').fromNow(),
    },
    {
      title: t({
        id: 'settings.branches.table.actions',
        message: 'Acties',
      }),
      key: 'operation',
      fixed: 'right',
      width: 110,
      render: (_: string, record: BranchRow) => (
        <ActionButtonWrapper>
          <Can I={PermissionAction.UPDATE} a={PermissionObjectType.BRANCH}>
            <Tooltip
              title={t({
                id: 'action.edit',
                message: 'Bewerken',
              })}
            >
              <Button
                onClick={() => {
                  setUpdateSubject(
                    data?.fetchBranches.find((branch) =>
                      new ObjectId(branch._id).equals(record.key)
                    )
                  )
                }}
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>
          </Can>
          <Can I={PermissionAction.DELETE} a={PermissionObjectType.BRANCH}>
            <Tooltip
              title={t({
                id: 'action.delete',
                message: 'Verwijderen',
              })}
            >
              <Popconfirm
                placement={'left'}
                title={t({
                  id: 'settings.branches.action.delete.confirm',
                  message: 'Ben je zeker dat je deze afdeling wil verwijderen?',
                })}
                open={
                  deletePopConfirmIndex === record.key &&
                  deletePopConfirmVisible
                }
                okType="danger"
                okText={t({
                  id: 'action.delete',
                  message: 'Verwijderen',
                })}
                cancelText={t({
                  id: 'action.cancel',
                  message: 'Annuleren',
                })}
                okButtonProps={{ loading: deleting }}
                onConfirm={() => {
                  deleteBranch({
                    variables: {
                      id: record.key,
                    },
                    refetchQueries: ['branches'],
                  })
                    .then(() => {
                      notification.success({
                        message: t({
                          id: 'settings.branches.action.delete.success',
                          message: 'Afdeling succesvol verwijderd',
                        }),
                      })
                    })
                    .catch(errorNotifierFn)
                    .finally(() => setDeletePopConfirmVisible(false))
                }}
                onCancel={() => setDeletePopConfirmVisible(false)}
              >
                <Button
                  onClick={() => {
                    setDeletePopConfirmIndex(record.key)
                    setDeletePopConfirmVisible(true)
                  }}
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Tooltip>
          </Can>
        </ActionButtonWrapper>
      ),
    },
  ]

  const handleCreation = async () => {
    try {
      const { theme, ...fields } = await form.validateFields()
      createBranch({
        variables: {
          ...fields,
          primaryColor: theme.primaryColor?.hex || '#1890ff',
          secondaryColor: theme.secondaryColor?.hex || undefined,
          headerColor: theme.headerColor?.hex || '#1890ff',
          textColor: theme.headerColor?.hex || '#000000d9',
          likeColor: theme.likeColor?.hex || 'rgb(255, 107, 108)',
          fontFamily: theme.fontFamily,
        },
        refetchQueries: ['branches'],
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'settings.branches.action.create.success',
              message: 'Afdeling succesvol aangemaakt',
            }),
          })
          setBranchModalVisible(false)
        })
        .catch(errorNotifierFn)
    } catch {
      return
    }
  }

  const handleUpdate = async () => {
    try {
      const { theme, ...fields } = await form.validateFields()
      updateBranch({
        variables: {
          id: updateSubject?._id,
          ...fields,
          primaryColor: theme.primaryColor?.hex || theme.primaryColor,
          secondaryColor:
            theme.secondaryColor?.hex || theme.secondaryColor || undefined,
          textColor: theme.textColor?.hex || theme.textColor,
          headerColor: theme.headerColor?.hex || theme.headerColor,
          likeColor: theme.likeColor?.hex || theme.likeColor,
          fontFamily: theme.fontFamily,
          zoom: theme.zoom,
        },
        refetchQueries: ['branches'],
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'settings.branches.action.update.success',
              message: 'Afdeling succesvol gewijzigd',
            }),
          })
          setBranchModalVisible(false)
        })
        .catch(errorNotifierFn)
    } catch {
      return
    }
  }

  const formItemLayout = {
    labelCol: {
      sm: { span: 8 },
    },
    wrapperCol: {
      sm: { span: 16 },
    },
  }
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      sm: { span: 16, offset: 8 },
    },
  }

  const client = useApolloClient()

  const handleChange = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setUploading(true)
      return
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, () => {
        setUploading(false)
        client.refetchQueries({ include: ['branches'] })
        setBranchModalVisible(false)
      })
    }
  }

  const handleLandingImageChange = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setLandingImageUploading(true)
      return
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, () => {
        setLandingImageUploading(false)
        client.refetchQueries({ include: ['branches'] })
        setBranchModalVisible(false)
      })
    }
  }

  const handleCourseHeaderImageChange = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === 'uploading') {
      setCourseHeaderImageUploading(true)
      return
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, () => {
        setCourseHeaderImageUploading(false)
        client.refetchQueries({ include: ['branches'] })
        setBranchModalVisible(false)
      })
    }
  }

  const handleCertificateBackgroundChange = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === 'uploading') {
      setCertificateBackgroundUploading(true)
      return
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, () => {
        setCertificateBackgroundUploading(false)
        client.refetchQueries({ include: ['branches'] })
        setBranchModalVisible(false)
      })
    }
  }

  const uploadButton = (
    <div>
      {uploading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>
        {uploading ? (
          <Trans id="action.uploading">Uploading</Trans>
        ) : (
          <Trans id="action.upload">Upload</Trans>
        )}
      </div>
    </div>
  )

  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={t({
          id: 'settings.branches.title',
          message: 'Afdelingen',
        })}
        style={{ backgroundColor: '#FFF' }}
        extra={[
          <Space wrap={true} key="1">
            <InputSearch
              placeholder={t({
                id: 'settings.branches.search',
                message: 'Zoeken op naam',
              })}
              onSearch={(value) => {
                setSearchTerm(value)
                setPage(1)
              }}
              style={{ width: 200 }}
            />
            <Can I={PermissionAction.CREATE} a={PermissionObjectType.BRANCH}>
              <DropdownButton
                icon={<DownOutlined />}
                onClick={() => setBranchModalVisible(true)}
                type="primary"
                menu={{
                  hidden: !ability.can(
                    PermissionAction.IMPORT,
                    PermissionObjectType.BRANCH
                  ),
                  onClick: ({ key }) => navigate(`/settings/branches/${key}`),
                  items: [
                    {
                      key: 'import',
                      label: t({
                        id: 'settings.branches.action.import',
                        message: 'Afdelingen importeren',
                      }),
                    },
                  ],
                }}
              >
                <Trans id="settings.branches.action.create">
                  Afdeling aanmaken
                </Trans>
              </DropdownButton>
            </Can>
          </Space>,
        ]}
      />
      <Table
        locale={{
          emptyText: t({
            id: 'settings.branches.table.empty',
            message: 'Geen afdelingen gevonden.',
          }),
          cancelSort: t({
            id: 'table.sort.cancel',
            message: 'Klik om niet langer te sorteren.',
          }),
          triggerAsc: t({
            id: 'table.sort.asc',
            message: 'Klik om oplopend te sorteren.',
          }),
          triggerDesc: t({
            id: 'table.sort.desc',
            message: 'Klik om aflopend te sorteren.',
          }),
        }}
        scroll={{ x: 400 }}
        dataSource={branches}
        loading={loading}
        columns={columns}
        showSorterTooltip={false}
        pagination={{
          current: page,
          onChange: (page: number) => setPage(page),
        }}
      />
      <Drawer
        forceRender
        title={
          updateSubject
            ? t({
                id: 'settings.branches.action.update.title',
                message: 'Afdeling wijzigen',
              })
            : t({
                id: 'settings.branches.action.create.title',
                message: 'Nieuwe afdeling aanmaken',
              })
        }
        open={branchModalVisible}
        afterOpenChange={(visible) => {
          if (!visible) setUpdateSubject(undefined)
        }}
        onClose={() => {
          setBranchModalVisible(false)
        }}
        extra={
          <Space>
            <Button
              onClick={updateSubject ? handleUpdate : handleCreation}
              loading={creating || updating}
              type="primary"
            >
              {updateSubject
                ? t({
                    id: 'action.update',
                    message: 'Wijzigen',
                  })
                : t({
                    id: 'action.create',
                    message: 'Aanmaken',
                  })}
            </Button>
          </Space>
        }
        width={640}
        contentWrapperStyle={{ maxWidth: '100%' }}
      >
        <Form
          key={updateSubject?._id}
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={updateSubject ? handleUpdate : handleCreation}
          initialValues={
            updateSubject
              ? {
                  ...updateSubject,
                  theme: {
                    primaryColor:
                      updateSubject?.theme?.primaryColor || undefined,
                    secondaryColor:
                      updateSubject?.theme?.secondaryColor || undefined,
                    headerColor: updateSubject?.theme?.headerColor || undefined,
                    textColor: updateSubject?.theme?.textColor || undefined,
                    fontFamily: updateSubject?.theme?.fontFamily || undefined,
                    zoom: updateSubject?.theme?.zoom || 1,
                    likeColor: updateSubject?.theme?.likeColor || undefined,
                  },
                }
              : {
                  loginMethods: [AuthMethod.EMAIL_PASS],
                  redirects: [],
                  channels: {
                    enabled: [],
                    default: [],
                  },
                }
          }
          autoComplete="off"
          style={{ marginTop: -24 }}
        >
          <Tabs
            defaultActiveKey="1"
            destroyInactiveTabPane={false}
            items={[
              {
                label: t({
                  id: 'settings.branches.form.section.general',
                  message: 'Algemeen',
                }),
                key: 'general',
                forceRender: true,
                children: (
                  <>
                    {updateSubject && (
                      <Form.Item wrapperCol={{ sm: { offset: 8, span: 16 } }}>
                        <Upload
                          name="file"
                          listType="picture-card"
                          className="branch-logo-uploader"
                          showUploadList={false}
                          action={`${
                            import.meta.env.NX_BACKEND_URL
                          }/api/files/uploadBranchLogo/${updateSubject._id}`}
                          beforeUpload={uploadValidator(2, [
                            FileType.jpg,
                            FileType.png,
                            FileType.gif,
                          ])}
                          onChange={handleChange}
                          headers={{
                            Authorization: `Bearer ${localStorage.getItem(
                              'aa_lms_at'
                            )}`,
                            'x-academy-host': window.location.hostname,
                          }}
                        >
                          {updateSubject?.logo?.url && !uploading ? (
                            <img
                              src={updateSubject?.logo?.url}
                              alt={''}
                              style={{
                                backgroundColor: '#888',
                                width: '100%',
                              }}
                            />
                          ) : (
                            uploadButton
                          )}
                        </Upload>
                      </Form.Item>
                    )}
                    <Form.Item
                      label={t({
                        id: 'settings.branches.form.label.name',
                        message: 'Naam',
                      })}
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: t({
                            id: 'settings.branches.form.validation.name',
                            message:
                              'Gelieve de naam van de afdeling in te vullen',
                          }),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label={t({
                        id: 'settings.branches.form.label.link_to_faq',
                        message: 'Link naar FAQ',
                      })}
                      name="faqLink"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label={t({
                        id: 'settings.branches.form.label.link_to_contact',
                        message: 'Link naar contactpagina',
                      })}
                      name="contactLink"
                    >
                      <Input />
                    </Form.Item>
                    <Divider />
                    {!!channels?.fetchChannels?.length && (
                      <Form.Item
                        label={t({
                          id: 'settings.branches.form.label.enabled_channels',
                          message: 'Actieve kennisbronnen',
                        })}
                        name={['channels', 'enabled']}
                        tooltip={t({
                          id: 'settings.branches.form.tooltip.enabled_channels',
                          message:
                            'Deze kennisbronnen worden zichtbaar voor de admins van deze afdeling en kunnen worden geactiveerd voor de gebruikers.',
                        })}
                      >
                        <Checkbox.Group
                          options={channels?.fetchChannels.map(
                            (type) =>
                              ({
                                label: type.name,
                                value: type._id as string,
                              }) as CheckboxOptionType
                          )}
                        />
                      </Form.Item>
                    )}
                    {enabledChannels?.length > 0 && (
                      <Form.Item
                        label={t({
                          id: 'settings.branches.form.label.default_channels',
                          message: 'Standaard kennisbronnen',
                        })}
                        name={['channels', 'default']}
                        tooltip={t({
                          id: 'settings.branches.form.tooltip.default_channels',
                          message:
                            'Deze kennisbronnen worden standaard geactiveerd voor nieuwe gebruikers van deze afdeling.',
                        })}
                      >
                        <Checkbox.Group
                          options={channels?.fetchChannels
                            .filter((i) =>
                              (enabledChannels || []).some((a) =>
                                new ObjectId(a).equals(i._id)
                              )
                            )
                            .map(
                              (type) =>
                                ({
                                  label: type.name,
                                  value: type._id as string,
                                }) as CheckboxOptionType
                            )}
                        />
                      </Form.Item>
                    )}
                    <Divider />
                    <CustomFieldsFormSection
                      parent={FieldParent.Branch}
                      fields={fields?.fetchFields}
                      initialValues={updateSubject?.meta}
                      admin={true}
                    />
                    <Divider />
                    {updateSubject && (
                      <Form.Item
                        name="disabled"
                        valuePropName="checked"
                        wrapperCol={{ sm: { offset: 8, span: 16 } }}
                      >
                        <Checkbox>
                          <Trans id="settings.branches.action.deactivate">
                            Afdeling deactiveren
                          </Trans>
                        </Checkbox>
                      </Form.Item>
                    )}
                  </>
                ),
              },
              {
                label: t({
                  id: 'settings.branches.form.section.hosts',
                  message: 'Hosts',
                }),
                key: 'hosts',
                forceRender: true,
                children: (
                  <>
                    <Form.List
                      name="hostnames"
                      initialValue={[
                        `new.${window.location.hostname
                          .replace('test.', '')
                          .replace('staging.', '')}`,
                      ]}
                      rules={[
                        {
                          validator: async (_, hostnames) => {
                            if (!hostnames?.length || hostnames?.length === 0) {
                              throw new Error(
                                t({
                                  id: 'settings.branches.form.validation.hosts',
                                  message:
                                    'Er werd nog geen hostname aan deze afdeling toegevoegd.',
                                })
                              )
                            }
                          },
                        },
                      ]}
                    >
                      {(fields, { add, remove }, { errors }) => (
                        <>
                          {fields.map((field, index) => (
                            <Form.Item
                              {...(index === 0
                                ? formItemLayout
                                : formItemLayoutWithOutLabel)}
                              label={
                                index === 0
                                  ? t({
                                      id: 'settings.branches.form.label.host',
                                      message: 'Toegestane hosts',
                                    })
                                  : ''
                              }
                              required={false}
                              key={field.key}
                            >
                              <Form.Item
                                {...field}
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                  {
                                    required: true,
                                    whitespace: true,
                                    message: t({
                                      id: 'settings.branches.form.validation.host',
                                      message:
                                        'Vul een hostname in of verwijder dit veld.',
                                    }),
                                  },
                                ]}
                                noStyle
                              >
                                <Input />
                              </Form.Item>
                              {fields.length > 0 ? (
                                <MinusCircleOutlined
                                  className="dynamic-delete-button"
                                  style={{
                                    position: 'absolute',
                                    right: 6,
                                    top: 9,
                                  }}
                                  onClick={() => remove(field.name)}
                                />
                              ) : null}
                            </Form.Item>
                          ))}
                          <Form.Item {...formItemLayoutWithOutLabel}>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              style={{ width: '60%' }}
                              icon={<PlusOutlined />}
                            >
                              {' '}
                              <Trans id="settings.branches.form.action.add_host">
                                Hostname toevoegen
                              </Trans>
                            </Button>
                            <Form.ErrorList errors={errors} />
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                    <Form.List name="redirects" initialValue={[]}>
                      {(fields, { add, remove }, { errors }) => (
                        <>
                          {fields.map((field, index) => (
                            <Form.Item
                              {...(index === 0
                                ? formItemLayout
                                : formItemLayoutWithOutLabel)}
                              label={
                                index === 0
                                  ? t({
                                      id: 'settings.branches.form.label.redirect_url',
                                      message: 'Naar deze afdeling redirecten',
                                    })
                                  : ''
                              }
                              required={false}
                              key={field.key}
                            >
                              <Form.Item
                                {...field}
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                  {
                                    required: true,
                                    whitespace: true,
                                    message: t({
                                      id: 'settings.branches.form.validation.redirect_url',
                                      message:
                                        'Vul een URL in of verwijder dit veld.',
                                    }),
                                  },
                                ]}
                                noStyle
                              >
                                <Input />
                              </Form.Item>
                              {fields.length > 0 ? (
                                <MinusCircleOutlined
                                  className="dynamic-delete-button"
                                  style={{
                                    position: 'absolute',
                                    right: 6,
                                    top: 9,
                                  }}
                                  onClick={() => remove(field.name)}
                                />
                              ) : null}
                            </Form.Item>
                          ))}
                          <Form.Item {...formItemLayoutWithOutLabel}>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              style={{ width: '60%' }}
                              icon={<PlusOutlined />}
                            >
                              {' '}
                              <Trans id="settings.branches.form.action.add_redirect_url">
                                Te redirecten URL toevoegen
                              </Trans>
                            </Button>
                            <Form.ErrorList errors={errors} />
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </>
                ),
              },
              {
                label: t({
                  id: 'settings.branches.form.section.theme',
                  message: 'Thema',
                }),
                key: 'theme',
                forceRender: true,
                children: (
                  <>
                    <Form.Item
                      label={t({
                        id: 'settings.branches.form.label.font',
                        message: 'Lettertype',
                      })}
                      name={['theme', 'fontFamily']}
                    >
                      <Select>
                        {FONTS.map((font) => (
                          <Select.Option key={font} value={font}>
                            {font}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label={t({
                        id: 'settings.branches.form.label.primary_color',
                        message: 'Primaire kleur',
                      })}
                      tooltip={t({
                        id: 'settings.branches.form.tooltip.primary_color',
                        message:
                          'Deze kleur wordt gebruikt voor o.a. de kleur van knoppen, actieve elementen, ...',
                      })}
                      name={['theme', 'primaryColor']}
                      valuePropName="color"
                      trigger="onChange"
                      initialValue={'#1890ff'}
                    >
                      <CompactPicker
                        styles={{ default: { compact: { width: '100%' } } }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={t({
                        id: 'settings.branches.form.label.secondary_color',
                        message: 'Secundaire kleur',
                      })}
                      tooltip={t({
                        id: 'settings.branches.form.tooltip.secondary_color',
                        message:
                          'Deze kleur wordt gebruikt voor elementen die contrasteren met de primaire kleur.',
                      })}
                      name={['theme', 'secondaryColor']}
                      valuePropName="color"
                      trigger="onChange"
                    >
                      <CompactPicker
                        styles={{ default: { compact: { width: '100%' } } }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={t({
                        id: 'settings.branches.form.label.header_color',
                        message: 'Navigatiekleur',
                      })}
                      tooltip={t({
                        id: 'settings.branches.form.tooltip.header_color',
                        message:
                          'Deze kleur wordt enkel gebruikt voor de navigatiebalk.',
                      })}
                      name={['theme', 'headerColor']}
                      valuePropName="color"
                      trigger="onChange"
                      initialValue={'#001529'}
                    >
                      <CompactPicker
                        styles={{ default: { compact: { width: '100%' } } }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={t({
                        id: 'settings.branches.form.label.text_color',
                        message: 'Tekstkleur',
                      })}
                      tooltip={t({
                        id: 'settings.branches.form.tooltip.text_color',
                        message:
                          'Deze kleur wordt gebruikt voor teksten doorheen het leerplatform.',
                      })}
                      name={['theme', 'textColor']}
                      valuePropName="color"
                      trigger="onChange"
                      initialValue={'#000000d9'}
                    >
                      <CompactPicker
                        styles={{ default: { compact: { width: '100%' } } }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={t({
                        id: 'settings.branches.form.label.likes_color',
                        message: 'Favorietenkleur',
                      })}
                      tooltip={t({
                        id: 'settings.branches.form.tooltip.likes_color',
                        message:
                          "Deze kleur wordt gebruikt voor het hartje van 'mijn lijst'.",
                      })}
                      name={['theme', 'likeColor']}
                      valuePropName="color"
                      trigger="onChange"
                      initialValue={'rgb(255, 107, 108)'}
                    >
                      <CompactPicker
                        styles={{ default: { compact: { width: '100%' } } }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={t({
                        id: 'settings.branches.form.label.zoom',
                        message: 'Zoom',
                      })}
                      tooltip={t({
                        id: 'settings.branches.form.tooltip.zoom',
                        message:
                          'Deze waarde bepaalt het zoompercentage van het leerplatform.',
                      })}
                      name={['theme', 'zoom']}
                    >
                      <InputNumber min={0.5} max={2} step={0.05} />
                    </Form.Item>
                    {updateSubject && (
                      <Form.Item wrapperCol={{ sm: { offset: 8, span: 16 } }}>
                        {updateSubject?.landingImage && (
                          <a
                            href={updateSubject?.landingImage.url}
                            target="_blank"
                            style={{
                              textDecoration: 'none',
                              display: 'block',
                              maxWidth: '100%',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              margin: '6px 0',
                            }}
                            rel="noreferrer"
                          >
                            <PaperClipOutlined />{' '}
                            {updateSubject?.landingImage?.name
                              .split('/')
                              .at(-1)}
                          </a>
                        )}
                        <Upload
                          name="file"
                          className="branch-landing-image-uploader"
                          showUploadList={false}
                          action={`${
                            import.meta.env.NX_BACKEND_URL
                          }/api/files/uploadBranchLandingImage/${
                            updateSubject._id
                          }`}
                          beforeUpload={uploadValidator(2, [
                            FileType.jpg,
                            FileType.png,
                            FileType.svg,
                          ])}
                          onChange={handleLandingImageChange}
                          headers={{
                            Authorization: `Bearer ${localStorage.getItem(
                              'aa_lms_at'
                            )}`,
                            'x-academy-host': window.location.hostname,
                          }}
                        >
                          <Button
                            icon={<UploadOutlined />}
                            disabled={landingImageUploading}
                          >
                            {landingImageUploading
                              ? t({
                                  id: 'action.uploading',
                                  message: 'Uploading',
                                })
                              : t({
                                  id: 'settings.branches.form.label.upload_landing_image',
                                  message: 'Welkomstscherm afbeelding',
                                })}
                          </Button>
                        </Upload>
                      </Form.Item>
                    )}
                    {updateSubject && (
                      <Form.Item wrapperCol={{ sm: { offset: 8, span: 16 } }}>
                        {updateSubject?.courseHeaderImage && (
                          <a
                            href={updateSubject?.courseHeaderImage.url}
                            target="_blank"
                            style={{
                              textDecoration: 'none',
                              display: 'block',
                              maxWidth: '100%',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              margin: '6px 0',
                            }}
                            rel="noreferrer"
                          >
                            <PaperClipOutlined />{' '}
                            {updateSubject?.courseHeaderImage?.name
                              .split('/')
                              .at(-1)}
                          </a>
                        )}
                        <Upload
                          name="file"
                          className="branch-landing-image-uploader"
                          showUploadList={false}
                          action={`${
                            import.meta.env.NX_BACKEND_URL
                          }/api/files/uploadBranchCourseHeader/${
                            updateSubject._id
                          }`}
                          beforeUpload={uploadValidator(2, [
                            FileType.jpg,
                            FileType.png,
                            FileType.svg,
                          ])}
                          onChange={handleCourseHeaderImageChange}
                          headers={{
                            Authorization: `Bearer ${localStorage.getItem(
                              'aa_lms_at'
                            )}`,
                            'x-academy-host': window.location.hostname,
                          }}
                        >
                          <Button
                            icon={<UploadOutlined />}
                            disabled={courseHeaderImageUploading}
                          >
                            {courseHeaderImageUploading
                              ? t({
                                  id: 'action.uploading',
                                  message: 'Uploading',
                                })
                              : t({
                                  id: 'settings.branches.form.label.upload_course_header',
                                  message: 'Opleiding header',
                                })}
                          </Button>
                        </Upload>
                      </Form.Item>
                    )}
                    <Form.Item
                      name="disableLandingGradient"
                      valuePropName="checked"
                      wrapperCol={{ sm: { offset: 8, span: 16 } }}
                    >
                      <Checkbox>
                        <Trans id="settings.branches.form.label.disable_landing_gradient">
                          Gradient op landingspagina uitschakelen
                        </Trans>
                      </Checkbox>
                    </Form.Item>
                  </>
                ),
              },
              {
                label: t({
                  id: 'settings.branches.form.section.certificates',
                  message: 'Attesten',
                }),
                key: 'certificates',
                forceRender: true,
                children: (
                  <>
                    {updateSubject && (
                      <Form.Item wrapperCol={{ sm: { offset: 8, span: 16 } }}>
                        {updateSubject?.certificateBackground && (
                          <a
                            href={updateSubject?.certificateBackground.url}
                            target="_blank"
                            style={{
                              textDecoration: 'none',
                              display: 'block',
                              maxWidth: '100%',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              margin: '6px 0',
                            }}
                            rel="noreferrer"
                          >
                            <PaperClipOutlined />{' '}
                            {updateSubject?.certificateBackground?.name
                              .split('/')
                              .at(-1)}
                          </a>
                        )}
                        <Upload
                          name="file"
                          className="branch-certifiate-background-uploader"
                          showUploadList={false}
                          action={`${
                            import.meta.env.NX_BACKEND_URL
                          }/api/files/uploadBranchCertificateBackground/${
                            updateSubject._id
                          }`}
                          beforeUpload={uploadValidator(2, [
                            FileType.jpg,
                            FileType.png,
                          ])}
                          onChange={handleCertificateBackgroundChange}
                          headers={{
                            Authorization: `Bearer ${localStorage.getItem(
                              'aa_lms_at'
                            )}`,
                            'x-academy-host': window.location.hostname,
                          }}
                        >
                          <Button
                            icon={<UploadOutlined />}
                            disabled={certificateBackgroundUploading}
                          >
                            {certificateBackgroundUploading
                              ? t({
                                  id: 'action.uploading',
                                  message: 'Uploading',
                                })
                              : t({
                                  id: 'settings.branches.form.label.upload_certificate_background',
                                  message: 'Achtergrondafbeelding attest',
                                })}
                          </Button>
                        </Upload>
                      </Form.Item>
                    )}
                    <Divider />
                    <Form.Item
                      name={['certificationSettings', 'allowChoice']}
                      valuePropName="checked"
                      wrapperCol={{ sm: { offset: 8, span: 16 } }}
                    >
                      <Checkbox>
                        <Trans id="settings.branches.form.label.allow_certification_type_choice">
                          Keuze van certificeringstype van opleiding toelaten
                        </Trans>
                      </Checkbox>
                    </Form.Item>
                  </>
                ),
              },
              {
                label: t({
                  id: 'settings.branches.form.section.auth',
                  message: 'Authenticatie',
                }),
                key: 'auth',
                forceRender: true,
                children: (
                  <>
                    <Form.Item
                      label={t({
                        id: 'settings.branches.form.label.login_methods',
                        message: 'Inlogmethodes',
                      })}
                      name="loginMethods"
                    >
                      <Checkbox.Group
                        options={[
                          {
                            label: t({
                              id: 'settings.branches.form.label.login_methods.email_pass',
                              message: 'E-mail en wachtwoord',
                            }),
                            value: AuthMethod.EMAIL_PASS,
                          },
                          {
                            label: t({
                              id: 'settings.branches.form.label.login_methods.microsoft',
                              message: 'Microsoft',
                            }),
                            value: AuthMethod.MICROSOFT,
                          },
                        ]}
                      />
                    </Form.Item>
                    <Divider />
                    <Form.Item
                      name="enableRegistration"
                      valuePropName="checked"
                      wrapperCol={{ sm: { offset: 8, span: 16 } }}
                    >
                      <Checkbox>
                        <Trans id="settings.branches.form.label.enable_registration">
                          Registreren als bezoeker toestaan
                        </Trans>
                      </Checkbox>
                    </Form.Item>
                    <Form.Item
                      label={t({
                        id: 'settings.branches.form.label.admin_login_role',
                        message: 'Admin login',
                      })}
                      help={t({
                        id: 'settings.branches.form.help.admin_login_role',
                        message:
                          'Beperken welke rol toegang heeft tot de admin login.',
                      })}
                      name="adminLoginRole"
                    >
                      <Select
                        loading={loadingBranchRoles}
                        options={Object.values(
                          groupBy(
                            branchRoles?.fetchBranchRoles || [],
                            ({ branch_name }) => branch_name
                          )
                        ).map((roles) => {
                          const branch =
                            roles[0].branch_name ||
                            t({
                              id: 'settings.users.form.label.default',
                              message: 'Standaard',
                            })
                          return {
                            label: branch,
                            options: roles.map((userRole) => ({
                              value: userRole._id,
                              label: userRole.name,
                            })),
                          }
                        })}
                      />
                    </Form.Item>
                  </>
                ),
              },
              {
                label: t({
                  id: 'settings.branches.form.section.notifications',
                  message: 'Notificaties',
                }),
                key: 'notifications',
                forceRender: true,
                children: (
                  <>
                    <h2>Toewijzingen</h2>
                    <p style={{ marginBottom: 8 }}>
                      E-mails uitsturen wanneer:
                    </p>
                    <Form.Item
                      name={['notifications', 'assignment_completed']}
                      valuePropName="checked"
                      style={{ margin: 0 }}
                    >
                      <Checkbox>een toewijzing werd voltooid</Checkbox>
                    </Form.Item>
                    <Form.Item
                      name={['notifications', 'assignment_missed_deadline']}
                      valuePropName="checked"
                      style={{ margin: 0 }}
                    >
                      <Checkbox>
                        de deadline voor een toewijzing niet werd gehaald
                      </Checkbox>
                    </Form.Item>
                  </>
                ),
              },
              {
                label: t({
                  id: 'settings.branches.form.section.scim',
                  message: 'SCIM',
                }),
                key: 'scim',
                disabled: !updateSubject,
                forceRender: true,
                destroyInactiveTabPane: true,
                children: updateSubject?._id ? (
                  <SCIM branch_id={updateSubject?._id} />
                ) : null,
              },
              {
                label: t({
                  id: 'settings.branches.form.section.favicon',
                  message: 'Favicon',
                }),
                key: 'favicon',
                disabled: !updateSubject,
                forceRender: true,
                children: (
                  <>
                    {updateSubject && (
                      <Upload
                        name="file"
                        listType="picture-card"
                        className="branch-favicon-uploader"
                        showUploadList={false}
                        action={`${
                          import.meta.env.NX_BACKEND_URL
                        }/api/files/uploadBranchFavicon/${updateSubject._id}`}
                        beforeUpload={uploadValidator(1, [
                          FileType.ico,
                          FileType.msico,
                        ])}
                        onChange={handleChange}
                        headers={{
                          Authorization: `Bearer ${localStorage.getItem(
                            'aa_lms_at'
                          )}`,
                          'x-academy-host': window.location.hostname,
                        }}
                      >
                        {updateSubject?.favicon?.url && !uploading ? (
                          <img
                            src={updateSubject?.favicon?.url}
                            alt={''}
                            style={{
                              backgroundColor: '#888',
                              width: '100%',
                            }}
                          />
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    )}
                  </>
                ),
              },
            ]}
          />
        </Form>
      </Drawer>
    </>
  )
}
