import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'

import { useAuth } from 'apps/lms-front/src/modules/auth/hooks/use-auth'

import { renderToolbar } from '../../../units/pages/unit-viewer/pdf-unit-viewer/PdfViewerToolbar'

type CertificatePreviewProps = {
  course_id: string
}
export const CertificatePreview = ({ course_id }: CertificatePreviewProps) => {
  const { user } = useAuth()

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: () => [],
    renderToolbar,
    toolbarPlugin: {
      openPlugin: {
        enableShortcuts: false,
      },
      getFilePlugin: {
        fileNameGenerator: () => {
          return `${course_id}.pdf`
        },
      },
    },
  })

  return (
    <div style={{ height: '700px', maxHeight: '80vh' }}>
      <Viewer
        fileUrl={`${
          import.meta.env.NX_BACKEND_URL
        }/api/certificate?course_id=${course_id}&user_id=${user?._id}&token=${Math.floor(
          Date.now() / 3600000
        )}`}
        httpHeaders={{
          authorization: `Bearer ${localStorage.getItem('aa_lms_at')}`,
        }}
        defaultScale={SpecialZoomLevel.PageFit}
        plugins={[defaultLayoutPluginInstance]}
      />
    </div>
  )
}
