import { ApolloQueryResult } from '@apollo/client'
import { t } from '@lingui/macro'
import { Modal } from 'antd'
import { useRef, useState } from 'react'

import { PublicUnitQuery } from 'apps/lms-front/src/generated/graphql'

import { QuizViewer, QuizViewerRef } from '../QuizViewer'

type VideoUnit = PublicUnitQuery['fetchUnitById'] & {
  __typename: 'VideoUnit'
}

type intermediaryQuestionsModalProps = {
  open: boolean
  questions: (VideoUnit['questions'][0] | VideoUnit['survey_questions'][0])[]
  onResetRequested: () => void
  onFinish: () => void
  unit: VideoUnit
  refetchUnit: (() => Promise<ApolloQueryResult<PublicUnitQuery>>) | undefined
}

export const IntermediaryQuestionsModal = ({
  open,
  questions,
  onResetRequested,
  onFinish,
  unit,
  refetchUnit,
}: intermediaryQuestionsModalProps) => {
  const quizRef = useRef<QuizViewerRef>(null)

  const [current, setCurrent] = useState(0)
  const [answerLoading, setAnswerLoading] = useState<boolean>(false)

  return (
    <Modal
      open={questions.length > 0 && open}
      cancelText={t({
        id: 'unit.viewer.video.restart_video',
        message: 'Video herstarten',
      })}
      okText={
        current === questions.length
          ? t({
              id: 'action.continue',
              message: 'Doorgaan',
            })
          : t({
              id: 'unit.viewer.video.quiz.submit',
              message: 'Antwoord indienen',
            })
      }
      onOk={async () => {
        current === questions.length ? onFinish() : quizRef.current?.submit()
      }}
      onCancel={() => onResetRequested()}
      cancelButtonProps={{
        hidden: current === questions.length,
      }}
      okButtonProps={{ loading: answerLoading }}
      closable={false}
      maskClosable={false}
      maskStyle={{ backdropFilter: 'blur(5px)' }}
    >
      <QuizViewer
        ref={quizRef}
        unit={unit}
        refetchUnit={refetchUnit}
        questionIndex={current}
        onQuestionIndexChange={(index) => setCurrent(index)}
        onLoadingStateChange={(loading) => setAnswerLoading(loading)}
      />
    </Modal>
  )
}
