/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable unicorn/no-useless-undefined */
import { DeleteOutlined, EditOutlined, DownOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import {
  Button,
  Checkbox,
  Form,
  Input,
  notification,
  PageHeader,
  Popconfirm,
  Select,
  Switch,
  Table,
  Tooltip,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import Modal from 'antd/lib/modal/Modal'
import { ColumnsType } from 'antd/lib/table'
import { CompareFn } from 'antd/lib/table/interface'
import { ObjectId } from 'bson'
import { extend } from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import Fuse from 'fuse.js'
import { useEffect, useMemo, useState } from 'react'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  FieldsQuery,
  CreateFieldMutation,
  DeleteFieldMutation,
  UpdateFieldMutation,
  FieldType,
  FieldParent,
} from 'apps/lms-front/src/generated/graphql'

import { Can } from '../../../auth/components/Can'
import { ActionButtonWrapper } from '../../../shared/components/action-button-wrapper/ActionButtonWrapper'
import { DropdownButton } from '../../../shared/components/dynamic-dropdown-button/DropdownButton'
import { InputSearch } from '../../../shared/components/input-search/InputSearch'
import { errorNotifierFn } from '../../../shared/helpers/error-notifier'
import { defaultSort } from '../../../shared/utils/sort'

import CREATE_FIELD_MUTATION from './../../mutations/create-field.graphql'
import DELETE_FIELD_MUTATION from './../../mutations/delete-field.graphql'
import UPDATE_FIELD_MUTATION from './../../mutations/update-field.graphql'
import FIELDS_QUERY from './../../queries/fields.graphql'

extend(utc)
extend(timezone)
extend(relativeTime)

interface FieldRow {
  _id: string
  key: string
  name: string
  created?: Date
  type: FieldType
  parent: FieldParent
}

export const Fields = () => {
  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [fieldModalVisible, setFieldModalVisible] = useState(false)
  const [deletePopConfirmIndex, setDeletePopConfirmIndex] = useState<string>()
  const [deletePopConfirmVisible, setDeletePopConfirmVisible] = useState(false)
  const [updateSubject, setUpdateSubject] =
    useState<FieldsQuery['fetchFields'][0]>()
  const [form] = useForm()

  const { data, loading } = useQuery<FieldsQuery>(FIELDS_QUERY)

  const [createField, { loading: creating }] = useMutation<CreateFieldMutation>(
    CREATE_FIELD_MUTATION
  )

  const [updateField, { loading: updating }] = useMutation<UpdateFieldMutation>(
    UPDATE_FIELD_MUTATION
  )

  const [deleteField, { loading: deleting }] = useMutation<DeleteFieldMutation>(
    DELETE_FIELD_MUTATION
  )

  useEffect(() => {
    if (updateSubject) {
      setFieldModalVisible(true)
    }
  }, [updateSubject])

  useEffect(() => {
    if (fieldModalVisible) form.resetFields()
  }, [fieldModalVisible, form])

  const fields = useMemo(() => {
    const fuse = new Fuse(data?.fetchFields || [], {
      keys: ['name'],
      findAllMatches: true,
    })

    const result = fuse.search(searchTerm)

    return (
      searchTerm.length > 1
        ? result.map((result) => result.item)
        : data?.fetchFields || []
    ).map<FieldRow>((item: FieldsQuery['fetchFields'][0]) => ({
      _id: item._id,
      key: item.key,
      name: item.name,
      created: item.created,
      type: item.type,
      parent: item.parent,
    }))
  }, [data, searchTerm])

  const columns: ColumnsType<FieldRow> = [
    {
      title: t({
        id: 'settings.fields.table.name',
        message: 'Naam',
      }),
      dataIndex: 'name',
      key: 'name',
      sorter: defaultSort('name') as CompareFn<unknown>,
      fixed: 'left',
    },
    {
      title: t({
        id: 'settings.fields.table.id',
        message: 'ID',
      }),
      dataIndex: 'key',
      key: 'key',
      sorter: defaultSort('key') as CompareFn<unknown>,
    },
    {
      title: t({
        id: 'settings.fields.table.type',
        message: 'Soort',
      }),
      dataIndex: 'type',
      key: 'type',
      sorter: defaultSort('type') as CompareFn<unknown>,
    },
    {
      title: t({
        id: 'settings.fields.table.parent',
        message: 'Inhoudstype',
      }),
      dataIndex: 'parent',
      key: 'parent',
      sorter: defaultSort('parent') as CompareFn<unknown>,
    },
    {
      title: t({
        id: 'settings.fields.table.actions',
        message: 'Acties',
      }),
      key: 'operation',
      fixed: 'right',
      width: 110,
      render: (_, record: FieldRow) => (
        <ActionButtonWrapper>
          <Can
            I={PermissionAction.UPDATE}
            a={PermissionObjectType.CUSTOM_FIELD}
          >
            <Tooltip
              title={t({
                id: 'actions.edit',
                message: 'Bewerken',
              })}
            >
              <Button
                onClick={() => {
                  setUpdateSubject(
                    data?.fetchFields.find((field) =>
                      new ObjectId(field._id).equals(record._id)
                    )
                  )
                }}
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>
          </Can>
          <Can
            I={PermissionAction.DELETE}
            a={PermissionObjectType.CUSTOM_FIELD}
          >
            <Tooltip
              title={t({
                id: 'actions.delete',
                message: 'Verwijderen',
              })}
            >
              <Popconfirm
                placement={'left'}
                title={t({
                  id: 'settings.fields.action.delete.confirmation',
                  message: 'Ben je zeker dat je dit dataveld wil verwijderen?',
                })}
                open={
                  deletePopConfirmIndex === record.key &&
                  deletePopConfirmVisible
                }
                okType="danger"
                okText={t({
                  id: 'actions.delete',
                  message: 'Verwijderen',
                })}
                cancelText={t({
                  id: 'actions.cancel',
                  message: 'Annuleren',
                })}
                okButtonProps={{ loading: deleting }}
                onConfirm={() => {
                  deleteField({
                    variables: {
                      id: record._id,
                    },
                    refetchQueries: ['fields'],
                  })
                    .then(() => {
                      notification.success({
                        message: t({
                          id: 'settings.fields.action.delete.success',
                          message: 'Dataveld succesvol verwijderd',
                        }),
                      })
                    })
                    .catch(errorNotifierFn)
                    .finally(() => setDeletePopConfirmVisible(false))
                }}
                onCancel={() => setDeletePopConfirmVisible(false)}
              >
                <Button
                  onClick={() => {
                    setDeletePopConfirmIndex(record.key)
                    setDeletePopConfirmVisible(true)
                  }}
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Tooltip>
          </Can>
        </ActionButtonWrapper>
      ),
    },
  ]

  const handleCreation = async () => {
    try {
      const fields = await form.validateFields()
      createField({
        variables: {
          ...fields,
        },
        refetchQueries: ['fields'],
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'settings.fields.action.create.success',
              message: 'Dataveld succesvol aangemaakt',
            }),
          })
          setFieldModalVisible(false)
        })
        .catch(errorNotifierFn)
    } catch {
      return
    }
  }

  const handleUpdate = async () => {
    try {
      const fields = await form.validateFields()
      updateField({
        variables: {
          id: updateSubject?._id,
          ...fields,
        },
        refetchQueries: ['fields'],
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'settings.fields.action.update.success',
              message: 'Dataveld succesvol gewijzigd',
            }),
          })
          setFieldModalVisible(false)
        })
        .catch(errorNotifierFn)
    } catch {
      return
    }
  }

  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={t({
          id: 'settings.fields.title',
          message: 'Datavelden',
        })}
        style={{ backgroundColor: '#FFF' }}
        extra={[
          <InputSearch
            key="1"
            placeholder={t({
              id: 'settings.fields.search',
              message: 'Zoeken op naam',
            })}
            onSearch={(value) => {
              setSearchTerm(value)
              setPage(1)
            }}
            style={{ width: 200 }}
          />,
          <Can
            key="2"
            I={PermissionAction.CREATE}
            a={PermissionObjectType.CUSTOM_FIELD}
          >
            <DropdownButton
              icon={<DownOutlined />}
              onClick={() => setFieldModalVisible(true)}
              type="primary"
              menu={{
                hidden: true,
                items: [
                  {
                    key: 'import-fields',
                    label: t({
                      id: 'settings.fields.action.import',
                      message: 'Datavelden importeren',
                    }),
                  },
                ],
              }}
            >
              <Trans id="settings.fields.action.create">
                Dataveld aanmaken
              </Trans>
            </DropdownButton>
          </Can>,
        ]}
      />
      <Table
        locale={{
          emptyText: t({
            id: 'settings.fields.table.empty',
            message: 'Geen datavelden gevonden.',
          }),
          cancelSort: t({
            id: 'table.sort.cancel',
            message: 'Klik om niet langer te sorteren.',
          }),
          triggerAsc: t({
            id: 'table.sort.asc',
            message: 'Klik om oplopend te sorteren.',
          }),
          triggerDesc: t({
            id: 'table.sort.desc',
            message: 'Klik om aflopend te sorteren.',
          }),
        }}
        scroll={{ x: 400 }}
        dataSource={fields}
        loading={loading}
        columns={columns}
        showSorterTooltip={false}
        pagination={{
          current: page,
          onChange: (page: number) => setPage(page),
        }}
      />
      <Modal
        forceRender
        title={
          updateSubject
            ? t({
                id: 'settings.fields.action.update.title',
                message: 'Dataveld wijzigen',
              })
            : t({
                id: 'settings.fields.action.create.title',
                message: 'Nieuw dataveld aanmaken',
              })
        }
        open={fieldModalVisible}
        onOk={updateSubject ? handleUpdate : handleCreation}
        confirmLoading={creating || updating}
        onCancel={() => {
          setFieldModalVisible(false)
        }}
        afterClose={() => setUpdateSubject(undefined)}
        cancelText={t({
          id: 'actions.cancel',
          message: 'Annuleren',
        })}
        okText={
          updateSubject
            ? t({
                id: 'actions.update',
                message: 'Wijzigen',
              })
            : t({
                id: 'actions.create',
                message: 'Aanmaken',
              })
        }
        width={640}
      >
        <Form
          key={updateSubject?._id}
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={updateSubject ? handleUpdate : handleCreation}
          initialValues={updateSubject || undefined}
          autoComplete="off"
        >
          <Form.Item
            label={t({
              id: 'settings.fields.form.label.name',
              message: 'Naam',
            })}
            name="name"
            rules={[
              {
                required: true,
                message: t({
                  id: 'settings.fields.form.validation.name',
                  message: 'Gelieve de naam van de dataveld in te vullen',
                }),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t({
              id: 'settings.fields.form.label.id',
              message: 'ID',
            })}
            name="key"
            rules={[
              {
                required: true,
                message: t({
                  id: 'settings.fields.form.validation.id',
                  message: 'Gelieve een unieke identifier in te vullen',
                }),
              },
              {
                max: 24,
                message: t({
                  id: 'settings.extensions.form.validation.max_characters',
                  message: `Gelieve onder de ${24} tekens te blijven`,
                }),
              },
              {
                pattern: /^[_a-z]+$/g,
                message: t({
                  id: 'settings.fields.form.validation.id_pattern',
                  message:
                    'De ID mag enkel kleine letters en underscores bevatten.',
                }),
              },
            ]}
          >
            <Input disabled={!!updateSubject} />
          </Form.Item>
          <Form.Item
            label={t({
              id: 'settings.fields.form.label.type',
              message: 'Soort',
            })}
            name="type"
            required={true}
          >
            <Select
              options={[
                {
                  label: t({
                    id: 'settings.fields.form.type.text_long',
                    message: 'Tekst (lang)',
                  }),
                  value: FieldType.TextLong,
                },
                {
                  label: t({
                    id: 'settings.fields.form.type.text_short',
                    message: 'Tekst (kort)',
                  }),
                  value: FieldType.TextShort,
                },
                {
                  label: t({
                    id: 'settings.fields.form.type.thumbnail',
                    message: 'Thumbnail',
                  }),
                  value: FieldType.Thumbnail,
                },
                {
                  label: t({
                    id: 'settings.fields.form.type.boolean',
                    message: 'Booleaans',
                  }),
                  value: FieldType.Boolean,
                },
                {
                  label: t({
                    id: 'settings.fields.form.type.number',
                    message: 'Numeriek',
                  }),
                  value: FieldType.Number,
                },
                {
                  label: t({
                    id: 'settings.fields.form.type.date',
                    message: 'Datum',
                  }),
                  value: FieldType.Date,
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label={t({
              id: 'settings.fields.form.label.parent',
              message: 'Inhoudstype',
            })}
            name="parent"
            required={true}
          >
            <Select
              options={[
                {
                  label: t({
                    id: 'object.course',
                    message: 'Opleiding',
                  }),
                  value: FieldParent.Course,
                },
                {
                  label: t({
                    id: 'object.user',
                    message: 'Gebruiker',
                  }),
                  value: FieldParent.User,
                },
                {
                  label: t({
                    id: 'object.branch',
                    message: 'Kantoor',
                  }),
                  value: FieldParent.Branch,
                },
                {
                  label: t({
                    id: 'object.hierarchy_section',
                    message: 'Kantoorsectie',
                  }),
                  value: FieldParent.HierarchySection,
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label={t({
              id: 'settings.fields.form.label.visibility',
              message: 'Zichtbaarheid',
            })}
            name="public"
            valuePropName="checked"
          >
            <Switch
              unCheckedChildren={t({
                id: 'settings.fields.form.visibility.private',
                message: 'Admin',
              })}
              checkedChildren={t({
                id: 'settings.fields.form.visibility.public',
                message: 'Openbaar',
              })}
            />
          </Form.Item>
          <Form.Item
            label={t({
              id: 'settings.fields.form.label.hidden',
              message: 'Verborgen',
            })}
            name="hidden"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Button hidden disabled={creating} type="primary" htmlType={'submit'}>
            <Trans id="actions.save">Opslaan</Trans>
          </Button>
        </Form>
      </Modal>
    </>
  )
}
