import {
  UnlockOutlined,
  BuildOutlined,
  UserOutlined,
  BranchesOutlined,
  TagsOutlined,
  UsergroupAddOutlined,
  ClusterOutlined,
  SafetyCertificateOutlined,
  DatabaseOutlined,
  KeyOutlined,
  ControlOutlined,
  ApiOutlined,
  ShareAltOutlined,
} from '@ant-design/icons'
import { t } from '@lingui/macro'
import { Card, Col, Row } from 'antd'
import Meta from 'antd/lib/card/Meta'
import { useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'

import { AbilityContext } from '../../../auth/components/Can'
import { ApiKeys } from '../api-keys/ApiKeys'
import { Branches } from '../branches/Branches'
import { Categories } from '../categories/Categories'
import { CertificationTypes } from '../certifications/CertificationTypes'
import { Channels } from '../channels/Channels'
import { Extensions } from '../extensions/Extensions'
import { Fields } from '../fields/Fields'
import { Groups } from '../groups/Groups'
import { RolesSettings } from '../roles/Roles'
import { Rules } from '../rules/Rules'
import { CourseTypes } from '../types/CourseTypes'
import { Users } from '../users/Users'
import { Webhooks } from '../webhooks/Webhooks'

import { Content, CustomTabs } from './General.styles'

export const GeneralSettings = () => {
  const navigate = useNavigate()
  const { setting } = useParams()
  const [section, setSection] = useState(setting || 'general')
  const ability = useContext(AbilityContext)

  const loadSection = (section: string) => {
    navigate('/settings/' + section)
    setSection(section)
  }

  return (
    <CustomTabs
      type="card"
      activeKey={section}
      onChange={(key) => loadSection(key)}
    >
      <CustomTabs.TabPane
        tab={t({
          id: 'settings.general.title',
          message: 'Overzicht',
        })}
        key="general"
      >
        <Content>
          <Row gutter={[16, 16]}>
            {ability.can(PermissionAction.READ, PermissionObjectType.USER) && (
              <Col sm={{ span: 12 }} md={{ span: 6 }} span={24}>
                <Card hoverable={true} onClick={() => loadSection('users')}>
                  <Meta
                    title={t({
                      id: 'settings.general.users.title',
                      message: 'Gebruikers',
                    })}
                    avatar={<UserOutlined style={{ fontSize: '1.5rem' }} />}
                  />
                </Card>
              </Col>
            )}
            {ability.can(PermissionAction.READ, PermissionObjectType.ROLE) && (
              <Col sm={{ span: 12 }} md={{ span: 6 }} span={24}>
                <Card hoverable={true} onClick={() => loadSection('roles')}>
                  <Meta
                    title={t({
                      id: 'settings.general.roles.title',
                      message: 'Gebruikersrollen',
                    })}
                    avatar={<UnlockOutlined style={{ fontSize: '1.5rem' }} />}
                  />
                </Card>
              </Col>
            )}
            {ability.can(
              PermissionAction.READ,
              PermissionObjectType.USER_GROUP
            ) && (
              <Col sm={{ span: 12 }} md={{ span: 6 }} span={24}>
                <Card hoverable={true} onClick={() => loadSection('groups')}>
                  <Meta
                    title={t({
                      id: 'settings.general.groups.title',
                      message: 'Groepen',
                    })}
                    avatar={
                      <UsergroupAddOutlined style={{ fontSize: '1.5rem' }} />
                    }
                  />
                </Card>
              </Col>
            )}
            {ability.can(
              PermissionAction.READ,
              PermissionObjectType.BRANCH
            ) && (
              <Col sm={{ span: 12 }} md={{ span: 6 }} span={24}>
                <Card hoverable={true} onClick={() => loadSection('branches')}>
                  <Meta
                    title={t({
                      id: 'settings.general.branches.title',
                      message: 'Afdelingen',
                    })}
                    avatar={<BranchesOutlined style={{ fontSize: '1.5rem' }} />}
                  />
                </Card>
              </Col>
            )}
            {ability.can(
              PermissionAction.READ,
              PermissionObjectType.CHANNEL
            ) && (
              <Col sm={{ span: 12 }} md={{ span: 6 }} span={24}>
                <Card hoverable={true} onClick={() => loadSection('channels')}>
                  <Meta
                    title={t({
                      id: 'settings.general.channels.title',
                      message: 'Kennisbronnen',
                    })}
                    avatar={<ShareAltOutlined style={{ fontSize: '1.5rem' }} />}
                  />
                </Card>
              </Col>
            )}
            {ability.can(
              PermissionAction.READ,
              PermissionObjectType.COURSE_CATEGORY
            ) && (
              <Col sm={{ span: 12 }} md={{ span: 6 }} span={24}>
                <Card
                  hoverable={true}
                  onClick={() => loadSection('categories')}
                >
                  <Meta
                    title={t({
                      id: 'settings.general.categories.title',
                      message: 'Categorieën',
                    })}
                    avatar={<TagsOutlined style={{ fontSize: '1.5rem' }} />}
                  />
                </Card>
              </Col>
            )}
            {ability.can(
              PermissionAction.READ,
              PermissionObjectType.COURSE_TYPE
            ) && (
              <Col sm={{ span: 12 }} md={{ span: 6 }} span={24}>
                <Card
                  hoverable={true}
                  onClick={() => loadSection('course-types')}
                >
                  <Meta
                    title={t({
                      id: 'settings.general.course_types.title',
                      message: 'Opleidingstypes',
                    })}
                    avatar={<ClusterOutlined style={{ fontSize: '1.5rem' }} />}
                  />
                </Card>
              </Col>
            )}
            {ability.can(
              PermissionAction.READ,
              PermissionObjectType.CERTIFICATION_TYPE
            ) && (
              <Col sm={{ span: 12 }} md={{ span: 6 }} span={24}>
                <Card
                  hoverable={true}
                  onClick={() => loadSection('certification-types')}
                >
                  <Meta
                    title={t({
                      id: 'settings.general.certification_types.title',
                      message: 'Certificeringstypes',
                    })}
                    avatar={
                      <SafetyCertificateOutlined
                        style={{ fontSize: '1.5rem' }}
                      />
                    }
                  />
                </Card>
              </Col>
            )}

            <Col sm={{ span: 12 }} md={{ span: 6 }} span={24}>
              <Card hoverable={true} onClick={() => loadSection('fields')}>
                <Meta
                  title={t({
                    id: 'settings.general.fields.title',
                    message: 'Datavelden',
                  })}
                  avatar={<DatabaseOutlined style={{ fontSize: '1.5rem' }} />}
                />
              </Card>
            </Col>

            {ability.can(
              PermissionAction.CREATE,
              PermissionObjectType.EXTENSION
            ) && (
              <Col sm={{ span: 12 }} md={{ span: 6 }} span={24}>
                <Card
                  hoverable={true}
                  onClick={() => loadSection('extensions')}
                >
                  <Meta
                    title={t({
                      id: 'settings.general.extensions.title',
                      message: 'Extensies',
                    })}
                    avatar={<BuildOutlined style={{ fontSize: '1.5rem' }} />}
                  />
                </Card>
              </Col>
            )}

            {ability.can(
              PermissionAction.CREATE,
              PermissionObjectType.API_KEY
            ) && (
              <Col sm={{ span: 12 }} md={{ span: 6 }} span={24}>
                <Card hoverable={true} onClick={() => loadSection('apikeys')}>
                  <Meta
                    title={t({
                      id: 'settings.general.api_keys.title',
                      message: 'API-sleutels',
                    })}
                    avatar={<KeyOutlined style={{ fontSize: '1.5rem' }} />}
                  />
                </Card>
              </Col>
            )}

            {ability.can(PermissionAction.READ, PermissionObjectType.RULE) && (
              <Col sm={{ span: 12 }} md={{ span: 6 }} span={24}>
                <Card hoverable={true} onClick={() => loadSection('rules')}>
                  <Meta
                    title={t({
                      id: 'settings.general.rules.title',
                      message: 'Regels',
                    })}
                    avatar={<ControlOutlined style={{ fontSize: '1.5rem' }} />}
                  />
                </Card>
              </Col>
            )}

            {ability.can(
              PermissionAction.READ,
              PermissionObjectType.WEBHOOK
            ) && (
              <Col sm={{ span: 12 }} md={{ span: 6 }} span={24}>
                <Card hoverable={true} onClick={() => loadSection('webhooks')}>
                  <Meta
                    title={t({
                      id: 'settings.general.webhooks.title',
                      message: 'Webhooks',
                    })}
                    avatar={<ApiOutlined style={{ fontSize: '1.5rem' }} />}
                  />
                </Card>
              </Col>
            )}
          </Row>
        </Content>
      </CustomTabs.TabPane>
      {ability.can(PermissionAction.READ, PermissionObjectType.USER) && (
        <CustomTabs.TabPane
          tab={t({
            id: 'settings.general.users.title',
            message: 'Gebruikers',
          })}
          key="users"
          disabled={false}
        >
          <Users />
        </CustomTabs.TabPane>
      )}
      {ability.can(PermissionAction.READ, PermissionObjectType.ROLE) && (
        <CustomTabs.TabPane
          tab={t({
            id: 'settings.general.roles.title',
            message: 'Gebruikersrollen',
          })}
          key="roles"
          disabled={false}
        >
          <RolesSettings />
        </CustomTabs.TabPane>
      )}
      {ability.can(PermissionAction.READ, PermissionObjectType.USER_GROUP) && (
        <CustomTabs.TabPane
          tab={t({
            id: 'settings.general.groups.title',
            message: 'Groepen',
          })}
          key="groups"
          disabled={false}
        >
          <Groups />
        </CustomTabs.TabPane>
      )}
      {ability.can(PermissionAction.READ, PermissionObjectType.BRANCH) && (
        <CustomTabs.TabPane
          tab={t({
            id: 'settings.general.branches.title',
            message: 'Afdelingen',
          })}
          key="branches"
          disabled={false}
        >
          <Branches />
        </CustomTabs.TabPane>
      )}
      {ability.can(PermissionAction.READ, PermissionObjectType.CHANNEL) && (
        <CustomTabs.TabPane
          tab={t({
            id: 'settings.general.channels.title',
            message: 'Kennisbronnen',
          })}
          key="channels"
          disabled={false}
        >
          <Channels />
        </CustomTabs.TabPane>
      )}
      {ability.can(
        PermissionAction.READ,
        PermissionObjectType.COURSE_CATEGORY
      ) && (
        <CustomTabs.TabPane
          tab={t({
            id: 'settings.general.categories.title',
            message: 'Categorieën',
          })}
          key="categories"
          disabled={false}
        >
          <Categories />
        </CustomTabs.TabPane>
      )}
      {ability.can(PermissionAction.READ, PermissionObjectType.COURSE_TYPE) && (
        <CustomTabs.TabPane
          tab={t({
            id: 'settings.general.course_types.title',
            message: 'Opleidingstypes',
          })}
          key="course-types"
          disabled={false}
        >
          <CourseTypes />
        </CustomTabs.TabPane>
      )}
      {ability.can(
        PermissionAction.READ,
        PermissionObjectType.CERTIFICATION_TYPE
      ) && (
        <CustomTabs.TabPane
          tab={t({
            id: 'settings.general.certification_types.title',
            message: 'Certificeringstypes',
          })}
          key="certification-types"
          disabled={false}
        >
          <CertificationTypes />
        </CustomTabs.TabPane>
      )}
      <CustomTabs.TabPane
        tab={t({
          id: 'settings.general.fields.title',
          message: 'Datavelden',
        })}
        key="fields"
        disabled={false}
      >
        <Fields />
      </CustomTabs.TabPane>
      {ability.can(PermissionAction.CREATE, PermissionObjectType.EXTENSION) && (
        <CustomTabs.TabPane
          tab={t({
            id: 'settings.general.extensions.title',
            message: 'Extensies',
          })}
          key="extensions"
          disabled={false}
        >
          <Extensions />
        </CustomTabs.TabPane>
      )}
      {ability.can(PermissionAction.CREATE, PermissionObjectType.API_KEY) && (
        <CustomTabs.TabPane
          tab={t({
            id: 'settings.general.api_keys.title',
            message: 'API-sleutels',
          })}
          key="apikeys"
          disabled={false}
        >
          <ApiKeys />
        </CustomTabs.TabPane>
      )}
      {ability.can(PermissionAction.CREATE, PermissionObjectType.RULE) && (
        <CustomTabs.TabPane
          tab={t({
            id: 'settings.general.rules.title',
            message: 'Regels',
          })}
          key="rules"
          disabled={false}
        >
          <Rules />
        </CustomTabs.TabPane>
      )}
      {ability.can(PermissionAction.READ, PermissionObjectType.WEBHOOK) && (
        <CustomTabs.TabPane
          tab={t({
            id: 'settings.general.webhooks.title',
            message: 'Webhooks',
          })}
          key="webhooks"
          disabled={false}
        >
          <Webhooks />
        </CustomTabs.TabPane>
      )}
    </CustomTabs>
  )
}
