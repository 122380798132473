/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable sonarjs/cognitive-complexity */
import {
  AbilityBuilder,
  createMongoAbility,
  subject as subjectfn,
} from '@casl/ability'

type AbilityRole = {
  branch_id: string
  section_id?: string | null
  section_path?: string | null
  role_level: number
  permissions: { action: string; subject: string }[]
}

export enum PermissionAction {
  ACCESS = 'access',
  ARCHIVE = 'archive',
  CREATE = 'create',
  DOWNLOAD = 'download',
  READ = 'read',
  UPDATE = 'update',
  DELETE = 'delete',
  ASSIGN = 'assign',
  SHARE = 'share',
  CONFIGURE = 'configure',
  IMPORT = 'import',
  EXPORT = 'export',
  PIN = 'pin',
  ADD_TO_CALENDAR = 'add_to_calendar',
  EMAIL = 'email',
}
export enum PermissionObjectType {
  ACTIVITY = 'activity',
  API_KEY = 'api_key',
  ARTICLE = 'article',
  ARTICLE_DRAFT = 'article_draft',
  ASSIGNMENT = 'assignment',
  CHANNEL = 'channel',
  CLOUDFLARE = 'cloudflare',
  DASHBOARD = 'dashboard',
  EVENT = 'event',
  EXTENSION = 'extension',
  HIERARCHY = 'hierarchy',
  JOB_OPPORTUNITY = 'job_opportunity',
  LIKE = 'like',
  OWN_ARTICLE = 'own_article',
  OWN_BRANCH = 'own_branch',
  OWN_COURSE = 'own_course',
  OWN_REPORT = 'own_report',
  OWN_USER = 'own_user',
  BRANCH = 'branch',
  BRANCH_ARTICLE = 'branch_article',
  BRANCH_ARTICLE_DRAFT = 'branch_article_draft',
  BRANCH_CERTIFICATION_TYPE = 'branch_certification_type',
  BRANCH_COURSE = 'branch_course',
  BRANCH_COURSE_ATTRIBUTES = 'branch_course_attributes',
  BRANCH_COURSE_CATEGORY = 'branch_course_category',
  BRANCH_COURSE_DRAFT = 'branch_course_draft',
  BRANCH_COURSE_TAG = 'branch_course_tag',
  BRANCH_HIERARCHY = 'branch_hierarchy',
  BRANCH_JOB_OPPORTUNITY = 'branch_job_opportunity',
  BRANCH_REPORT = 'branch_report',
  BRANCH_ROLE = 'branch_role',
  BRANCH_RULE = 'branch_rule',
  BRANCH_SOFTWARE = 'branch_software',
  BRANCH_USER = 'branch_user',
  BRANCH_USER_GROUP = 'branch_user_group',
  COURSE_DRAFT = 'course_draft',
  COURSE = 'course',
  COURSE_CONTENTS = 'course_contents',
  COURSE_CATEGORY = 'course_category',
  COURSE_TYPE = 'course_type',
  CERTIFICATION_TYPE = 'certification_type',
  CUSTOM_FIELD = 'custom_field',
  ROLE = 'role',
  RULE = 'rule',
  USER = 'user',
  USER_GROUP = 'user_group',
  PLATFORM_SETTINGS = 'platform_settings',
  REPORT = 'report',
  SOFTWARE = 'software',
  VIDEO = 'video',
  WEBHOOK = 'webhook',
  WEBHOOK_DELIVERY = 'webhook_delivery',
}

export const subject = (type: string, object) => {
  if (!object) return subjectfn(type, {})
  const newObject = {
    ...object,
    _id: object._id?.toString(),
    owner: object.owner?.toString(),
    ownerBranch: object.ownerBranch?.toString(),
    branch_id: object.branch_id?.toString(),
  }
  return subjectfn(type, newObject)
}

export const PermissionData = [
  {
    category: 'activity',
    label: 'Activity',
    admin_only: true,
    children: [
      {
        label: 'Update any course/unit activity',
        action: PermissionAction.UPDATE,
        subject: PermissionObjectType.ACTIVITY,
      },
    ],
  },
  {
    category: 'apikeys',
    label: 'API Keys',
    admin_only: true,
    children: [
      {
        label: 'Create API keys',
        action: PermissionAction.CREATE,
        subject: PermissionObjectType.API_KEY,
      },
    ],
  },
  {
    category: 'articles',
    label: 'Articles',
    admin_only: false,
    children: [
      {
        label: 'Read publically shared articles',
        action: PermissionAction.READ,
        subject: PermissionObjectType.ARTICLE,
      },
      {
        label: 'Read any article draft',
        action: PermissionAction.READ,
        subject: PermissionObjectType.ARTICLE_DRAFT,
        admin_only: true,
      },
      {
        label: 'Create new article',
        action: PermissionAction.CREATE,
        subject: PermissionObjectType.ARTICLE,
      },
      {
        label: 'Update any article',
        action: PermissionAction.UPDATE,
        subject: PermissionObjectType.ARTICLE,
        admin_only: true,
      },
      {
        label: 'Delete any article',
        action: PermissionAction.DELETE,
        subject: PermissionObjectType.ARTICLE,
        admin_only: true,
      },
      {
        label: 'Share any article outside of their branch',
        action: PermissionAction.SHARE,
        subject: PermissionObjectType.ARTICLE,
        admin_only: true,
      },
      {
        label: 'Read any branch article',
        action: PermissionAction.READ,
        subject: PermissionObjectType.BRANCH_ARTICLE,
      },
      {
        label: 'Read any branch article draft',
        action: PermissionAction.READ,
        subject: PermissionObjectType.BRANCH_ARTICLE_DRAFT,
      },
      {
        label: 'Update any branch articles',
        action: PermissionAction.UPDATE,
        subject: PermissionObjectType.BRANCH_ARTICLE,
      },
      {
        label: 'Delete any branch article',
        action: PermissionAction.DELETE,
        subject: PermissionObjectType.BRANCH_ARTICLE,
      },
      {
        label: 'Share any branch article outside of the branch',
        action: PermissionAction.SHARE,
        subject: PermissionObjectType.BRANCH_ARTICLE,
        admin_only: true,
      },
      {
        label: 'Read own articles',
        action: PermissionAction.READ,
        subject: PermissionObjectType.OWN_ARTICLE,
      },
      {
        label: 'Update own articles',
        action: PermissionAction.UPDATE,
        subject: PermissionObjectType.OWN_ARTICLE,
      },
      {
        label: 'Delete own articles',
        action: PermissionAction.DELETE,
        subject: PermissionObjectType.OWN_ARTICLE,
      },
      {
        label: 'Share own articles outside of the branch',
        action: PermissionAction.SHARE,
        subject: PermissionObjectType.OWN_ARTICLE,
        admin_only: true,
      },
    ],
  },
  {
    category: 'assignments',
    label: 'Assignments',
    admin_only: false,
    children: [
      {
        label: 'Read my assignments',
        action: PermissionAction.READ,
        subject: PermissionObjectType.ASSIGNMENT,
      },
      {
        label: 'Create an assignment',
        action: PermissionAction.CREATE,
        subject: PermissionObjectType.ASSIGNMENT,
      },
      {
        label: 'Update an assignment',
        action: PermissionAction.UPDATE,
        subject: PermissionObjectType.ASSIGNMENT,
      },
      {
        label: 'Delete an assignment',
        action: PermissionAction.DELETE,
        subject: PermissionObjectType.ASSIGNMENT,
      },
    ],
  },
  {
    category: 'branches',
    label: 'Branches',
    admin_only: false,
    children: [
      {
        label: 'Read any branch',
        action: PermissionAction.READ,
        subject: PermissionObjectType.BRANCH,
        admin_only: true,
      },
      {
        label: 'Create new branch',
        action: PermissionAction.CREATE,
        subject: PermissionObjectType.BRANCH,
        admin_only: true,
      },
      {
        label: 'Update any branch',
        action: PermissionAction.UPDATE,
        subject: PermissionObjectType.BRANCH,
        admin_only: true,
      },
      {
        label: 'Read all hierarchies',
        action: PermissionAction.READ,
        subject: PermissionObjectType.HIERARCHY,
        admin_only: true,
      },
      {
        label: 'Update all hierarchies',
        action: PermissionAction.UPDATE,
        subject: PermissionObjectType.HIERARCHY,
        admin_only: true,
      },
      {
        label: 'Read the branch hierarchy',
        action: PermissionAction.READ,
        subject: PermissionObjectType.BRANCH_HIERARCHY,
      },
      {
        label: 'Update the branch hierarchy',
        action: PermissionAction.UPDATE,
        subject: PermissionObjectType.BRANCH_HIERARCHY,
      },
      {
        label: 'Delete any branch',
        action: PermissionAction.DELETE,
        subject: PermissionObjectType.BRANCH,
        admin_only: true,
      },
      {
        label: 'Read own branch',
        action: PermissionAction.READ,
        subject: PermissionObjectType.OWN_BRANCH,
      },
      {
        label: 'Update own branch',
        action: PermissionAction.UPDATE,
        subject: PermissionObjectType.OWN_BRANCH,
      },
    ],
  },
  {
    category: 'certification_types',
    label: 'Certification types',
    admin_only: false,
    children: [
      {
        label: 'Assign a certification type',
        action: PermissionAction.ASSIGN,
        subject: PermissionObjectType.CERTIFICATION_TYPE,
        admin_only: true,
      },
      {
        label: 'Read any certification type',
        action: PermissionAction.READ,
        subject: PermissionObjectType.CERTIFICATION_TYPE,
      },
      {
        label: 'Create new certification type',
        action: PermissionAction.CREATE,
        subject: PermissionObjectType.CERTIFICATION_TYPE,
        admin_only: true,
      },
      {
        label: 'Update any certification type',
        action: PermissionAction.UPDATE,
        subject: PermissionObjectType.CERTIFICATION_TYPE,
        admin_only: true,
      },
      {
        label: 'Delete any certification type',
        action: PermissionAction.DELETE,
        subject: PermissionObjectType.CERTIFICATION_TYPE,
        admin_only: true,
      },
      {
        label: 'Assign a branch certification type',
        action: PermissionAction.ASSIGN,
        subject: PermissionObjectType.BRANCH_CERTIFICATION_TYPE,
      },
      {
        label: 'Read any branch certification type',
        action: PermissionAction.READ,
        subject: PermissionObjectType.BRANCH_CERTIFICATION_TYPE,
      },
      {
        label: 'Create new branch certification type',
        action: PermissionAction.CREATE,
        subject: PermissionObjectType.BRANCH_CERTIFICATION_TYPE,
      },
      {
        label: 'Update any branch certification type',
        action: PermissionAction.UPDATE,
        subject: PermissionObjectType.BRANCH_CERTIFICATION_TYPE,
      },
      {
        label: 'Delete any branch certification type',
        action: PermissionAction.DELETE,
        subject: PermissionObjectType.BRANCH_CERTIFICATION_TYPE,
      },
    ],
  },
  {
    category: 'channels',
    label: 'Channels',
    admin_only: false,
    children: [
      {
        label: 'Create new channel',
        action: PermissionAction.CREATE,
        subject: PermissionObjectType.CHANNEL,
        admin_only: true,
      },
      {
        label: 'Read any channel',
        action: PermissionAction.READ,
        subject: PermissionObjectType.CHANNEL,
        admin_only: false,
      },
      {
        label: 'Update any channel',
        action: PermissionAction.UPDATE,
        subject: PermissionObjectType.CHANNEL,
        admin_only: true,
      },
      {
        label: 'Delete any channel',
        action: PermissionAction.DELETE,
        subject: PermissionObjectType.CHANNEL,
        admin_only: true,
      },
    ],
  },
  {
    category: 'courses',
    label: 'Courses',
    admin_only: false,
    children: [
      {
        label: 'Read publically shared courses',
        action: PermissionAction.READ,
        subject: PermissionObjectType.COURSE,
      },
      {
        label: 'Read any course draft',
        action: PermissionAction.READ,
        subject: PermissionObjectType.COURSE_DRAFT,
        admin_only: true,
      },
      {
        label: 'Create new course',
        action: PermissionAction.CREATE,
        subject: PermissionObjectType.COURSE,
      },
      {
        label: 'Update any course',
        action: PermissionAction.UPDATE,
        subject: PermissionObjectType.COURSE,
        admin_only: true,
      },
      {
        label: 'Delete any course',
        action: PermissionAction.DELETE,
        subject: PermissionObjectType.COURSE,
        admin_only: true,
      },
      {
        label: 'Share any course outside of their branch',
        action: PermissionAction.SHARE,
        subject: PermissionObjectType.COURSE,
        admin_only: true,
      },
      {
        label: 'Read any branch course',
        action: PermissionAction.READ,
        subject: PermissionObjectType.BRANCH_COURSE,
      },
      {
        label: 'Read any branch course draft',
        action: PermissionAction.READ,
        subject: PermissionObjectType.BRANCH_COURSE_DRAFT,
      },
      {
        label: 'Update branch courses',
        action: PermissionAction.UPDATE,
        subject: PermissionObjectType.BRANCH_COURSE,
      },
      {
        label: 'Delete any branch course',
        action: PermissionAction.DELETE,
        subject: PermissionObjectType.BRANCH_COURSE,
      },
      {
        label: 'Share any branch course outside of the branch',
        action: PermissionAction.SHARE,
        subject: PermissionObjectType.BRANCH_COURSE,
        admin_only: true,
      },
      {
        label: 'Pin any branch course',
        action: PermissionAction.PIN,
        subject: PermissionObjectType.BRANCH_COURSE,
        admin_only: true,
      },
      {
        label: 'Assign branch course tags',
        action: PermissionAction.ASSIGN,
        subject: PermissionObjectType.BRANCH_COURSE_TAG,
      },
      {
        label: 'Read own courses',
        action: PermissionAction.READ,
        subject: PermissionObjectType.OWN_COURSE,
      },
      {
        label: 'Update own courses',
        action: PermissionAction.UPDATE,
        subject: PermissionObjectType.OWN_COURSE,
      },
      {
        label: 'Archive own courses',
        action: PermissionAction.ARCHIVE,
        subject: PermissionObjectType.OWN_COURSE,
      },
      {
        label: 'Delete own courses',
        action: PermissionAction.DELETE,
        subject: PermissionObjectType.OWN_COURSE,
      },
      {
        label: 'Share own courses outside of the branch',
        action: PermissionAction.SHARE,
        subject: PermissionObjectType.OWN_COURSE,
        admin_only: true,
      },
      {
        label: 'Add course to calendar',
        action: PermissionAction.ADD_TO_CALENDAR,
        subject: PermissionObjectType.COURSE,
      },
    ],
  },
  {
    category: 'course_attributes',
    label: 'Course attributes',
    admin_only: false,
    children: [
      {
        label: 'Read any branch course attributes',
        action: PermissionAction.READ,
        subject: PermissionObjectType.BRANCH_COURSE_ATTRIBUTES,
      },
      {
        label: 'Update any branch course attributes',
        action: PermissionAction.UPDATE,
        subject: PermissionObjectType.BRANCH_COURSE_ATTRIBUTES,
      },
    ],
  },
  {
    category: 'course_categories',
    label: 'Course categories',
    admin_only: false,
    children: [
      {
        label: 'Read any course category',
        action: PermissionAction.READ,
        subject: PermissionObjectType.COURSE_CATEGORY,
      },
      {
        label: 'Create new course category',
        action: PermissionAction.CREATE,
        subject: PermissionObjectType.COURSE_CATEGORY,
        admin_only: true,
      },
      {
        label: 'Update any course category',
        action: PermissionAction.UPDATE,
        subject: PermissionObjectType.COURSE_CATEGORY,
        admin_only: true,
      },
      {
        label: 'Delete any course category',
        action: PermissionAction.DELETE,
        subject: PermissionObjectType.COURSE_CATEGORY,
        admin_only: true,
      },
      {
        label: 'Read any branch course category',
        action: PermissionAction.READ,
        subject: PermissionObjectType.BRANCH_COURSE_CATEGORY,
      },
      {
        label: 'Create new branch course category',
        action: PermissionAction.CREATE,
        subject: PermissionObjectType.BRANCH_COURSE_CATEGORY,
      },
      {
        label: 'Update any branch course category',
        action: PermissionAction.UPDATE,
        subject: PermissionObjectType.BRANCH_COURSE_CATEGORY,
      },
      {
        label: 'Delete any branch course category',
        action: PermissionAction.DELETE,
        subject: PermissionObjectType.BRANCH_COURSE_CATEGORY,
      },
    ],
  },
  {
    category: 'course_types',
    label: 'Course types',
    admin_only: false,
    children: [
      {
        label: 'Read any course type',
        action: PermissionAction.READ,
        subject: PermissionObjectType.COURSE_TYPE,
      },
      {
        label: 'Create new course type',
        action: PermissionAction.CREATE,
        subject: PermissionObjectType.COURSE_TYPE,
        admin_only: true,
      },
      {
        label: 'Update any course type',
        action: PermissionAction.UPDATE,
        subject: PermissionObjectType.COURSE_TYPE,
        admin_only: true,
      },
      {
        label: 'Delete any course type',
        action: PermissionAction.DELETE,
        subject: PermissionObjectType.COURSE_TYPE,
        admin_only: true,
      },
    ],
  },
  {
    category: 'custom_fields',
    label: 'Custom fields',
    admin_only: true,
    children: [
      {
        // Deprecated
        label: 'Read any custom field',
        action: PermissionAction.READ,
        subject: PermissionObjectType.CUSTOM_FIELD,
      },
      {
        label: 'Create new custom field',
        action: PermissionAction.CREATE,
        subject: PermissionObjectType.CUSTOM_FIELD,
      },
      {
        label: 'Update any custom field',
        action: PermissionAction.UPDATE,
        subject: PermissionObjectType.CUSTOM_FIELD,
      },
      {
        label: 'Delete any custom field',
        action: PermissionAction.DELETE,
        subject: PermissionObjectType.CUSTOM_FIELD,
      },
    ],
  },
  {
    category: 'course_likes',
    label: 'Course likes',
    admin_only: false,
    children: [
      {
        label: 'Like/unlike any course',
        action: PermissionAction.CREATE,
        subject: PermissionObjectType.LIKE,
      },
      {
        label: 'Read own course likes',
        action: PermissionAction.READ,
        subject: PermissionObjectType.LIKE,
      },
    ],
  },
  {
    category: 'dashboard',
    label: 'Dashboard',
    admin_only: false,
    children: [
      {
        label: 'Access dashboard',
        action: PermissionAction.READ,
        subject: PermissionObjectType.DASHBOARD,
      },
    ],
  },
  {
    category: 'events',
    label: 'Events',
    admin_only: false,
    children: [
      {
        label: 'Create an event',
        action: PermissionAction.CREATE,
        subject: PermissionObjectType.EVENT,
      },
    ],
  },
  {
    category: 'extensions',
    label: 'Extensions',
    admin_only: true,
    children: [
      {
        label: 'Create new extension',
        action: PermissionAction.CREATE,
        subject: PermissionObjectType.EXTENSION,
      },
      {
        label: 'Update any extension',
        action: PermissionAction.UPDATE,
        subject: PermissionObjectType.EXTENSION,
      },
      {
        label: 'Delete any extension',
        action: PermissionAction.DELETE,
        subject: PermissionObjectType.EXTENSION,
      },
    ],
  },
  {
    category: 'global_settings',
    label: 'Global settings',
    admin_only: true,
    children: [
      {
        label: 'Access global settings',
        action: PermissionAction.ACCESS,
        subject: PermissionObjectType.PLATFORM_SETTINGS,
      },
      {
        label: 'Configure global settings',
        action: PermissionAction.CONFIGURE,
        subject: PermissionObjectType.PLATFORM_SETTINGS,
      },
    ],
  },
  {
    category: 'import_export',
    label: 'Import/Export',
    children: [
      {
        label: 'Import articles into own branch',
        action: PermissionAction.IMPORT,
        subject: PermissionObjectType.BRANCH_ARTICLE,
        admin_only: true,
      },
      {
        label: 'Import articles',
        action: PermissionAction.IMPORT,
        subject: PermissionObjectType.ARTICLE,
        admin_only: true,
      },
      {
        label: 'Import branches',
        action: PermissionAction.IMPORT,
        subject: PermissionObjectType.BRANCH,
        admin_only: true,
      },
      {
        label: 'Import branch hierarchy',
        action: PermissionAction.IMPORT,
        subject: PermissionObjectType.BRANCH_HIERARCHY,
        admin_only: true,
      },
      {
        label: 'Import courses into own branch',
        action: PermissionAction.IMPORT,
        subject: PermissionObjectType.BRANCH_COURSE,
        admin_only: true,
      },
      {
        label: 'Import courses',
        action: PermissionAction.IMPORT,
        subject: PermissionObjectType.COURSE,
        admin_only: true,
      },
      {
        label: 'Import users into own branch',
        action: PermissionAction.IMPORT,
        subject: PermissionObjectType.BRANCH_USER,
        admin_only: true,
      },
      {
        label: 'Import users',
        action: PermissionAction.IMPORT,
        subject: PermissionObjectType.USER,
        admin_only: true,
      },
      {
        label: 'Export users',
        action: PermissionAction.EXPORT,
        subject: PermissionObjectType.USER,
        admin_only: true,
      },
      {
        label: 'Export branch users',
        action: PermissionAction.EXPORT,
        subject: PermissionObjectType.BRANCH_USER,
      },
    ],
  },
  {
    category: 'jobs',
    label: 'Job opportunities',
    admin_only: false,
    children: [
      {
        label: 'Read job opportunities',
        action: PermissionAction.READ,
        subject: PermissionObjectType.JOB_OPPORTUNITY,
      },
      {
        label: 'Read branch job opportunities',
        action: PermissionAction.READ,
        subject: PermissionObjectType.BRANCH_JOB_OPPORTUNITY,
      },
      {
        label: 'Create branch job opportunities',
        action: PermissionAction.CREATE,
        subject: PermissionObjectType.BRANCH_JOB_OPPORTUNITY,
      },
      {
        label: 'Update branch job opportunities',
        action: PermissionAction.UPDATE,
        subject: PermissionObjectType.BRANCH_JOB_OPPORTUNITY,
      },
      {
        label: 'Delete branch job opportunities',
        action: PermissionAction.DELETE,
        subject: PermissionObjectType.BRANCH_JOB_OPPORTUNITY,
      },
    ],
  },
  {
    category: 'reports',
    label: 'Reports',
    admin_only: false,
    children: [
      {
        label: 'Read any report',
        action: PermissionAction.READ,
        subject: PermissionObjectType.REPORT,
        admin_only: true,
      },
      {
        label: 'Read branch reports',
        action: PermissionAction.READ,
        subject: PermissionObjectType.BRANCH_REPORT,
      },
      {
        label: 'Read personal report',
        action: PermissionAction.READ,
        subject: PermissionObjectType.OWN_REPORT,
      },
    ],
  },
  {
    category: 'roles',
    label: 'Roles',
    admin_only: false,
    children: [
      {
        label: 'Assign a role',
        action: PermissionAction.ASSIGN,
        subject: PermissionObjectType.ROLE,
      },
      {
        label: 'Read any role',
        action: PermissionAction.READ,
        subject: PermissionObjectType.ROLE,
      },
      {
        label: 'Create new role',
        action: PermissionAction.CREATE,
        subject: PermissionObjectType.ROLE,
        admin_only: true,
      },
      {
        label: 'Update any role',
        action: PermissionAction.UPDATE,
        subject: PermissionObjectType.ROLE,
        admin_only: true,
      },
      {
        label: 'Delete any role',
        action: PermissionAction.DELETE,
        subject: PermissionObjectType.ROLE,
        admin_only: true,
      },
      {
        label: 'Read any branch role',
        action: PermissionAction.READ,
        subject: PermissionObjectType.BRANCH_ROLE,
      },
      {
        label: 'Create new branch role',
        action: PermissionAction.CREATE,
        subject: PermissionObjectType.BRANCH_ROLE,
      },
      {
        label: 'Update any branch role',
        action: PermissionAction.UPDATE,
        subject: PermissionObjectType.BRANCH_ROLE,
      },
      {
        label: 'Delete any branch role',
        action: PermissionAction.DELETE,
        subject: PermissionObjectType.BRANCH_ROLE,
      },
    ],
  },
  {
    category: 'rules',
    label: 'Rules',
    children: [
      {
        label: 'Create new rule',
        action: PermissionAction.CREATE,
        subject: PermissionObjectType.RULE,
        admin_only: true,
      },
      {
        label: 'Read any rule',
        action: PermissionAction.READ,
        subject: PermissionObjectType.RULE,
        admin_only: true,
      },
      {
        label: 'Update any rule',
        action: PermissionAction.UPDATE,
        subject: PermissionObjectType.RULE,
        admin_only: true,
      },
      {
        label: 'Delete any rule',
        action: PermissionAction.DELETE,
        subject: PermissionObjectType.RULE,
        admin_only: true,
      },
      {
        label: 'Create new branch rule',
        action: PermissionAction.CREATE,
        subject: PermissionObjectType.BRANCH_RULE,
        admin_only: true,
      },
      {
        label: 'Read any branch rule',
        action: PermissionAction.READ,
        subject: PermissionObjectType.BRANCH_RULE,
      },
      {
        label: 'Update any branch rule',
        action: PermissionAction.UPDATE,
        subject: PermissionObjectType.BRANCH_RULE,
        admin_only: true,
      },
      {
        label: 'Delete any branch rule',
        action: PermissionAction.DELETE,
        subject: PermissionObjectType.BRANCH_RULE,
        admin_only: true,
      },
    ],
  },
  {
    category: 'users',
    label: 'Users',
    admin_only: false,
    children: [
      {
        label: 'Read any users details',
        action: PermissionAction.READ,
        subject: PermissionObjectType.USER,
        admin_only: true,
      },
      {
        label: 'Create new user',
        action: PermissionAction.CREATE,
        subject: PermissionObjectType.USER,
        admin_only: true,
      },
      {
        label: 'Update any users details',
        action: PermissionAction.UPDATE,
        subject: PermissionObjectType.USER,
        admin_only: true,
      },
      {
        label: 'Delete any user',
        action: PermissionAction.DELETE,
        subject: PermissionObjectType.USER,
        admin_only: true,
      },
      {
        label: 'Create new branch user',
        action: PermissionAction.CREATE,
        subject: PermissionObjectType.BRANCH_USER,
      },
      {
        label: 'Read branch users details',
        action: PermissionAction.READ,
        subject: PermissionObjectType.BRANCH_USER,
      },
      {
        label: 'Update branch users details',
        action: PermissionAction.UPDATE,
        subject: PermissionObjectType.BRANCH_USER,
      },
      {
        label: 'Delete branch users',
        action: PermissionAction.DELETE,
        subject: PermissionObjectType.BRANCH_USER,
      },
      {
        label: 'Read own user details',
        action: PermissionAction.READ,
        subject: PermissionObjectType.OWN_USER,
      },
      {
        label: 'Update own user details',
        action: PermissionAction.UPDATE,
        subject: PermissionObjectType.OWN_USER,
      },
      {
        label: 'Email any user',
        action: PermissionAction.EMAIL,
        subject: PermissionObjectType.USER,
        admin_only: true,
      },
    ],
  },
  {
    category: 'user_groups',
    label: 'User groups',
    admin_only: false,
    children: [
      {
        label: 'Read any user group',
        action: PermissionAction.READ,
        subject: PermissionObjectType.USER_GROUP,
        admin_only: true,
      },
      {
        label: 'Create new user group',
        action: PermissionAction.CREATE,
        subject: PermissionObjectType.USER_GROUP,
        admin_only: true,
      },
      {
        label: 'Update any user group',
        action: PermissionAction.UPDATE,
        subject: PermissionObjectType.USER_GROUP,
        admin_only: true,
      },
      {
        label: 'Delete any user group',
        action: PermissionAction.DELETE,
        subject: PermissionObjectType.USER_GROUP,
        admin_only: true,
      },
      {
        label: 'Read branch user groups',
        action: PermissionAction.READ,
        subject: PermissionObjectType.BRANCH_USER_GROUP,
      },
      {
        label: 'Create new branch user group',
        action: PermissionAction.CREATE,
        subject: PermissionObjectType.BRANCH_USER_GROUP,
      },
      {
        label: 'Update branch user groups',
        action: PermissionAction.UPDATE,
        subject: PermissionObjectType.BRANCH_USER_GROUP,
      },
      {
        label: 'Delete branch user groups',
        action: PermissionAction.DELETE,
        subject: PermissionObjectType.BRANCH_USER_GROUP,
      },
    ],
  },
  {
    category: 'software',
    label: 'Software & more',
    admin_only: false,
    children: [
      {
        label: 'Configure branch software vendors',
        action: PermissionAction.CONFIGURE,
        subject: PermissionObjectType.BRANCH_SOFTWARE,
      },
    ],
  },
  {
    category: 'videos',
    label: 'Videos',
    admin_only: true,
    children: [
      {
        label: 'Download any video',
        action: PermissionAction.DOWNLOAD,
        subject: PermissionObjectType.VIDEO,
      },
    ],
  },
  {
    category: 'webhooks',
    label: 'Webhooks',
    admin_only: true,
    children: [
      {
        label: 'Read any webhook',
        action: PermissionAction.READ,
        subject: PermissionObjectType.WEBHOOK,
      },
      {
        label: 'Create new webhook',
        action: PermissionAction.CREATE,
        subject: PermissionObjectType.WEBHOOK,
      },
      {
        label: 'Update any webhook',
        action: PermissionAction.UPDATE,
        subject: PermissionObjectType.WEBHOOK,
      },
      {
        label: 'Delete any webhook',
        action: PermissionAction.DELETE,
        subject: PermissionObjectType.WEBHOOK,
      },
    ],
  },
  {
    category: 'webhook_deliveries',
    label: 'Webhook deliveries',
    admin_only: true,
    children: [
      {
        label: 'Read any webhook delivery',
        action: PermissionAction.READ,
        subject: PermissionObjectType.WEBHOOK_DELIVERY,
      },
    ],
  },
]

export function createAbility(roles: AbilityRole[], user_id: string) {
  const { can, build } = new AbilityBuilder(createMongoAbility)

  roles.forEach(({ branch_id, section_id, permissions, role_level }) => {
    const ownerBranchCondition = {
      ownerBranch: branch_id,
    }

    const sharedBranchCondition = {
      'sharing.branches': branch_id,
    }

    const branchDraftCondition = {
      ownerBranchCondition: branch_id,
      published: null,
    }

    const ownerCondition = {
      owner: user_id,
    }

    const branchUserCondition = {
      'branches.branch_id': branch_id,
    }

    const sectionUserCondition = {
      'branches.section_path': {
        $regex: new RegExp(`${section_id || branch_id}`),
      },
    }

    const deeperLevelSectionUserCondition = {
      'branches.section_path': {
        $regex: new RegExp(`${section_id}`),
      },
      'branches.section_id': {
        $ne: section_id,
      },
    }

    const sameLevelSectionUserCondition = {
      'branches.section_id': section_id,
      'branches.role_level': {
        $gt: role_level,
      },
    }

    const userCondition = {
      _id: user_id,
    }

    const branchCondition = {
      _id: branch_id,
    }

    const groupBranchCondition = {
      branch_id: branch_id,
    }

    const sectionPathCondition = {
      path: { $regex: new RegExp(`${section_id || branch_id}`) },
    }

    const branchPathCondition = {
      path: { $regex: new RegExp(`${branch_id}`) },
    }

    permissions.forEach((permission) => {
      if (
        permission.action === PermissionAction.READ &&
        (permission.subject === PermissionObjectType.COURSE ||
          permission.subject === PermissionObjectType.ARTICLE)
      ) {
        // Read any course or article
        can(permission.action, permission.subject, {
          published: { $ne: null },
        })
      } else if (
        permission.action === PermissionAction.READ &&
        (permission.subject === PermissionObjectType.COURSE_DRAFT ||
          permission.subject === PermissionObjectType.ARTICLE_DRAFT)
      ) {
        // Read any draft course or article
        can(permission.action, permission.subject, {
          published: null,
        })
      } else if (
        permission.action === PermissionAction.READ &&
        (permission.subject === PermissionObjectType.OWN_COURSE ||
          permission.subject === PermissionObjectType.OWN_ARTICLE)
      ) {
        // Read own courses or articles
        can(permission.action, permission.subject, ownerCondition)
      } else if (
        permission.action === PermissionAction.READ &&
        (permission.subject === PermissionObjectType.BRANCH_COURSE ||
          permission.subject === PermissionObjectType.BRANCH_ARTICLE)
      ) {
        // Read branch course or article
        can(permission.action, permission.subject, sharedBranchCondition)
      } else if (
        permission.action === PermissionAction.READ &&
        (permission.subject === PermissionObjectType.BRANCH_COURSE_DRAFT ||
          permission.subject === PermissionObjectType.BRANCH_ARTICLE_DRAFT)
      ) {
        // Read branch course or article draft
        can(permission.action, permission.subject, branchDraftCondition)
      } else if (
        permission.action === PermissionAction.UPDATE &&
        (permission.subject === PermissionObjectType.BRANCH_COURSE ||
          permission.subject === PermissionObjectType.BRANCH_ARTICLE)
      ) {
        // Update branch courses or articles
        can(permission.action, permission.subject, ownerBranchCondition)
      } else if (
        permission.action === PermissionAction.DELETE &&
        (permission.subject === PermissionObjectType.BRANCH_COURSE ||
          permission.subject === PermissionObjectType.BRANCH_ARTICLE)
      ) {
        // Delete branch courses or articles
        can(permission.action, permission.subject, ownerBranchCondition)
      } else if (
        permission.action === PermissionAction.SHARE &&
        (permission.subject === PermissionObjectType.BRANCH_COURSE ||
          permission.subject === PermissionObjectType.BRANCH_ARTICLE)
      ) {
        // Share branch courses or articles
        can(permission.action, permission.subject, ownerBranchCondition)
      } else if (
        permission.action === PermissionAction.PIN &&
        (permission.subject === PermissionObjectType.BRANCH_COURSE ||
          permission.subject === PermissionObjectType.BRANCH_ARTICLE)
      ) {
        // Pin branch courses or articles
        can(permission.action, permission.subject, ownerBranchCondition)
      } else if (
        permission.action === PermissionAction.UPDATE &&
        (permission.subject === PermissionObjectType.OWN_COURSE ||
          permission.subject === PermissionObjectType.OWN_ARTICLE)
      ) {
        // Update own courses or articles
        can(permission.action, permission.subject, ownerCondition)
      } else if (
        permission.action === PermissionAction.ARCHIVE &&
        (permission.subject === PermissionObjectType.OWN_COURSE ||
          permission.subject === PermissionObjectType.OWN_ARTICLE)
      ) {
        // Archive own courses or articles
        can(permission.action, permission.subject, ownerCondition)
      } else if (
        permission.action === PermissionAction.DELETE &&
        (permission.subject === PermissionObjectType.OWN_COURSE ||
          permission.subject === PermissionObjectType.OWN_ARTICLE)
      ) {
        // Delete own courses or articles
        can(permission.action, permission.subject, ownerCondition)
      } else if (
        permission.action === PermissionAction.SHARE &&
        (permission.subject === PermissionObjectType.OWN_COURSE ||
          permission.subject === PermissionObjectType.OWN_ARTICLE)
      ) {
        // Share own courses or articles
        can(permission.action, permission.subject, ownerCondition)
      } else if (
        permission.action === PermissionAction.READ &&
        permission.subject === PermissionObjectType.BRANCH_REPORT
      ) {
        if (branch_id && !section_id) {
          // Read branch reports
          can(permission.action, permission.subject, {
            _id: branch_id,
          })
          can(permission.action, permission.subject, branchPathCondition)
          can(permission.action, permission.subject, branchUserCondition)
        } else if (branch_id && section_id) {
          can(permission.action, permission.subject, sectionUserCondition)
          can(permission.action, permission.subject, sectionPathCondition)
        }
      } else if (
        permission.action === PermissionAction.READ &&
        permission.subject === PermissionObjectType.OWN_REPORT
      ) {
        // Read personal report
        can(permission.action, permission.subject, {
          user: {
            _id: user_id,
          },
        })
      } else if (
        permission.action === PermissionAction.CREATE &&
        permission.subject === PermissionObjectType.ASSIGNMENT
      ) {
        if (branch_id && !section_id) {
          // Branch level
          can(permission.action, permission.subject)
        } else if (branch_id && section_id) {
          can(permission.action, permission.subject, sectionPathCondition)
          can(
            permission.action,
            permission.subject,
            deeperLevelSectionUserCondition
          )
          can(
            permission.action,
            permission.subject,
            sameLevelSectionUserCondition
          )
        }
      } else if (
        permission.action === PermissionAction.READ &&
        permission.subject === PermissionObjectType.BRANCH_USER
      ) {
        if (branch_id && !section_id) {
          // Read branch users
          can(permission.action, permission.subject, branchUserCondition)
          can(permission.action, permission.subject, branchCondition)
          can(permission.action, permission.subject, branchPathCondition)
        } else if (branch_id && section_id) {
          can(permission.action, permission.subject, sectionPathCondition)
        }
      } else if (
        (permission.action === PermissionAction.UPDATE &&
          permission.subject === PermissionObjectType.BRANCH_USER) ||
        (permission.action === PermissionAction.DELETE &&
          permission.subject === PermissionObjectType.BRANCH_USER)
      ) {
        // Update/delete branch user
        if (branch_id && !section_id) {
          can(permission.action, permission.subject, branchUserCondition)
          can(permission.action, permission.subject, branchCondition)
        } else {
          can(
            permission.action,
            permission.subject,
            deeperLevelSectionUserCondition
          )
          can(
            permission.action,
            permission.subject,
            sameLevelSectionUserCondition
          )
        }
      } else if (
        permission.action === PermissionAction.READ &&
        permission.subject === PermissionObjectType.OWN_USER
      ) {
        // Read own user details
        can(permission.action, permission.subject, userCondition)
      } else if (
        permission.action === PermissionAction.UPDATE &&
        permission.subject === PermissionObjectType.BRANCH_USER_GROUP
      ) {
        // Update branch user groups
        can(permission.action, permission.subject, groupBranchCondition)
      } else if (
        permission.action === PermissionAction.DELETE &&
        permission.subject === PermissionObjectType.BRANCH_USER_GROUP
      ) {
        // Delete branch user groups
        can(permission.action, permission.subject, groupBranchCondition)
      } else if (
        permission.action === PermissionAction.CREATE &&
        permission.subject === PermissionObjectType.BRANCH_RULE
      ) {
        // Create branch rules
        can(permission.action, permission.subject, groupBranchCondition)
      } else if (
        permission.action === PermissionAction.UPDATE &&
        permission.subject === PermissionObjectType.BRANCH_RULE
      ) {
        // Update branch rules
        can(permission.action, permission.subject, groupBranchCondition)
      } else if (
        permission.action === PermissionAction.READ &&
        permission.subject === PermissionObjectType.BRANCH_RULE
      ) {
        // Read branch rules
        can(permission.action, permission.subject, groupBranchCondition)
      } else if (
        permission.action === PermissionAction.DELETE &&
        permission.subject === PermissionObjectType.BRANCH_RULE
      ) {
        // Delete branch rules
        can(permission.action, permission.subject, groupBranchCondition)
      } else {
        can(permission.action, permission.subject)

        /** If can read all reports, also can read branch/own reports */
        if (
          permission.action === PermissionAction.READ &&
          permission.subject === PermissionObjectType.REPORT
        ) {
          can(PermissionAction.READ, PermissionObjectType.BRANCH_REPORT)
          can(PermissionAction.READ, PermissionObjectType.OWN_REPORT)
        }

        /** If can read all users, also can read branch/own users */
        if (
          permission.action === PermissionAction.READ &&
          permission.subject === PermissionObjectType.USER
        ) {
          can(PermissionAction.READ, PermissionObjectType.BRANCH_USER)
          can(PermissionAction.READ, PermissionObjectType.OWN_USER)
        }

        /** If can read all hierarchy, also can read branch hierarchy */
        if (
          permission.action === PermissionAction.READ &&
          permission.subject === PermissionObjectType.HIERARCHY
        ) {
          can(PermissionAction.READ, PermissionObjectType.BRANCH_HIERARCHY)
        }

        /** If can read all roles, also can read branch roles */
        if (
          permission.action === PermissionAction.READ &&
          permission.subject === PermissionObjectType.ROLE
        ) {
          can(PermissionAction.READ, PermissionObjectType.BRANCH_ROLE)
        }

        /** If can read all rules, also can read branch rules */
        if (
          permission.action === PermissionAction.READ &&
          permission.subject === PermissionObjectType.RULE
        ) {
          can(PermissionAction.READ, PermissionObjectType.BRANCH_RULE)
        }

        /** If can read all certification types, also can read branch certification types */
        if (
          permission.action === PermissionAction.READ &&
          permission.subject === PermissionObjectType.CERTIFICATION_TYPE
        ) {
          can(
            PermissionAction.READ,
            PermissionObjectType.BRANCH_CERTIFICATION_TYPE
          )
        }
      }
    })
  })

  return build()
}
