import { useMutation, useQuery } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import {
  Button,
  Col,
  notification,
  PageHeader,
  Row,
  Tag,
  Form,
  Input,
  Tree,
  Card,
  InputNumber,
  Select,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Store } from 'antd/lib/form/interface'
import { Key, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { AuthMethod, PermissionData } from '@lms-shared-patterns/models'
import {
  BranchRoleQuery,
  UpdateBranchRoleMutation,
  UpdateUserRoleInput,
  UserRolePermission,
  UserRolePermissionInput,
} from 'apps/lms-front/src/generated/graphql'
import { errorNotifierFn } from 'apps/lms-front/src/modules/shared/helpers/error-notifier'
import { useCopyToClipboard } from 'apps/lms-front/src/modules/shared/hooks/use-copy-to-clipboard'
import { PageProps } from 'apps/lms-front/src/modules/shared/interfaces/page.interface'
import { Content } from 'apps/lms-front/src/modules/shared/layout/Layout.style'

import UPDATE_BRANCH_ROLE_MUTATION from '../../../mutations/update-branch-role.graphql'
import BRANCH_ROLE_QUERY from '../../../queries/branch-role.graphql'

export const BranchRoleDetail = ({ route }: PageProps) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [, copy] = useCopyToClipboard()

  const [form] = useForm()
  const [formDirty, setFormDirty] = useState<boolean>(false)

  const [expandedKeys, setExpandedKeys] = useState<Key[]>([])
  const [checkedKeys, setCheckedKeys] = useState<Key[]>([])
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true)

  const permissionData = PermissionData.filter((i) => !i.admin_only).map(
    (category) => ({
      title: category.label,
      key: category.category,
      children: category.children
        .filter((child) => !child.admin_only)
        .map((permission) => ({
          title: permission.label,
          key: permission.action + '|' + permission.subject,
        })),
    })
  )

  const transformSelectedPermissions = (
    checkedKeys: Key[]
  ): UserRolePermissionInput[] => {
    return checkedKeys
      .map((key) => {
        const [action, subject] = key.toString().split('|')
        return { action, subject }
      })
      .filter((item) => item.action && item.subject)
  }

  const parseSelectedPermissions = (
    permissions: UserRolePermission[]
  ): Key[] => {
    return permissions.map(({ subject, action }) => {
      return `${action}|${subject}`
    })
  }

  const onCheck = (
    checkedKeysValue: Key[] | { checked: Key[]; halfChecked: Key[] }
  ) => {
    if (userRole?.fetchUserRole.readonly) return
    if (Array.isArray(checkedKeysValue)) {
      setFormDirty(true)
      setCheckedKeys(checkedKeysValue)
    }
  }

  const onExpand = (expandedKeysValue: React.Key[]) => {
    setExpandedKeys(expandedKeysValue)
    setAutoExpandParent(false)
  }

  const { data: userRole } = useQuery<BranchRoleQuery>(BRANCH_ROLE_QUERY, {
    variables: { id },
    fetchPolicy: 'network-only',
  })
  const [updateUserRole, { loading: updating }] =
    useMutation<UpdateBranchRoleMutation>(UPDATE_BRANCH_ROLE_MUTATION)

  useEffect(() => {
    setCheckedKeys(
      parseSelectedPermissions(userRole?.fetchUserRole.permissions || [])
    )
  }, [userRole])

  /** Copy/paste permissions to clipboard  */
  useEffect(() => {
    const handleCopy = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'c') {
        event.preventDefault()
        copy(JSON.stringify(checkedKeys)).then(() => {
          notification.info({
            message: t({
              id: 'settings.roles.notification.copied_to_clipboard',
              message: 'Permissies gekopieerd naar klembord.',
            }),
          })
        })
      } else if ((event.metaKey || event.ctrlKey) && event.key === 'v') {
        event.preventDefault()
        navigator.clipboard
          .readText()
          .then((text) => setCheckedKeys(JSON.parse(text)))
          .then(() => {
            setFormDirty(true)
            notification.info({
              message: t({
                id: 'settings.roles.notification.pasted_from_clipboard',
                message: 'Permissies van klembord toegepast.',
              }),
            })
          })
      }
    }
    document.addEventListener('keydown', handleCopy)
    return () => {
      document.removeEventListener('keydown', handleCopy)
    }
  }, [checkedKeys, copy])

  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={route.label}
        subTitle={route.description}
        tags={
          userRole?.fetchUserRole.readonly ? (
            <Tag color="red">
              <Trans id="tag.read_only">Read-only</Trans>
            </Tag>
          ) : (
            <></>
          )
        }
        extra={[
          <Button onClick={() => navigate(-1)} key="2">
            <Trans id="actions.go_back">Ga terug</Trans>
          </Button>,
          <Button
            disabled={
              !formDirty ||
              updating ||
              userRole?.fetchUserRole.readonly ||
              false
            }
            onClick={() => form.submit()}
            key="1"
            type="primary"
          >
            <Trans id="actions.save">Opslaan</Trans>
          </Button>,
        ]}
      />
      <Content>
        <Row justify="center" style={{ flex: 1 }}>
          <Col xs={24} lg={12}>
            {userRole && (
              <Form
                form={form}
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={userRole.fetchUserRole as Store}
                onFinish={(variables: UpdateUserRoleInput) => {
                  updateUserRole({
                    variables: {
                      id,
                      ...variables,
                      permissions: transformSelectedPermissions(checkedKeys),
                    },
                    refetchQueries: ['fetchBranchRole'],
                  })
                    .then(() => {
                      notification.success({
                        message: t({
                          id: 'settings.roles.action.update.success',
                          message: 'Gebruikersrol succesvol opgeslagen',
                        }),
                      })
                      setFormDirty(false)
                    })
                    .catch(errorNotifierFn)
                }}
                onFieldsChange={() => setFormDirty(true)}
                autoComplete="off"
              >
                <Form.Item
                  label={t({
                    id: 'settings.roles.form.label.name',
                    message: 'Naam',
                  })}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t({
                        id: 'settings.roles.form.validation.name',
                        message: 'Gelieve een naam voor deze rol in te vullen',
                      }),
                    },
                    {
                      pattern: /^[\s\w-]*$/,
                      message: t({
                        id: 'settings.roles.form.validation.name.pattern',
                        message: 'Gelieve geen speciale tekens te gebruiken',
                      }),
                    },
                  ]}
                >
                  <Input
                    disabled={userRole?.fetchUserRole.readonly || false}
                    pattern="^[\s\w-]*$"
                  />
                </Form.Item>
                <Form.Item
                  label={t({
                    id: 'settings.roles.form.label.level',
                    message: 'Niveau',
                  })}
                  name="level"
                  rules={[
                    {
                      type: 'number',
                      min: 100,
                      message: t({
                        id: 'settings.roles.form.validation.level.min',
                        message: 'Het niveau moet minstens 100 zijn',
                      }),
                    },
                  ]}
                  initialValue={100}
                  required
                >
                  <InputNumber />
                </Form.Item>
                <Form.Item
                  label={t({
                    id: 'settings.roles.form.label.permissions',
                    message: 'Rechten',
                  })}
                >
                  <Card>
                    <Tree
                      checkable
                      selectable={false}
                      onExpand={onExpand}
                      expandedKeys={expandedKeys}
                      autoExpandParent={autoExpandParent}
                      onCheck={onCheck}
                      checkedKeys={checkedKeys}
                      treeData={permissionData}
                    />
                  </Card>
                </Form.Item>
                <Form.Item
                  hidden
                  label={t({
                    id: 'settings.roles.form.label.login_method',
                    message: 'Inlogmethode',
                  })}
                  name="enforcedLoginMethod"
                >
                  <Select
                    options={[
                      {
                        label: t({
                          id: 'settings.roles.form.label.login_method.none',
                          message: 'Alle methodes',
                        }),
                        value: null,
                      },
                      {
                        label: t({
                          id: 'settings.roles.form.label.login_method.email_pass',
                          message: 'E-mail en wachtwoord',
                        }),
                        value: AuthMethod.EMAIL_PASS,
                      },
                      {
                        label: t({
                          id: 'settings.roles.form.label.login_method.microsoft',
                          message: 'Microsoft',
                        }),
                        value: AuthMethod.MICROSOFT,
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item wrapperCol={{ sm: { offset: 8, span: 16 } }}>
                  <Button
                    disabled={
                      !formDirty ||
                      updating ||
                      userRole?.fetchUserRole.readonly ||
                      false
                    }
                    type="primary"
                    htmlType={'submit'}
                  >
                    <Trans id="actions.save">Opslaan</Trans>
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Col>
        </Row>
      </Content>
    </>
  )
}
