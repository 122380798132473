import {
  ArrowLeftOutlined,
  FileDoneOutlined,
  TeamOutlined,
  LaptopOutlined,
  ClockCircleOutlined,
  MailOutlined,
  PhoneOutlined,
  CheckOutlined,
  CloseOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { Plural, Trans, t } from '@lingui/macro'
import { Map as GoogleMap } from '@vis.gl/react-google-maps'
import {
  Button,
  Card,
  Col,
  Empty,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Tooltip,
  notification,
} from 'antd'
import { ObjectId } from 'bson'
import dayjs from 'dayjs'
import { isPossiblePhoneNumber } from 'libphonenumber-js'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { useCallback, useState } from 'react'
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser'
import { Link, useNavigate, useParams } from 'react-router-dom'

import {
  JobAccessibilityOptions,
  JobBenefits,
  JobOtherCriteria,
  JobSchedule,
  JobType,
  StudentJobSchedule,
} from '@lms-shared-patterns/models/job-type.model'
import {
  ContractType,
  EducationLevel,
  JobOpportunityQuery,
} from 'apps/lms-front/src/generated/graphql'

import { Image } from '../../../articles/pages/article/Article.style'
import { useAuth } from '../../../auth/hooks/use-auth'
import { useBranch } from '../../../auth/hooks/use-branch'
import { LoadScreen } from '../../../core/components/LoadScreen'
import { getParentRoute } from '../../../core/routes/router'
import { Player } from '../../../shared/components/player/player'
import { emptyPixel } from '../../../shared/constants/empty-pixel'
import { errorNotifierFn } from '../../../shared/helpers/error-notifier'
import { PageProps } from '../../../shared/interfaces/page.interface'
import { Container } from '../../../shared/layout/Layout.style'
import { renderJobType } from '../../utils/render-job-type'

import APPLY_JOB_MUTATION from './../../mutations/apply-for-job.graphql'
import JOB_OPPORTUNITY_QUERY from './../../queries/job-opportunity.graphql'
import {
  CompanyName,
  EmailTable,
  Meta,
  Paragraph,
  Table,
  Title,
} from './JobViewer.style'

export const JobVideo = ({
  job,
  anonymized,
}: {
  job: JobOpportunityQuery['fetchBranchJobOpportunity']
  anonymized?: boolean
}) => {
  const playFromBackups = useFeatureFlagEnabled('PlayFromCloudBackups')

  return (
    <div style={{ filter: anonymized ? 'blur(10px)' : undefined }}>
      <Player
        src={
          job.cf_stream?.signedBucketURL && playFromBackups
            ? {
                url: job.cf_stream?.signedBucketURL,
              }
            : job.cf_stream?.uid
            ? {
                hls: job.cf_stream?.playback.hls,
              }
            : {
                url: job.video?.external_url || '',
              }
        }
        enablePlaybackRates={true}
        disableAutoPlay={true}
      />
    </div>
  )
}

export const JobViewer = ({ route }: PageProps) => {
  const navigate = useNavigate()
  const { user } = useAuth()
  const branch = useBranch()
  const { job_id } = useParams<{ job_id: string }>()

  const [anonymized] = useState<boolean>(
    localStorage.getItem('aa_jobs_anon') === 'true' &&
      !route.path.includes('branch')
  )

  const [applicationForm] = Form.useForm()
  const [applicationFormVisible, setApplicationFormVisible] = useState(false)

  const [apply, { loading: applying }] = useMutation(APPLY_JOB_MUTATION)

  const { data, loading } = useQuery<JobOpportunityQuery>(
    JOB_OPPORTUNITY_QUERY,
    {
      variables: {
        id: job_id,
      },
    }
  )
  const parent = getParentRoute(route, { job_id })

  const transform = useCallback((node, index) => {
    if (node.type === 'tag' && node.name === 'p') {
      return (
        <Paragraph key={`content-${node.name}-${index}`}>
          {node.children.map((child: unknown, i: number) =>
            convertNodeToElement(child, i, () => undefined)
          )}
        </Paragraph>
      )
    }
    return undefined
  }, [])

  if (loading) {
    return <LoadScreen />
  }

  const job = data?.fetchBranchJobOpportunity

  if (!job)
    return (
      <Container style={{ padding: '36px 0' }}>
        <Empty
          description={
            <p>
              <Trans id="jobs.detail.not_found">
                Vacature niet gevonden. Ben je zeker dat je over de nodige
                rechten beschikt om deze te bekijken?
              </Trans>
            </p>
          }
        >
          <Button
            type="primary"
            onClick={() =>
              navigate(
                `${parent}${sessionStorage.getItem('aa_job_filters') || ''}`
              )
            }
          >
            <Trans id="jobs.detail.back_to_overview">
              Terug naar overzicht
            </Trans>
          </Button>
        </Empty>
      </Container>
    )

  const ctaCard = (
    <Space direction="vertical">
      <h2>
        <Trans id="job.cta.heading">Heb je interesse?</Trans>
      </h2>
      <p style={{ filter: anonymized ? 'blur(5px)' : undefined }}>
        <Trans id="job.cta.body">
          Neem contact op met {job.contactPerson} van{' '}
          {job.branch?.hiringOrganisation?.name || job.branch?.name} of
          solliciteer via onderstaande knop.
        </Trans>
      </p>
      <Space
        direction="vertical"
        style={{ filter: anonymized ? 'blur(5px)' : undefined }}
      >
        <a href={`mailto:${job.email}`} target="_blank" rel="noreferrer">
          <Space>
            <MailOutlined />
            {job.email}
          </Space>
        </a>
        <a href={`tel:${job.phoneNumber}`} target="_blank" rel="noreferrer">
          <Space>
            <PhoneOutlined />
            {job.phoneNumber}
          </Space>
        </a>
      </Space>
      <Button
        style={{ marginTop: 16 }}
        type="primary"
        size="large"
        onClick={() => setApplicationFormVisible(true)}
      >
        <Trans id="job.cta.apply">Solliciteren</Trans>
      </Button>
    </Space>
  )

  return (
    <Container>
      <Row>
        <Col>
          <Space
            style={{
              justifyContent: 'space-between',
              width: '100%',
              position: 'relative',
            }}
          >
            <Link
              to={`${parent}${sessionStorage.getItem('aa_job_filters') || ''}`}
            >
              <Space style={{ marginBottom: 8 }}>
                <ArrowLeftOutlined style={{ fontSize: 12 }} />
                <Trans id="jobs.detail.back_to_overview">
                  Terug naar overzicht
                </Trans>
              </Space>
            </Link>
          </Space>
        </Col>
      </Row>
      <Row gutter={[15, 15]}>
        <Col
          sm={{ order: 0 }}
          lg={{ span: 8, order: 2 }}
          style={{ width: '100%' }}
        >
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <Card
              cover={
                job.video?.external_url || job.cf_stream ? (
                  <JobVideo job={job} anonymized={anonymized} />
                ) : job.location ? (
                  <GoogleMap
                    style={{
                      width: '100%',
                      height: 'auto',
                      aspectRatio: '16/9',
                    }}
                    center={{ lat: job.location[1], lng: job.location[0] }}
                    zoom={11}
                    controlled
                    disableDoubleClickZoom={true}
                    headingInteractionEnabled={false}
                    keyboardShortcuts={false}
                    rotateControl={false}
                    scaleControl={false}
                    zoomControl={false}
                    disableDefaultUI={true}
                    gestureHandling={null}
                  />
                ) : undefined
              }
            >
              <Space direction="vertical">
                <Space size={'middle'} style={{ marginBottom: 8 }}>
                  <img
                    src={anonymized ? emptyPixel : job.branch?.logo?.url}
                    alt={job.branch?.logo?.alt || ''}
                    loading="lazy"
                    width={job.branch?.logo?.width}
                    height={job.branch?.logo?.height}
                    style={{
                      backgroundColor: job.branch?.theme.headerColor,
                      width: 64,
                      height: 64,
                      objectFit: 'contain',
                      padding: 8,
                      borderRadius: 4,
                    }}
                  />
                  <div>
                    <CompanyName
                      style={{ filter: anonymized ? 'blur(5px)' : undefined }}
                    >
                      {job.branch?.hiringOrganisation?.name || job.branch?.name}
                    </CompanyName>
                    <Meta>
                      <Trans id="job.created.label">
                        Geplaatst op {dayjs(job.created).format('D MMMM YYYY')}
                      </Trans>
                    </Meta>
                  </div>
                </Space>
              </Space>
            </Card>
            <Card>{ctaCard}</Card>
          </Space>
        </Col>
        <Col sm={{ order: 1 }} lg={{ span: 16 }} style={{ width: '100%' }}>
          <Card>
            <Space direction="vertical">
              <Title>
                <strong>
                  {renderJobType(
                    job.title,
                    'position' in job ? job.position || undefined : undefined
                  )}
                </strong>{' '}
                in {job.city}
              </Title>
              <Space size="middle" wrap>
                {/* <Space>
                  <EnvironmentOutlined />
                  <span>
                    {job.zipCode} {job.city}
                  </span>
                </Space> */}
                <Space
                  style={{
                    filter: anonymized ? 'blur(5px)' : undefined,
                  }}
                >
                  <TeamOutlined />
                  <Plural
                    id={'job.teamsize.label'}
                    value={job.teamSize}
                    one={'# medewerker'}
                    other={'# medewerkers'}
                  />
                </Space>
                {'other' in job &&
                  job.other.includes(JobOtherCriteria.RemoteWork) && (
                    <Space>
                      <LaptopOutlined />
                      <Trans id="job.remote.label">
                        Mogelijkheid tot thuiswerk
                      </Trans>
                    </Space>
                  )}
                {'other' in job &&
                  job.other.includes(JobOtherCriteria.FlexibleHours) && (
                    <Space>
                      <ClockCircleOutlined />
                      <Trans id="job.remote.flexible_hours">
                        Flexibele werkuren
                      </Trans>
                    </Space>
                  )}
                {'other' in job &&
                  job.other.includes(JobOtherCriteria.ItaaInternship) && (
                    <Space>
                      <FileDoneOutlined />
                      <Trans id="job.remote.itaa_internship">
                        Mogelijkheid tot ITAA-stage
                      </Trans>
                    </Space>
                  )}
              </Space>
              {job.image?.url && (
                <Image
                  width={job.image?.width || 720}
                  height={job.image?.width || 360}
                  loading="lazy"
                  src={job.image.url}
                  alt={job.image.alt || ''}
                  style={{
                    marginTop: '0.75rem',
                    filter: anonymized ? 'brightness(0)' : undefined,
                  }}
                />
              )}
              <h2>
                <Trans id="job.general.heading">Algemeen</Trans>
              </h2>
              <Table>
                {job.__typename === JobType.Internship && (
                  <tbody>
                    <tr>
                      <th>
                        <Trans id="job.location">Locatie</Trans>
                      </th>
                      <td
                        style={{
                          filter: anonymized ? 'blur(5px)' : undefined,
                        }}
                      >
                        {job.street && (
                          <>
                            {job.street} {job.house_no ?? ''}
                            <br />
                          </>
                        )}
                        {job.zipCode} {job.city}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <Trans id="job.accessibility">Bereikbaarheid</Trans>
                      </th>
                      <td>
                        {job.accessibility
                          .map((a) => {
                            if (a === JobAccessibilityOptions.Parking)
                              return 'Wagen - parking aanwezig'
                            if (a === JobAccessibilityOptions.PublicTransport)
                              return 'Makkelijk bereikbaar via het openbaar vervoer'
                            return undefined
                          })
                          .filter(Boolean)
                          .map((i, a) => (
                            <div key={a}>
                              <Space>{i}</Space>
                            </div>
                          ))}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <Trans id="job.education_level">
                          Opleidingsrichting
                        </Trans>
                      </th>
                      <td>
                        {job.educationLevel
                          .map((a) => {
                            if (a === EducationLevel.Bachelor) return 'Bachelor'
                            if (a === EducationLevel.Graduate) return 'Graduaat'
                            if (a === EducationLevel.Master) return 'Master'
                            if (a === EducationLevel.Either)
                              return 'Geen voorkeur'

                            return undefined
                          })
                          .filter(Boolean)
                          .map((i, a) => (
                            <div key={a}>{i}</div>
                          ))}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <Trans id="job.remote">Thuiswerk</Trans>
                      </th>
                      <td>
                        {job.other.includes(JobOtherCriteria.RemoteWork) ? (
                          <CheckOutlined />
                        ) : (
                          <CloseOutlined />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <Trans id="job.flexible_hours">
                          Flexibele werkuren
                        </Trans>
                      </th>
                      <td>
                        {job.other.includes(JobOtherCriteria.FlexibleHours) ? (
                          <CheckOutlined />
                        ) : (
                          <CloseOutlined />
                        )}
                      </td>
                    </tr>
                  </tbody>
                )}
                {job.__typename === JobType.StudentJob && (
                  <tbody>
                    <tr>
                      <th>
                        <Trans id="job.location">Locatie</Trans>
                      </th>
                      <td
                        style={{
                          filter: anonymized ? 'blur(5px)' : undefined,
                        }}
                      >
                        {job.street && (
                          <>
                            {job.street} {job.house_no ?? ''}
                            <br />
                          </>
                        )}
                        {job.zipCode} {job.city}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <Trans id="job.accessibility">Bereikbaarheid</Trans>
                      </th>
                      <td>
                        {job.accessibility
                          .map((a) => {
                            if (a === JobAccessibilityOptions.Parking)
                              return 'Parking beschikbaar'
                            if (a === JobAccessibilityOptions.PublicTransport)
                              return 'Eenvoudig te bereiken via openbaar vervoer'
                            return undefined
                          })
                          .filter(Boolean)
                          .map((i, a) => (
                            <div key={a}>
                              <Space>{i}</Space>
                            </div>
                          ))}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <Trans id="job.schedule">Werkschema</Trans>
                      </th>
                      <td>
                        {job.studentJobSchedule
                          .map((a) => {
                            if (a === StudentJobSchedule.FixedDays)
                              return 'Vaste dag(en) per week'
                            if (a === StudentJobSchedule.Vacations)
                              return 'Schoolvakanties'
                            return undefined
                          })
                          .filter(Boolean)
                          .map((i, a) => (
                            <div key={a}>{i}</div>
                          ))}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <Trans id="job.education_level">
                          Opleidingsrichting
                        </Trans>
                      </th>
                      <td>
                        {job.educationLevel
                          .map((a) => {
                            if (a === EducationLevel.Bachelor) return 'Bachelor'
                            if (a === EducationLevel.Graduate) return 'Graduaat'
                            if (a === EducationLevel.Master) return 'Master'
                            if (a === EducationLevel.None)
                              return 'Geen diploma vereist'
                            if (a === EducationLevel.Either)
                              return 'Geen voorkeur'

                            return undefined
                          })
                          .filter(Boolean)
                          .map((i, a) => (
                            <div key={a}>{i}</div>
                          ))}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <Trans id="job.remote">Thuiswerk</Trans>
                      </th>
                      <td>
                        {job.other.includes(JobOtherCriteria.RemoteWork) ? (
                          <CheckOutlined />
                        ) : (
                          <CloseOutlined />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <Trans id="job.flexible_hours">
                          Flexibele werkuren
                        </Trans>
                      </th>
                      <td>
                        {job.other.includes(JobOtherCriteria.FlexibleHours) ? (
                          <CheckOutlined />
                        ) : (
                          <CloseOutlined />
                        )}
                      </td>
                    </tr>
                  </tbody>
                )}
                {job.__typename === JobType.FirstJob && (
                  <tbody>
                    <tr>
                      <th>
                        <Trans id="job.location">Locatie</Trans>
                      </th>
                      <td
                        style={{
                          filter: anonymized ? 'blur(5px)' : undefined,
                        }}
                      >
                        {job.street && (
                          <>
                            {job.street} {job.house_no ?? ''}
                            <br />
                          </>
                        )}
                        {job.zipCode} {job.city}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <Trans id="job.accessibility">Bereikbaarheid</Trans>
                      </th>
                      <td>
                        {job.accessibility
                          .map((a) => {
                            if (a === JobAccessibilityOptions.Parking)
                              return 'Parking beschikbaar'
                            if (a === JobAccessibilityOptions.PublicTransport)
                              return 'Eenvoudig te bereiken via openbaar vervoer'
                            return undefined
                          })
                          .filter(Boolean)
                          .map((i, a) => (
                            <div key={a}>
                              <Space>{i}</Space>
                            </div>
                          ))}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <Trans id="job.contract">Contract</Trans>
                      </th>
                      <td>
                        {job.contract
                          .map((a) => {
                            if (a === ContractType.Permanent)
                              return 'Vast contract'
                            if (a === ContractType.Temporary)
                              return 'Tijdelijk contract'
                            return undefined
                          })
                          .filter(Boolean)
                          .map((i, a) => (
                            <div key={a}>{i}</div>
                          ))}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <Trans id="job.schedule">Werkschema</Trans>
                      </th>
                      <td>
                        {job.firstJobSchedule
                          .map((a) => {
                            if (a === JobSchedule.FullTime) return 'Voltijds'
                            if (a === JobSchedule.PartTime) return 'Part-time'
                            return undefined
                          })
                          .filter(Boolean)
                          .map((i, a) => (
                            <div key={a}>{i}</div>
                          ))}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <Trans id="job.education_level">
                          Opleidingsrichting
                        </Trans>
                      </th>
                      <td>
                        {job.educationLevel
                          .map((a) => {
                            if (a === EducationLevel.Bachelor) return 'Bachelor'
                            if (a === EducationLevel.Graduate) return 'Graduaat'
                            if (a === EducationLevel.Master) return 'Master'
                            if (a === EducationLevel.Either)
                              return 'Geen voorkeur'

                            return undefined
                          })
                          .filter(Boolean)
                          .map((i, a) => (
                            <div key={a}>{i}</div>
                          ))}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <Space>
                          <Trans id="job.wage">Loonvork</Trans>
                          <Tooltip title={'Bruto bedrag per maand – fulltime'}>
                            <QuestionCircleOutlined />
                          </Tooltip>
                        </Space>
                      </th>
                      <td>
                        € {job.wage[0]} - € {job.wage[1]}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <Trans id="job.benefits">Extralegale voordelen</Trans>
                      </th>
                      <td>
                        {job.benefits
                          .map((a) => {
                            if (a === JobBenefits.CompanyCar)
                              return 'Bedrijfswagen'
                            if (a === JobBenefits.Smartphone)
                              return 'Smartphone'
                            if (a === JobBenefits.Laptop) return 'Laptop'
                            if (a === JobBenefits.MealVouchers)
                              return 'Maaltijdcheques'
                            if (a === JobBenefits.CafetariaPlan)
                              return 'Cafetariaplan'
                            if (a === JobBenefits.HealthInsurance)
                              return 'Hospitalisatie- en groepsverzekering'
                            if (a === JobBenefits.MobilityBudget)
                              return 'Mobiliteitsbudget'
                            if (a === JobBenefits.Bonus) return 'Bonus'
                            if (a === JobBenefits.Other) return 'Andere'
                            return undefined
                          })
                          .filter(Boolean)
                          .map((i, a) => (
                            <div key={a}>{i}</div>
                          ))}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <Trans id="job.remote">Thuiswerk</Trans>
                      </th>
                      <td>
                        {job.other.includes(JobOtherCriteria.RemoteWork) ? (
                          <CheckOutlined />
                        ) : (
                          <CloseOutlined />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <Trans id="job.flexible_hours">
                          Flexibele werkuren
                        </Trans>
                      </th>
                      <td>
                        {job.other.includes(JobOtherCriteria.FlexibleHours) ? (
                          <CheckOutlined />
                        ) : (
                          <CloseOutlined />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <Trans id="job.itaa_internship">
                          Mogelijkheid tot ITAA-stage
                        </Trans>
                      </th>
                      <td>
                        {job.other.includes(JobOtherCriteria.ItaaInternship) ? (
                          <CheckOutlined />
                        ) : (
                          <CloseOutlined />
                        )}
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
              <h2>
                <Trans id="job.tasks.heading">Takenpakket</Trans>
              </h2>
              <div style={{ filter: anonymized ? 'blur(5px)' : undefined }}>
                {ReactHtmlParser(job.tasks || '', {
                  transform,
                })}
              </div>
              <h2>
                <Trans id="job.competences.heading">
                  Vereiste competenties
                </Trans>
              </h2>
              <div style={{ filter: anonymized ? 'blur(5px)' : undefined }}>
                {ReactHtmlParser(job.competencies || '', {
                  transform,
                })}
              </div>
              <h2>
                <Trans id="job.why.heading">Werkomgeving</Trans>
              </h2>
              <div style={{ filter: anonymized ? 'blur(5px)' : undefined }}>
                {ReactHtmlParser(job.why || '', {
                  transform,
                })}
              </div>
              {ctaCard}
            </Space>
          </Card>
        </Col>
      </Row>
      <Modal
        title={<Trans id="job.apply.title">Solliciteren via e-mail</Trans>}
        open={applicationFormVisible}
        onCancel={() => setApplicationFormVisible(false)}
        destroyOnClose={true}
        cancelText={<Trans id="action.cancel">Annuleren</Trans>}
        okText={<Trans id="action.send">Verzenden</Trans>}
        width={720}
        onOk={() => applicationForm.submit()}
        okButtonProps={{ loading: applying }}
      >
        <Form
          form={applicationForm}
          initialValues={{
            email: user!.email,
            phone: user!.phone,
          }}
          onFinish={(variables) => {
            if (new ObjectId(String(branch!._id)).equals(job?.branch?._id)) {
              notification.error({
                message: t({
                  message: 'Je kan niet solliciteren op je eigen vacature',
                  id: 'jobs.apply.form.error.own_job',
                }),
              })
              return
            }
            apply({
              variables: {
                job: job_id,
                email: variables.email,
                phone: variables.phone,
              },
            })
              .then(() => {
                setApplicationFormVisible(false)
                notification.success({
                  message: t({
                    message: 'Je sollicitatie werd succesvol verzonden',
                    id: 'jobs.apply.form.success',
                  }),
                })
              })
              .catch(errorNotifierFn)
          }}
        >
          <EmailTable>
            <tr>
              <td>
                <strong>
                  <Trans id="jobs.apply.from">Aan</Trans>
                </strong>
              </td>
              <td style={{ filter: anonymized ? 'blur(5px)' : undefined }}>
                {job.contactPerson}
              </td>
            </tr>
            <tr>
              <td>
                <strong>
                  <Trans id="jobs.apply.cc">CC</Trans>
                </strong>
              </td>
              <td>{user!.email}</td>
            </tr>
            <tr>
              <td>
                <strong>
                  <Trans id="jobs.apply.subject">Onderwerp</Trans>
                </strong>
              </td>
              <td>
                Er werd gesolliciteerd op jouw vacature via Accountants Academy!
              </td>
            </tr>
            <tr>
              <td colSpan={2} style={{ padding: 16 }}>
                <p>Dag {job.contactPerson}</p>
                <p>
                  Goed nieuws! {user!.firstName} {user!.lastName} heeft
                  interesse in de onderstaande vacature en wil graag met je in
                  contact komen.
                </p>
                <p style={{ marginBottom: 8 }}>
                  <strong>Gegevens van de student:</strong>
                </p>
                <ul>
                  <li>
                    <strong>Naam: </strong>
                    {user!.firstName} {user!.lastName}
                  </li>
                  <li>
                    <strong>GSM-nummer: </strong>
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          validator: (_, value) => {
                            const valid = isPossiblePhoneNumber(value, 'BE')
                            if (!valid)
                              return Promise.reject(
                                t({
                                  message: 'Vul een geldig GSM-nummer in',
                                  id: 'jobs.apply.form.error.phone',
                                })
                              )
                            return Promise.resolve()
                          },
                          message: t({
                            message: 'Vul een geldig GSM-nummer in',
                            id: 'jobs.apply.form.error.phone',
                          }),
                        },
                      ]}
                      style={{
                        marginBottom: 0,
                        display: 'inline-block',
                        verticalAlign: 'middle',
                      }}
                    >
                      <Input
                        size="small"
                        style={{ width: 250 }}
                        placeholder={t({
                          message: 'Vul je GSM-nummer in',
                          id: 'jobs.apply.form.placeholder.phone',
                        })}
                        required
                      />
                    </Form.Item>
                  </li>
                  <li>
                    <strong>E-mailadres: </strong>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          type: 'email',
                          message: t({
                            message: 'Vul een geldig e-mailadres in',
                            id: 'jobs.apply.form.error.email',
                          }),
                        },
                      ]}
                      style={{
                        marginBottom: 0,
                        display: 'inline-block',
                        verticalAlign: 'middle',
                      }}
                    >
                      <Input
                        size="small"
                        style={{ width: 250 }}
                        placeholder={t({
                          message: 'Vul je e-mailadres in',
                          id: 'jobs.apply.form.placeholder.email',
                        })}
                        required
                      />
                    </Form.Item>
                  </li>
                </ul>

                <p>
                  <strong>Details van de vacature: </strong>
                </p>
                <ul>
                  <li>
                    <a
                      href={`/branch/jobs/${job._id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {renderJobType(
                        job.title,
                        'position' in job
                          ? job.position || undefined
                          : undefined
                      )}{' '}
                      ({job.city})
                    </a>
                  </li>
                </ul>
                <p>
                  Bedankt voor het plaatsen van jouw vacature op Accountants
                  Academy en veel succes met het verdere contact.
                </p>
                <p style={{ marginBottom: 0 }}>Het Accountants Academy Team</p>
              </td>
            </tr>
          </EmailTable>
        </Form>
      </Modal>
    </Container>
  )
}
