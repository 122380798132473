/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable sonarjs/no-identical-functions */
import { DownOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { AnyAbility } from '@casl/ability'
import { Trans, t } from '@lingui/macro'
import { Checkbox, Slider, Tree } from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import CollapsePanel from 'antd/lib/collapse/CollapsePanel'
import { ObjectId } from 'bson'
import { usePostHog } from 'posthog-js/react'
import React, { useEffect, useMemo, useState } from 'react'

import {
  CoursesFiltersQuery,
  // DistinctCourseDatePropertyQuery,
  BranchCoursesFiltersQuery,
  MeQuery,
  CoursesQuery,
} from 'apps/lms-front/src/generated/graphql'
import { useBranch } from 'apps/lms-front/src/modules/auth/hooks/use-branch'

import { useAuth } from '../../../auth/hooks/use-auth'
import { LoadSection } from '../../../core/components/LoadScreen'
import { InputSearch } from '../../../shared/components/input-search/InputSearch'
import { reduceDuplicateLabels } from '../../../shared/helpers/reduce-duplicate-labels'
import { SOFTWARE_TYPE_ID, useSoftware } from '../../hooks/use-software'

import BRANCH_COURSES_FILTERS_QUERY from './../../queries/branch-courses-filters.graphql'
import COURSES_FILTERS_QUERY from './../../queries/courses-filters.graphql'
// import DISTINCT_LAST_REVISION from './../../queries/distinct-course-date-prop.graphql'
import {
  ResetButton,
  Section,
  SectionTitle,
  StyledCollapse,
  Wrapper,
} from './CoursesFilters.style'

interface Props {
  query: string
  type: (string | null)[] | undefined | null
  category: (string | null)[] | undefined | null
  certificationType?: (string | null)[] | undefined | null
  onQueryChanged: (val: string) => void
  onTypeChanged: (val: string[]) => void
  onCategoryChanged: (val: string[]) => void
  onBranchCategoryChanged: (val: string[]) => void
  onCertificationTypeChanged?: (val: string[]) => void
  onLevelChanged: (val: string[]) => void
  onStatusChanged: (val: boolean | undefined) => void
  onRevisionChanged: (val: number[]) => void
  onDurationChanged: (val: [number, number]) => void
  permissions: {
    canReadCourseType: boolean
    canReadCourseCategory: boolean
  }
  user?: MeQuery['me']
  meta?: CoursesQuery['fetchCourses']['meta']
  ability?: AnyAbility
}

export const Filter = ({
  heading,
  children,
  expand,
  loading,
}: {
  heading: React.ReactNode
  children?: React.ReactNode
  expand?: boolean
  loading?: boolean
}) => (
  <Section style={{ paddingTop: 0, paddingBottom: 0 }}>
    <StyledCollapse
      expandIcon={({ isActive }) => (
        <DownOutlined rotate={isActive ? 180 : 0} />
      )}
      defaultActiveKey={expand ? ['0'] : []}
      ghost
    >
      <CollapsePanel key={0} header={heading}>
        {loading ? <LoadSection /> : children}
      </CollapsePanel>
    </StyledCollapse>
  </Section>
)

export const CoursesFilters = ({
  onQueryChanged,
  onTypeChanged,
  onCategoryChanged,
  onBranchCategoryChanged,
  onCertificationTypeChanged,
  onLevelChanged,
  onStatusChanged,
  onDurationChanged,
  // onRevisionChanged,
  query,
  type = [],
  category = [],
  certificationType = [],
  meta,
  permissions,
}: Props) => {
  const { user } = useAuth()
  const branch = useBranch()
  const posthog = usePostHog()

  const userCertificationTypes = user?.certificationType || []
  const [durationFilterVisible, setDurationFilterVisible] = useState(false)
  const [searchTerm, setSearchTerm] = useState<string>(query)
  const [checkedCategories, setCheckedCategories] = useState<
    CheckboxValueType[]
  >(category?.filter(Boolean) as string[])
  const [checkedCertificationTypes, setCheckedCertificationTypes] = useState<
    CheckboxValueType[]
  >(certificationType?.filter(Boolean) as string[])

  const [checkedBranchCategories, setCheckedBranchCategories] = useState<
    CheckboxValueType[]
  >(category?.filter(Boolean) as string[])

  const [checkedLevels, setCheckedLevels] = useState<CheckboxValueType[]>([])
  const [checkedTypes, setCheckedTypes] = useState<CheckboxValueType[]>(
    type?.filter(Boolean) as string[]
  )
  const [checkedStatuses, setCheckedStatuses] = useState<CheckboxValueType[]>(
    []
  )
  // const [checkedRevisionDate, setCheckedRevisionDate] = useState<
  //   CheckboxValueType[]
  // >([])

  useEffect(() => {
    posthog.onFeatureFlags(function () {
      if (posthog.isFeatureEnabled('DurationFilter')) {
        setDurationFilterVisible(true)
      }
    })
  }, [])

  const { active: softwareNMoreActive, types: softwareTypes } =
    useSoftware(type)

  const onDurationFilterChange = (durationRange: [number, number]) => {
    onDurationChanged(durationRange)
  }

  const onTypeFilterChange = (list: CheckboxValueType[]) => {
    setCheckedTypes(list)
    onTypeChanged(list.map((item) => item.toString()))
  }
  const onCategoryFilterChange = (list: CheckboxValueType[]) => {
    setCheckedCategories(list)
    onCategoryChanged(list.map((item) => item.toString()))
  }
  const onBranchCategoryFilterChange = (list: CheckboxValueType[]) => {
    setCheckedBranchCategories(list)
    onBranchCategoryChanged(list.map((item) => item.toString()))
  }
  const onLevelFilterChange = (list: CheckboxValueType[]) => {
    setCheckedLevels(list)
    onLevelChanged(list.map((item) => item.toString()))
  }
  const onStatusFilterChange = (list: CheckboxValueType[]): void => {
    setCheckedStatuses(list)
    onStatusChanged(
      list.length > 1 || list.length === 0 ? undefined : (list[0] as boolean)
    )
  }
  const onCertificationTypeFilterChange = (list: CheckboxValueType[]): void => {
    /**
     * We actually want to filter on the certification type name, not the id, because some branches might have different ids for the same certification type.
     */
    const selectedLabels = new Set(
      userCertificationTypes
        .filter((l) => list.includes(String(l?._id)))
        .map((type) => type.name)
    )

    const selectedTypes = userCertificationTypes.filter((t) =>
      selectedLabels.has(t.name)
    )
    const selectedIds = selectedTypes.map((t) => t._id)
    setCheckedCertificationTypes(selectedIds)
    onCertificationTypeChanged?.(selectedIds)
  }
  // const onRevisionDateChange = (list: CheckboxValueType[]): void => {
  //   setCheckedRevisionDate(list)
  //   onRevisionChanged(list as number[])
  // }

  const { data: coursesFilters } = useQuery<CoursesFiltersQuery>(
    COURSES_FILTERS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      skip: !(
        permissions.canReadCourseType && permissions.canReadCourseCategory
      ),
    }
  )

  const { data: branchCoursesFilters } = useQuery<BranchCoursesFiltersQuery>(
    BRANCH_COURSES_FILTERS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      skip: !permissions.canReadCourseCategory,
    }
  )

  // const { data: courseRevisionDate } =
  //   useQuery<DistinctCourseDatePropertyQuery>(DISTINCT_LAST_REVISION, {
  //     variables: {
  //       prop: 'meta.revision_date',
  //       datePeriod: 'YEAR',
  //     },
  //     fetchPolicy: 'cache-and-network',
  //     skip: true,
  //   })

  const categoryOptions = useMemo(() => {
    if (meta?.categoryFilters) {
      return (
        coursesFilters?.fetchCategories
          .map((cat) => {
            const count =
              meta?.categoryFilters
                .filter(Boolean)
                .find((c) => c?.value === cat._id)?.count || 0
            return {
              label: `${cat.name}`,
              value: cat._id,
              count,
            }
          })
          .filter((cat) => cat.count > 0) || []
      )
    }
    return (
      coursesFilters?.fetchCategories.map((cat) => ({
        label: cat.name,
        value: cat._id,
      })) || []
    )
  }, [coursesFilters, meta])

  const branchCategoryOptions = useMemo(() => {
    if (meta?.categoryFilters) {
      return (
        branchCoursesFilters?.fetchBranchCategories
          .map((cat) => {
            const count =
              meta?.categoryFilters
                .filter(Boolean)
                .find((c) => c?.value === cat._id)?.count || 0
            return {
              label: `${cat.name}`,
              value: cat._id,
              count,
            }
          })
          .filter((cat) => cat.count > 0) || []
      )
    }
    return (
      branchCoursesFilters?.fetchBranchCategories.map((cat) => ({
        label: cat.name,
        value: cat._id,
      })) || []
    )
  }, [branchCoursesFilters, meta])

  const typeOptions = useMemo(() => {
    const _types = meta?.typeFilters
      ? coursesFilters?.fetchCourseTypes
          .filter((type) => !type.parent_id)
          .map((type) => {
            const count =
              meta?.typeFilters
                .filter(Boolean)
                .find((t) => t?.value === type._id)?.count || 0
            return {
              label: `${type.name}`,
              value: type._id,
              count,
            }
          })
          .filter((type) => type.count > 0) || []
      : coursesFilters?.fetchCourseTypes
          .filter((type) => !type.parent_id)
          .map((type) => ({
            label: type.name,
            value: type._id,
          })) || []
    return _types || []
  }, [coursesFilters, meta])

  const typeTreeFilterOptions = useMemo(() => {
    return typeOptions
      .filter((type) => {
        const hideSoftware =
          branch?.disabledAllSoftware ||
          !user?.channels?.some((c) =>
            new ObjectId('6694dc12e2c2cb44f79ac63a').equals(c._id)
          ) // Software & More
        return !(
          ObjectId.isValid(type.value) &&
          new ObjectId(type.value as string).equals(SOFTWARE_TYPE_ID) &&
          hideSoftware
        )
      })
      .map((type) => ({
        key: type.value as string,
        title: type.label,
        checkable: true,
        children: new ObjectId(type.value as string).equals(SOFTWARE_TYPE_ID)
          ? softwareTypes.map((type) => ({
              key: type,
              title: type,
              count: 1,
            }))
          : coursesFilters?.fetchCourseTypes
              .filter(
                (t) =>
                  t.parent_id &&
                  new ObjectId(String(t.parent_id)).equals(type.value)
              )
              .map((t) => ({
                key: `${type.value}.${t._id}`,
                title: t.name,
                count: 1,
              })),
      }))
  }, [typeOptions, coursesFilters?.fetchCourseTypes, softwareTypes, branch])

  return (
    <Wrapper>
      <Section>
        <SectionTitle>
          <Trans id="courses.search.title">Zoeken in opleidingen</Trans>
        </SectionTitle>
        <InputSearch
          placeholder={t({
            id: 'courses.search.placeholder',
            message: 'Zoekterm',
          })}
          onSearch={(query) => {
            onQueryChanged(query)
          }}
          onChange={(e) => {
            setSearchTerm(e.target.value)
            if (e.target.value === '') {
              onQueryChanged(e.target.value)
            }
          }}
          value={searchTerm}
          style={{ width: '100%' }}
        />
      </Section>
      {durationFilterVisible && !softwareNMoreActive && (
        <Filter
          heading={t({
            id: 'courses.filter.duration',
            message: 'Tijdsduur',
          })}
          loading={!meta}
        >
          <Slider
            range={{ draggableTrack: true }}
            tooltip={{
              formatter: (value?: number | undefined) => {
                if (value === 0) return `< 1 min`
                if (value === 180) return `> 3 uur`
                if (!value) return null
                if (value < 60) {
                  return `${value} min`
                } else if (value % 60 === 0) {
                  return `${value / 60} uur`
                }
                return `${Math.floor(value / 60)} uur ${value % 60} min`
              },
            }}
            marks={{
              0: { label: '0 min', style: { transform: 'translateX(-20%)' } },
              60: '1 uur',
              120: '2 uur',
              180: {
                style: {
                  textAlign: 'right',
                  whiteSpace: 'nowrap',
                  transform: 'translateX(-85%)',
                },
                label: '3+ uur',
              },
            }}
            defaultValue={[0, 180]}
            min={0}
            max={180}
            step={5}
            onAfterChange={onDurationFilterChange}
          />
        </Filter>
      )}
      {typeOptions.length > 0 && (
        <Filter
          heading={t({
            id: 'courses.filter.type',
            message: 'Type',
          })}
          expand
          loading={!meta}
        >
          <Tree
            style={{ marginLeft: '-1.5rem' }}
            checkable
            switcherIcon={<></>}
            checkStrictly
            selectable={false}
            expandedKeys={checkedTypes as string[]}
            onCheck={(checked) => {
              if (!Array.isArray(checked))
                onTypeFilterChange([...checked.checked, ...checked.halfChecked])
            }}
            checkedKeys={checkedTypes.map((type) => type.toString())}
            treeData={typeTreeFilterOptions}
          />
        </Filter>
      )}
      {userCertificationTypes.length > 0 && !softwareNMoreActive && (
        <Filter
          heading={t({
            id: 'courses.filter.certification_type',
            message: 'Attest',
          })}
          expand={
            userCertificationTypes.length > 1 ||
            (!!certificationType && certificationType.length > 0)
          }
          loading={!meta}
        >
          <Checkbox.Group
            className="vertical"
            options={userCertificationTypes
              .reduce<{ name: string; _id: string }[]>(
                reduceDuplicateLabels,
                []
              )
              .map((type) => ({
                label: type.name,
                value: type._id,
              }))}
            value={checkedCertificationTypes}
            onChange={onCertificationTypeFilterChange}
          />
        </Filter>
      )}
      {!!branchCategoryOptions?.length && !softwareNMoreActive && (
        <Filter
          heading={branch?.name || ''}
          loading={!meta}
          expand={!!category && category.length > 0}
        >
          <Checkbox.Group
            className="vertical"
            options={branchCategoryOptions}
            value={checkedBranchCategories}
            onChange={onBranchCategoryFilterChange}
          />
        </Filter>
      )}
      {!!categoryOptions?.length && !softwareNMoreActive && (
        <Filter
          heading={t({
            id: 'courses.filter.category',
            message: 'Categorie',
          })}
          loading={!meta}
          expand={!!category && category.length > 0}
        >
          <Checkbox.Group
            className="vertical"
            options={categoryOptions}
            value={checkedCategories}
            onChange={onCategoryFilterChange}
          />
        </Filter>
      )}
      {/* <Filter heading={'Niveau'}>
        <Checkbox.Group
          className="vertical"
          options={[
            { label: 'Starter', value: 'beginner' },
            { label: 'Gevorderd', value: 'advanced' },
            { label: 'Expert', value: 'expert' },
          ]}
          value={checkedLevels}
          onChange={onLevelFilterChange}
        />
      </Filter>
      <Filter heading={'Laatste revisie'}>
        <Checkbox.Group
          className="vertical"
          options={courseRevisionDate?.distinctCourseDateProperties.map(
            (date: number) => ({ label: date, value: date })
          )}
          value={checkedRevisionDate}
          onChange={onRevisionDateChange}
        />
      </Filter>
      <Filter heading={'Status'}>
        <Checkbox.Group
          className="vertical"
          options={[
            { label: 'Gepubliceerd', value: true },
            { label: 'Concept', value: false },
          ]}
          value={checkedStatuses}
          onChange={onStatusFilterChange}
        />
      </Filter> */}
      <Section>
        <ResetButton
          type="primary"
          disabled={
            (!searchTerm || searchTerm.length === 0) &&
            checkedCategories.length === 0 &&
            checkedLevels.length === 0 &&
            checkedStatuses.length === 0 &&
            checkedTypes.length === 0
          }
          onClick={() => {
            setSearchTerm('')
            onQueryChanged('')
            onCategoryFilterChange([])
            onBranchCategoryFilterChange([])
            onLevelFilterChange([])
            onTypeFilterChange([])
            onStatusFilterChange([])
          }}
        >
          <Trans id="courses.filter.reset">Reset alle filters</Trans>
        </ResetButton>
      </Section>
      {/* <Filter heading={'Duurtijd'}>Hier komt een filter.</Filter> */}
    </Wrapper>
  )
}
