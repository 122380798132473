/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable unicorn/no-useless-undefined */
import { StopOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import {
  Button,
  Form,
  Input,
  message,
  notification,
  PageHeader,
  Popconfirm,
  Space,
  Table,
  Tag,
  Tooltip,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import Modal from 'antd/lib/modal/Modal'
import { ColumnsType } from 'antd/lib/table'
import { CompareFn } from 'antd/lib/table/interface'
import dayjs, { extend } from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import Fuse from 'fuse.js'
import { useEffect, useMemo, useState } from 'react'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  ApiKeysQuery,
  CreateApiKeyMutation,
  RevokeApiKeyMutation,
} from 'apps/lms-front/src/generated/graphql'

import { Can } from '../../../auth/components/Can'
import { ActionButtonWrapper } from '../../../shared/components/action-button-wrapper/ActionButtonWrapper'
import { InputSearch } from '../../../shared/components/input-search/InputSearch'
import { errorNotifierFn } from '../../../shared/helpers/error-notifier'
import { useCopyToClipboard } from '../../../shared/hooks/use-copy-to-clipboard'
import { defaultSort } from '../../../shared/utils/sort'

import CREATE_API_KEY_MUTATION from './../../mutations/create-api-key.graphql'
import REVOKE_API_KEY_MUTATION from './../../mutations/revoke-api-key.graphql'
import API_KEYS_QUERY from './../../queries/api-keys.graphql'
import { ApiKey } from './ApiKeys.style'

extend(utc)
extend(timezone)
extend(relativeTime)

interface ApiKeyRow {
  _id: string
  created?: Date
  label: string
  expires?: Date
}

export const ApiKeys = () => {
  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [keyModalVisible, setKeyModalVisible] = useState(false)
  const [newlyCreatedApiKey, setNewlyCreatedApiKey] = useState<string>()
  const [deletePopConfirmIndex, setDeletePopConfirmIndex] = useState<string>()
  const [deletePopConfirmVisible, setDeletePopConfirmVisible] = useState(false)
  const [form] = useForm()
  const [_, copy] = useCopyToClipboard()

  const { data, loading } = useQuery<ApiKeysQuery>(API_KEYS_QUERY, {
    fetchPolicy: 'network-only',
  })

  const [createKey, { loading: creating }] = useMutation<CreateApiKeyMutation>(
    CREATE_API_KEY_MUTATION
  )

  const [revokeKey, { loading: deleting }] = useMutation<RevokeApiKeyMutation>(
    REVOKE_API_KEY_MUTATION
  )

  useEffect(() => {
    if (keyModalVisible) form.resetFields()
  }, [keyModalVisible, form])

  const apiKeys = useMemo(() => {
    const fuse = new Fuse(data?.fetchApiKeys || [], {
      keys: ['label'],
      findAllMatches: true,
    })

    const result = fuse.search(searchTerm)

    return (
      searchTerm.length > 1
        ? result.map((result) => result.item)
        : data?.fetchApiKeys || []
    ).map<ApiKeyRow>((item: ApiKeysQuery['fetchApiKeys'][0]) => ({
      _id: item._id,
      label: item.label,
      created: item.created,
      expires: item.expires,
    }))
  }, [data, searchTerm])

  const columns: ColumnsType<ApiKeyRow> = [
    {
      title: t({
        id: 'settings.api_keys.table.label',
        message: 'Label',
      }),
      dataIndex: 'label',
      key: 'label',
      sorter: defaultSort('label') as CompareFn<unknown>,
      fixed: 'left',
      render: (value, record) => (
        <>
          <Space>
            {value}
            {record.expires &&
              new Date(record.expires).getTime() < Date.now() && (
                <Tag color={'red'}>
                  <Trans id="tag.deactivated">Gedeactiveerd</Trans>
                </Tag>
              )}
          </Space>
        </>
      ),
    },
    {
      title: t({
        id: 'settings.api_keys.table.created',
        message: 'Aangemaakt',
      }),
      dataIndex: 'created',
      key: 'created',
      sorter: defaultSort('created') as CompareFn<unknown>,
      render: (value) => dayjs(value).tz('Europe/Brussels').fromNow(),
    },
    {
      title: t({
        id: 'settings.api_keys.table.expires',
        message: 'Vervalt op',
      }),
      dataIndex: 'expires',
      key: 'expires',
      sorter: defaultSort('expires') as CompareFn<unknown>,
      render: (value) =>
        value ? dayjs(value).tz('Europe/Brussels').fromNow() : '(nooit)',
    },
    {
      title: t({
        id: 'settings.api_keys.table.actions',
        message: 'Acties',
      }),
      key: 'operation',
      fixed: 'right',
      width: 110,
      render: (_, record: ApiKeyRow) => (
        <ActionButtonWrapper>
          <Can I={PermissionAction.DELETE} a={PermissionObjectType.EXTENSION}>
            {!record.expires ||
            new Date(record.expires).getTime() > Date.now() ? (
              <Tooltip
                title={t({
                  id: 'settings.api_keys.action.deactivate',
                  message: 'Deactiveren',
                })}
              >
                <Popconfirm
                  placement={'left'}
                  title={t({
                    id: 'settings.api_keys.action.deactivate.confirm',
                    message:
                      'Ben je zeker dat je deze sleutel wilt deactiveren? Deze actie kan niet ongedaan gemaakt worden.',
                  })}
                  open={
                    deletePopConfirmIndex === record._id &&
                    deletePopConfirmVisible
                  }
                  okType="danger"
                  okText={t({
                    id: 'settings.api_keys.action.deactivate',
                    message: 'Deactiveren',
                  })}
                  cancelText={t({
                    id: 'action.cancel',
                    message: 'Annuleren',
                  })}
                  okButtonProps={{ loading: deleting }}
                  onConfirm={() => {
                    revokeKey({
                      variables: {
                        id: record._id,
                      },
                      refetchQueries: ['apiKeys'],
                    })
                      .then(() => {
                        notification.success({
                          message: t({
                            id: 'settings.api_keys.action.deactivate.success',
                            message: 'API-sleutel succesvol gedeactiveerd.',
                          }),
                        })
                      })
                      .catch(errorNotifierFn)
                      .finally(() => setDeletePopConfirmVisible(false))
                  }}
                  onCancel={() => setDeletePopConfirmVisible(false)}
                >
                  <Button
                    onClick={() => {
                      setDeletePopConfirmIndex(record._id)
                      setDeletePopConfirmVisible(true)
                    }}
                    shape="circle"
                    icon={<StopOutlined />}
                  />
                </Popconfirm>
              </Tooltip>
            ) : null}
          </Can>
        </ActionButtonWrapper>
      ),
    },
  ]

  const handleCreation = async () => {
    try {
      const key = await form.validateFields()
      createKey({
        variables: {
          ...key,
        },
        refetchQueries: ['apiKeys'],
      })
        .then((response) => {
          setNewlyCreatedApiKey(response.data?.createApiKey)
        })
        .catch(errorNotifierFn)
    } catch {
      return
    }
  }

  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={t({
          id: 'settings.api_keys.title',
          message: 'API-sleutels',
        })}
        style={{ backgroundColor: '#FFF' }}
        extra={[
          <InputSearch
            key="1"
            placeholder={t({
              id: 'settings.api_keys.search',
              message: 'Zoeken op naam',
            })}
            onSearch={(value) => {
              setSearchTerm(value)
              setPage(1)
            }}
            style={{ width: 200 }}
          />,
          <Can
            key="2"
            I={PermissionAction.CREATE}
            a={PermissionObjectType.API_KEY}
          >
            <Button onClick={() => setKeyModalVisible(true)} type="primary">
              <Trans id="settings.api_keys.action.create">
                API-sleutel aanmaken
              </Trans>
            </Button>
          </Can>,
        ]}
      />
      <Table
        locale={{
          emptyText: t({
            id: 'settings.api_keys.table.empty',
            message: 'Geen API-sleutels gevonden.',
          }),
          cancelSort: t({
            id: 'table.sort.cancel',
            message: 'Klik om niet langer te sorteren.',
          }),
          triggerAsc: t({
            id: 'table.sort.asc',
            message: 'Klik om oplopend te sorteren.',
          }),
          triggerDesc: t({
            id: 'table.sort.desc',
            message: 'Klik om aflopend te sorteren.',
          }),
        }}
        scroll={{ x: 400 }}
        dataSource={apiKeys}
        loading={loading}
        columns={columns}
        showSorterTooltip={false}
        pagination={{
          current: page,
          onChange: (page: number) => setPage(page),
        }}
      />
      <Modal
        forceRender
        title={
          newlyCreatedApiKey
            ? t({
                id: 'settings.api_keys.modal.created.title',
                message:
                  'Deze API-sleutel krijg je slechts één keer te zien. Kopieer deze naar een veilige plek vooraleer je dit venster sluit.',
              })
            : t({
                id: 'settings.api_keys.modal.create.title',
                message: 'Nieuwe API-sleutel aanmaken',
              })
        }
        open={keyModalVisible}
        onOk={handleCreation}
        confirmLoading={creating}
        onCancel={() => {
          setKeyModalVisible(false)
        }}
        cancelText={t({
          id: 'action.cancel',
          message: 'Annuleren',
        })}
        okText={t({
          id: 'action.create',
          message: 'Aanmaken',
        })}
        okButtonProps={{ hidden: !!newlyCreatedApiKey }}
        cancelButtonProps={{ hidden: !!newlyCreatedApiKey }}
        width={640}
        maskClosable={!newlyCreatedApiKey}
        afterClose={() => {
          setNewlyCreatedApiKey(undefined)
        }}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={handleCreation}
          autoComplete="off"
          hidden={!!newlyCreatedApiKey}
        >
          <Form.Item
            label={t({
              id: 'settings.api_keys.form.label.label',
              message: 'Label',
            })}
            name="label"
            help={t({
              id: 'settings.api_keys.form.help.label',
              message:
                'Dit label wordt gebruikt om de API-sleutel te identificeren.',
            })}
            rules={[
              {
                required: true,
                message: t({
                  id: 'settings.api_keys.form.validation.label',
                  message:
                    'Gelieve een label voor deze API-sleutel in te vullen.',
                }),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Button hidden disabled={creating} type="primary" htmlType={'submit'}>
            <Trans id="settings.api_keys.form.submit">Sleutel aanmaken</Trans>
          </Button>
        </Form>
        <p hidden={!newlyCreatedApiKey}>
          <Trans id="settings.api_keys.modal.created.description">
            Klik op de sleutel om deze te kopiëren.
          </Trans>
        </p>
        <ApiKey
          hidden={!newlyCreatedApiKey}
          onClick={() => {
            copy(newlyCreatedApiKey || '').then(() => {
              message.info(
                t({
                  id: 'settings.api_keys.modal.created.copied',
                  message: 'API-sleutel gekopieerd naar klembord.',
                })
              )
            })
          }}
        >
          {newlyCreatedApiKey}
        </ApiKey>
      </Modal>
    </>
  )
}
