/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable unicorn/no-useless-undefined */
import { DeleteOutlined, EditOutlined, DownOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import {
  Button,
  Form,
  Input,
  notification,
  PageHeader,
  Popconfirm,
  Table,
  Tooltip,
  Transfer,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import Modal from 'antd/lib/modal/Modal'
import { ColumnsType } from 'antd/lib/table'
import { CompareFn } from 'antd/lib/table/interface'
import { ObjectId } from 'bson'
import dayjs, { extend } from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import Fuse from 'fuse.js'
import { test } from 'fuzzyjs'
import { useEffect, useMemo, useState } from 'react'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  BranchCategoriesQuery,
  CreateBranchCategoryMutation,
  DeleteCategoryMutation,
  UpdateBranchCategoryMutation,
} from 'apps/lms-front/src/generated/graphql'

import { Can } from '../../../auth/components/Can'
import { ActionButtonWrapper } from '../../../shared/components/action-button-wrapper/ActionButtonWrapper'
import { DropdownButton } from '../../../shared/components/dynamic-dropdown-button/DropdownButton'
import { InputSearch } from '../../../shared/components/input-search/InputSearch'
import { errorNotifierFn } from '../../../shared/helpers/error-notifier'
import { dateSort, defaultSort } from '../../../shared/utils/sort'

import CREATE_CATEGORY_MUTATION from './../../mutations/create-branch-category.graphql'
import DELETE_CATEGORY_MUTATION from './../../mutations/delete-branch-category.graphql'
import UPDATE_CATEGORY_MUTATION from './../../mutations/update-branch-category.graphql'
import CATEGORIES_QUERY from './../../queries/branch-categories.graphql'

extend(utc)
extend(timezone)
extend(relativeTime)

interface CategoryRow {
  key: string
  name: string
  created?: Date
}

export const BranchCategories = () => {
  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [categoryModalVisible, setCategoryModalVisible] = useState(false)
  const [deletePopConfirmIndex, setDeletePopConfirmIndex] = useState<string>()
  const [deletePopConfirmVisible, setDeletePopConfirmVisible] = useState(false)
  const [updateSubject, setUpdateSubject] =
    useState<BranchCategoriesQuery['fetchBranchCategories'][0]>()
  const [selectedCourses, setSelectedCourses] = useState<string[]>([])

  const [form] = useForm()

  const { data, loading } = useQuery<BranchCategoriesQuery>(CATEGORIES_QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  const [createBranchCategory, { loading: creating }] =
    useMutation<CreateBranchCategoryMutation>(CREATE_CATEGORY_MUTATION)

  const [updateCategory, { loading: updating }] =
    useMutation<UpdateBranchCategoryMutation>(UPDATE_CATEGORY_MUTATION)

  const [deleteCategory, { loading: deleting }] =
    useMutation<DeleteCategoryMutation>(DELETE_CATEGORY_MUTATION)

  useEffect(() => {
    if (updateSubject) {
      setCategoryModalVisible(true)
      setSelectedCourses(updateSubject.courses)
    }
  }, [updateSubject])

  useEffect(() => {
    if (categoryModalVisible) form.resetFields()
  }, [categoryModalVisible, form])

  const categories = useMemo(() => {
    const fuse = new Fuse(data?.fetchBranchCategories || [], {
      keys: ['name'],
      findAllMatches: true,
    })

    const result = fuse.search(searchTerm)

    return (
      searchTerm.length > 1
        ? result.map((result) => result.item)
        : data?.fetchBranchCategories || []
    ).map<CategoryRow>(
      (item: BranchCategoriesQuery['fetchBranchCategories'][0]) => ({
        key: item._id,
        name: item.name,
        created: item.created,
      })
    )
  }, [data, searchTerm])

  const filterOption = (
    inputValue: string,
    option: { _id: string; name: string }
  ) =>
    test(inputValue, option.name, {
      caseSensitive: false,
    })

  const columns: ColumnsType<CategoryRow> = [
    {
      title: t({
        id: 'branch.categories.table.name',
        message: 'Naam',
      }),
      dataIndex: 'name',
      key: 'name',
      sorter: defaultSort('name') as CompareFn<unknown>,
      fixed: 'left',
    },
    {
      title: t({
        id: 'branch.categories.table.created',
        message: 'Aangemaakt',
      }),
      dataIndex: 'created',
      key: 'created',
      sorter: dateSort('created') as CompareFn<unknown>,
      render: (text: string) => dayjs(text).tz('Europe/Brussels').fromNow(),
    },
    {
      title: t({
        id: 'branch.categories.table.actions',
        message: 'Acties',
      }),
      key: 'operation',
      fixed: 'right',
      width: 110,
      render: (_, record: CategoryRow) => (
        <ActionButtonWrapper>
          <Can
            I={PermissionAction.UPDATE}
            a={PermissionObjectType.BRANCH_COURSE_CATEGORY}
          >
            <Tooltip
              title={t({
                id: 'action.edit',
                message: 'Bewerken',
              })}
            >
              <Button
                onClick={() => {
                  setUpdateSubject(
                    data?.fetchBranchCategories.find((branch) =>
                      new ObjectId(branch._id).equals(record.key)
                    )
                  )
                }}
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>
          </Can>
          <Can
            I={PermissionAction.DELETE}
            a={PermissionObjectType.BRANCH_COURSE_CATEGORY}
          >
            <Tooltip
              title={t({
                id: 'action.delete',
                message: 'Verwijderen',
              })}
            >
              <Popconfirm
                placement={'left'}
                title={t({
                  id: 'branch.categories.delete.title',
                  message:
                    'Ben je zeker dat je deze categorie wil verwijderen?',
                })}
                open={
                  deletePopConfirmIndex === record.key &&
                  deletePopConfirmVisible
                }
                okType="danger"
                okText={t({
                  id: 'action.delete',
                  message: 'Verwijderen',
                })}
                cancelText={t({
                  id: 'action.cancel',
                  message: 'Annuleren',
                })}
                okButtonProps={{ loading: deleting }}
                onConfirm={() => {
                  deleteCategory({
                    variables: {
                      id: record.key,
                    },
                    refetchQueries: ['branchCategories'],
                  })
                    .then(() => {
                      notification.success({
                        message: t({
                          id: 'branch.categories.delete.success',
                          message: 'Categorie succesvol verwijderd',
                        }),
                      })
                    })
                    .catch(errorNotifierFn)
                    .finally(() => setDeletePopConfirmVisible(false))
                }}
                onCancel={() => setDeletePopConfirmVisible(false)}
              >
                <Button
                  onClick={() => {
                    setDeletePopConfirmIndex(record.key)
                    setDeletePopConfirmVisible(true)
                  }}
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Tooltip>
          </Can>
        </ActionButtonWrapper>
      ),
    },
  ]

  const handleCreation = async () => {
    try {
      const fields = await form.validateFields()
      createBranchCategory({
        variables: {
          ...fields,
          courses: selectedCourses,
        },
        refetchQueries: ['branchCategories'],
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'branch.categories.create.success',
              message: 'Categorie succesvol aangemaakt',
            }),
          })
          setCategoryModalVisible(false)
        })
        .catch(errorNotifierFn)
    } catch {
      return
    }
  }

  const handleUpdate = async () => {
    try {
      const fields = await form.validateFields()
      updateCategory({
        variables: {
          id: updateSubject?._id,
          courses: selectedCourses,
          ...fields,
        },
        refetchQueries: ['branchCategories'],
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'branch.categories.update.success',
              message: 'Categorie succesvol gewijzigd',
            }),
          })
          setCategoryModalVisible(false)
        })
        .catch(errorNotifierFn)
    } catch {
      return
    }
  }

  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={t({
          id: 'branch.categories.heading',
          message: 'Categorieën',
        })}
        style={{ backgroundColor: '#FFF' }}
        extra={[
          <InputSearch
            key="1"
            placeholder={t({
              id: 'branch.categories.search',
              message: 'Zoeken op naam',
            })}
            onSearch={(value) => {
              setSearchTerm(value)
              setPage(1)
            }}
            style={{ width: 200 }}
          />,
          <Can
            key="2"
            I={PermissionAction.CREATE}
            a={PermissionObjectType.BRANCH_COURSE_CATEGORY}
          >
            <DropdownButton
              icon={<DownOutlined />}
              onClick={() => setCategoryModalVisible(true)}
              type="primary"
              menu={{
                hidden: true,
                items: [
                  {
                    key: 'import-branch-categories',
                    label: t({
                      id: 'branch.categories.import',
                      message: 'Categorieën importeren',
                    }),
                  },
                ],
              }}
            >
              <Trans id="branch.categories.create">Categorie aanmaken</Trans>
            </DropdownButton>
          </Can>,
        ]}
      />
      <Table
        locale={{
          emptyText: t({
            id: 'branch.categories.table.empty',
            message: 'Geen categorieën gevonden.',
          }),
          cancelSort: t({
            id: 'table.sort.cancel',
            message: 'Klik om niet langer te sorteren.',
          }),
          triggerAsc: t({
            id: 'table.sort.asc',
            message: 'Klik om oplopend te sorteren.',
          }),
          triggerDesc: t({
            id: 'table.sort.desc',
            message: 'Klik om aflopend te sorteren.',
          }),
        }}
        scroll={{ x: 400 }}
        dataSource={categories}
        loading={loading}
        columns={columns}
        showSorterTooltip={false}
        pagination={{
          current: page,
          onChange: (page: number) => setPage(page),
        }}
      />
      <Modal
        forceRender
        title={
          updateSubject
            ? t({
                id: 'branch.categories.update.title',
                message: 'Categorie wijzigen',
              })
            : t({
                id: 'branch.categories.create.title',
                message: 'Nieuwe categorie aanmaken',
              })
        }
        open={categoryModalVisible}
        onOk={updateSubject ? handleUpdate : handleCreation}
        confirmLoading={creating || updating}
        onCancel={() => {
          setCategoryModalVisible(false)
        }}
        afterClose={() => {
          setUpdateSubject(undefined)
        }}
        cancelText={t({
          id: 'action.cancel',
          message: 'Annuleren',
        })}
        okText={
          updateSubject
            ? t({
                id: 'action.update',
                message: 'Wijzigen',
              })
            : t({
                id: 'action.create',
                message: 'Aanmaken',
              })
        }
        width={640}
      >
        <Form
          key={updateSubject?._id}
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={updateSubject ? handleUpdate : handleCreation}
          initialValues={updateSubject || undefined}
          autoComplete="off"
        >
          <Form.Item
            label={t({
              id: 'branch.categories.form.label.name',
              message: 'Naam',
            })}
            name="name"
            rules={[
              {
                required: true,
                message: t({
                  id: 'branch.categories.form.validation.name',
                  message: 'Gelieve de naam van de categorie in te vullen.',
                }),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Transfer
            operationStyle={{ animation: 'none' }}
            style={{ height: 300 }}
            listStyle={{ width: 275, height: 300 }}
            showSearch
            // @ts-ignore
            dataSource={data?.fetchBranchAccessibleCourses.results}
            // @ts-ignore
            filterOption={filterOption}
            targetKeys={selectedCourses}
            // @ts-ignore
            render={(record: (typeof courses)[0]) => record.name}
            onChange={(courses) => setSelectedCourses(courses)}
            // @ts-ignore
            rowKey={(record: (typeof courses)[0]) => record._id}
            titles={[
              t({
                id: 'branch.categories.form.transfer.course',
                message: 'Basisopleidingen',
              }),
              t({
                id: 'branch.categories.form.transfer.selected',
                message: 'Geselecteerd',
              }),
            ]}
            pagination={{
              pageSize: 100,
            }}
          />
          <Button hidden disabled={creating} type="primary" htmlType={'submit'}>
            <Trans id="action.save">Opslaan</Trans>
          </Button>
        </Form>
      </Modal>
    </>
  )
}
