import { Trans, t } from '@lingui/macro'
import { Modal } from 'antd'
import { useEffect } from 'react'

import { PublicUnitQuery } from 'apps/lms-front/src/generated/graphql'
import { useAuth } from 'apps/lms-front/src/modules/auth/hooks/use-auth'
import { useAxios } from 'apps/lms-front/src/modules/shared/hooks/use-axios'
import { useSocket } from 'apps/lms-front/src/modules/shared/hooks/use-socket.hook'

import { ConnectionStatus } from '../../../../components/connection-status/ConnectionStatus'
import { EventResults } from '../../../../components/event-results/EventResults'
import { Question } from '../../quiz-unit-viewer/QuizUnitViewer.styles'

type VideoUnit = PublicUnitQuery['fetchUnitById'] & {
  __typename: 'VideoUnit'
}

type remoteIntermediaryQuestionsModalProps = {
  event_id: string
  open: boolean
  onFinish: () => void
  unit: VideoUnit
  questions: (VideoUnit['questions'][0] | VideoUnit['survey_questions'][0])[]
}

export const RemoteIntermediaryQuestionsModal = ({
  event_id,
  open,
  onFinish,
  unit,
  questions,
}: remoteIntermediaryQuestionsModalProps) => {
  const { token } = useAuth()

  const [{ data: results }, refetchResults] = useAxios<
    { total: number; completed: number } | undefined
  >({
    url: `/api/activity/event/${event_id}/unit/${unit._id}`,
  })

  const { connected: eventConnected, emit } = useSocket('/api/event/ws', {
    skip: !event_id,
    token,
    events: {
      eventActionFinished: () => refetchResults(),
    },
  })

  useEffect(() => {
    if (open) {
      /**
       * Trigger the event unit action to show the questions
       */
      emit('triggerEventUnitAction', {
        event_id,
        unit_id: unit._id,
      })
    }
  }, [open, event_id, unit._id])

  return (
    <Modal
      open={questions.length > 0 && open}
      okText={t({
        id: 'action.continue',
        message: 'Doorgaan',
      })}
      onOk={async () => {
        emit('expireEventAction', {
          event_id,
          unit_id: unit._id,
        })
        onFinish()
      }}
      cancelButtonProps={{
        hidden: true,
        disabled: true,
      }}
      closable={false}
      maskClosable={false}
      maskStyle={{ backdropFilter: 'blur(5px)' }}
    >
      <ConnectionStatus connected={eventConnected} />
      <Question>
        <Trans id="unit.viewer.video.answer_questions_with_companion">
          Beantwoord de tussentijdse vragen op je telefoon
        </Trans>
      </Question>
      {results && (
        <EventResults total={results.total} completed={results.completed} />
      )}
    </Modal>
  )
}
