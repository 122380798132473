/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { t } from '@lingui/macro'
import {
  Player as VimePlayer,
  Hls,
  Ui,
  Control,
  Controls,
  ControlGroup,
  ScrubberControl,
  PlaybackControl,
  VolumeControl,
  ClickToPlay,
  ControlSpacer,
  FullscreenControl,
  PipControl,
  SettingsControl,
  DblClickFullscreen,
  DefaultSettings,
  TimeProgress,
  Youtube,
  Tooltip,
  Icon,
  LoadingScreen,
  Vimeo,
  Video,
} from '@vime/react'
import { debounce } from 'lodash-es'
import { SyntheticEvent, useEffect, useMemo, useState } from 'react'

import {
  getVimeoID,
  getYouTubeID,
  isVimeoVideoURL,
  isYouTubeVideoURL,
} from '@lms-shared-patterns/utils'
import { useAuth } from 'apps/lms-front/src/modules/auth/hooks/use-auth'

import { useMediaQuery } from '../../hooks/use-media-query'

import { Scrim } from './player.styled'

type PlayerProps = {
  playerRef?: React.RefObject<HTMLVmPlayerElement>
  src: { hls: string } | { url: string }
  enablePlaybackRates?: boolean
  disableAutoPlay?: boolean
  onIOSReady?: (video?: HTMLVideoElement) => void
  onReady?: (video?: HTMLVideoElement) => void
  onEnded?: () => void
  onProgress?: ({ playedSeconds }: { playedSeconds: number }) => void
  onPlaybackRateChange?: (playbackRate: number) => void
}

export const Player = ({
  playerRef,
  src,
  enablePlaybackRates,
  disableAutoPlay,
  onIOSReady,
  onReady,
  onProgress,
  onEnded,
  onPlaybackRateChange,
}: PlayerProps) => {
  const { user } = useAuth()
  const [playbackRates, setPlaybackRates] = useState<number[]>([1])

  const isMobile = useMediaQuery(`(max-width: 767px)`)

  const isiPhoneSafari = () => {
    const userAgent = navigator.userAgent
    const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent)
    const isiPhone = /(iphone|ipod)/i.test(userAgent)
    return isSafari && isiPhone
  }

  const video = playerRef?.current?.querySelector<HTMLVideoElement>('video')

  useEffect(() => {
    const listener = (event: SyntheticEvent<{ currentTime: number }>) => {
      onProgress &&
        onProgress({ playedSeconds: event.currentTarget.currentTime })
    }
    // @ts-ignore
    video?.addEventListener('timeupdate', listener)
    // @ts-ignore
    return () => video?.removeEventListener('timeupdate', listener)
  }, [onProgress, video])

  const onVmProgress = useMemo(
    () =>
      debounce(
        ({ playedSeconds }: { playedSeconds: number }) => {
          onProgress && onProgress({ playedSeconds })
        },
        200,
        { trailing: true, maxWait: 200 }
      ),
    [onProgress]
  )

  return (
    <VimePlayer
      ref={playerRef}
      autoplay={!disableAutoPlay}
      playbackRates={playbackRates}
      onVmReady={() => {
        if (playerRef?.current) {
          const video = playerRef.current?.querySelector('video')
          onReady && onReady(video || undefined)
          if (user?.lang === 'fr') playerRef.current.setCurrentTextTrack(1)
        }
      }}
      onVmPlaybackStarted={() => {
        if (playerRef?.current && isiPhoneSafari()) {
          const video = playerRef.current.querySelector('video')
          onIOSReady && onIOSReady(video || undefined)

          /**
           * Disable playbackRates other than 1
           */
          if (video && !enablePlaybackRates) {
            video.addEventListener('ratechange', () => {
              video.playbackRate = 1
            })
          } else if (!enablePlaybackRates) {
            playerRef.current.addEventListener('ratechange', () => {
              if (playerRef.current) playerRef.current.playbackRate = 1
            })
          }
        }
      }}
      onVmPlayingChange={() => {
        setPlaybackRates(
          enablePlaybackRates ? [0.5, 0.75, 0.9, 1, 1.25, 1.5, 1.75, 2] : [1]
        )
      }}
      onVmPlaybackEnded={() => {
        onEnded && onEnded()
      }}
      onVmPlaybackRateChange={(evt) => {
        console.log('event', evt)
        onPlaybackRateChange && onPlaybackRateChange(evt.detail)
        if (!enablePlaybackRates && playerRef?.current)
          playerRef.current.playbackRate = 1
      }}
      onVmCurrentTimeChange={(evt) => {
        if (!video) onVmProgress({ playedSeconds: evt.detail })
      }}
    >
      {'hls' in src && (
        <Hls
          version={'1.4.14'}
          controlsList={enablePlaybackRates ? '' : 'noplaybackrate'}
        >
          <source data-src={src.hls} type={'application/x-mpegURL'} />
        </Hls>
      )}
      {'url' in src &&
        src.url.startsWith('https://storage.googleapis.com/') && (
          <Video>
            <source data-src={src.url} type="video/mp4" />
          </Video>
        )}
      {'url' in src && isYouTubeVideoURL(src.url) && (
        <Youtube videoId={getYouTubeID(src.url)} />
      )}
      {'url' in src && isVimeoVideoURL(src.url) && (
        <Vimeo videoId={getVimeoID(src.url)} />
      )}
      {('hls' in src ||
        ('url' in src &&
          src.url.startsWith('https://storage.googleapis.com/'))) && (
        <Ui>
          <LoadingScreen />
          <Controls fullWidth hideOnMouseLeave>
            <Scrim>
              <div className={`scrim gradient gradientUp`} />
            </Scrim>
            <ControlGroup>
              <ScrubberControl />
            </ControlGroup>
            <ControlGroup space="top">
              <PlaybackControl tooltipDirection="right" hideTooltip />
              {!isMobile && (
                <Control
                  label={t({
                    id: 'player.rewind',
                    message: '10 seconden terugspoelen',
                  })}
                  onClick={() => {
                    if (playerRef?.current)
                      playerRef.current.currentTime =
                        playerRef.current.currentTime - 10
                  }}
                >
                  <Icon src="/icons/fast-forward.svg" />
                  <Tooltip>- 10s</Tooltip>
                </Control>
              )}
              {!isMobile && (
                <Control
                  label={t({
                    id: 'player.forward',
                    message: '30 seconden vooruitspoelen',
                  })}
                  onClick={() => {
                    if (playerRef?.current)
                      playerRef.current.currentTime =
                        playerRef.current.currentTime + 30
                  }}
                >
                  <Icon src="/icons/rewind.svg" />
                  <Tooltip>+ 30s</Tooltip>
                </Control>
              )}
              <VolumeControl hideTooltip />
              {!isMobile && <TimeProgress separator="/" />}
              <ControlSpacer />
              {!isMobile && <PipControl hideTooltip />}
              <SettingsControl />
              <FullscreenControl tooltipDirection="left" hideTooltip />
            </ControlGroup>
          </Controls>
          <DefaultSettings />
          <ClickToPlay />
          <DblClickFullscreen />
        </Ui>
      )}
    </VimePlayer>
  )
}
