/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { QuestionCircleOutlined } from '@ant-design/icons'
import { ApolloQueryResult } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import { Button, Col, Space, Tooltip } from 'antd'
import { useFeatureFlagEnabled, usePostHog } from 'posthog-js/react'
import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useParams } from 'react-router-dom'

import { createProgressMap, deflate, inflate } from '@lms-shared-patterns/utils'
import {
  PublicUnitQuery,
  UserQuizUnitActivity,
  UserSurveyUnitActivity,
} from 'apps/lms-front/src/generated/graphql'
import { useAuth } from 'apps/lms-front/src/modules/auth/hooks/use-auth'
import { Player } from 'apps/lms-front/src/modules/shared/components/player/player'
import { logActivity } from 'apps/lms-front/src/modules/shared/helpers/log-activity'
import { useInterval } from 'apps/lms-front/src/modules/shared/hooks/use-interval'
import { useSocket } from 'apps/lms-front/src/modules/shared/hooks/use-socket.hook'

import { exitFullscreen } from '../../../helpers/exit-fullscreen'
import { transformTimestampsInVideoAnnotation } from '../../../helpers/transform-timestamps'

import { PageHeader } from './../UnitViewer.style'
import { IntermediaryQuestionsModal } from './modals/IntermediaryQuestionsModal'
import { RemoteIntermediaryQuestionsModal } from './modals/RemoteIntermediaryQuestionsModal'
import { VideoProgressModal } from './modals/VideoProgressModal'
import { VideoQuestionModal } from './modals/VideoQuestionModal'
import { ALink } from './VideoUnitViewer.style'

export const VIDEO_UNIT_COMPLETE_PCT = 0.95

type VideoUnit = PublicUnitQuery['fetchUnitById'] & { __typename: 'VideoUnit' }

interface Props {
  unit: VideoUnit
  navigate: (uri: string) => void
  navigateToUnit: (id: string) => void
  allowChangingPlaybackRate: boolean
  refetchUnit?: () => Promise<ApolloQueryResult<PublicUnitQuery>>
  parent: string
}

function viewMapReducer(
  state: number[],
  { second, reset }: { second?: number; reset?: number }
) {
  if (reset) return Array.from<number>({ length: reset + 1 }).fill(0)
  if (typeof second === 'number' && second >= 0) {
    const newMap = [...state]
    newMap[second] += 1
    return newMap
  }
  return state
}

export const VideoUnitViewer = ({
  unit,
  navigate,
  navigateToUnit,
  allowChangingPlaybackRate,
  refetchUnit,
  parent,
}: Props) => {
  const { event_id } = useParams()

  const posthog = usePostHog()
  const playFromBackups = useFeatureFlagEnabled('PlayFromCloudBackups')

  /**
   * Socket connection
   */
  const { token } = useAuth()
  const { connected: wsConnected, emit } = useSocket('/api/activity/ws', {
    token,
    transports: ['polling', 'websocket'],
  })

  /**
   * Progress modal
   */
  const [progressModalVisible, setProgressModalVisible] =
    useState<boolean>(false)

  /**
   * Quiz
   */
  const [
    intermediaryQuestionsModalVisible,
    setIntermediaryQuestionsModalVisible,
  ] = useState<boolean>(false)
  const [questionsAnswered, setQuestionsAnswered] = useState<boolean>(false)

  /**
   * Question modal
   */
  const [questionModalOpen, setQuestionModalOpen] = useState(false)

  /** Video-related */
  const [loading, setLoading] = useState(false)
  const playerRef = useRef<HTMLVmPlayerElement>(null)
  const video = useMemo(() => {
    return playerRef.current?.querySelector('video')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerRef.current])

  const questions: Array<
    VideoUnit['questions'][0] | VideoUnit['survey_questions'][0]
  > = useMemo(() => {
    return [...(unit.questions || []), ...(unit.survey_questions || [])]
  }, [unit])

  const answers: Array<UserSurveyUnitActivity | UserQuizUnitActivity> =
    useMemo(() => {
      return [
        ...(unit.my_activity?.survey_answers || []),
        ...(unit.my_activity?.quiz_answers || []),
      ]
    }, [unit])

  const [position, setPosition] = useState(
    unit.my_activity?.completed
      ? 0
      : unit.my_activity?.video_progress?.last_position || 0
  )
  const prevSecondRef = useRef<number | undefined>(
    unit.my_activity?.video_progress?.last_position
      ? Math.max(
          Math.ceil(unit.my_activity?.video_progress?.last_position) - 1,
          0
        )
      : undefined
  )
  const [watchedPercentage, setWatchedPercentage] = useState(
    unit.my_activity?.video_progress?.watched_pct || 0
  )

  const initProgressMap = useMemo(() => {
    if (
      unit.my_activity?.video_progress &&
      unit.my_activity.video_progress.deflated_progress_map
    ) {
      return [
        ...inflate(
          unit.my_activity.video_progress.deflated_progress_map,
          unit.cf_stream?.duration
        ),
      ]
    }
    return createProgressMap(unit.cf_stream?.duration)
  }, [unit.my_activity?.video_progress, unit.cf_stream?.duration])

  const [viewMap, setSecondViewed] = useReducer(viewMapReducer, initProgressMap)

  const ping = useCallback(() => {
    const watchedSeconds = viewMap.reduce(
      (prev, cur) => prev + (cur > 0 ? 1 : 0),
      0
    )
    const _watchedPercentage = watchedSeconds / viewMap.length
    setWatchedPercentage(_watchedPercentage)
    const map = deflate(Uint8Array.from(viewMap))

    if (wsConnected)
      emit('activity', {
        event_id,
        unit_id: unit._id,
        video_progress: {
          watched_pct: watchedPercentage,
          last_position: position,
          deflated_progress_map: map,
        },
      })
    else
      logActivity({
        event_id,
        unit_id: unit._id,
        video_progress: {
          watched_pct: watchedPercentage,
          last_position: position,
          deflated_progress_map: map,
        },
      })
  }, [position, viewMap, watchedPercentage, unit._id, wsConnected, event_id])

  const handleProgressReset = () => {
    const length = viewMap.length
    setSecondViewed({ reset: length })
    setWatchedPercentage(0)
    setPosition(0)
    if (playerRef.current) {
      if (video) video.currentTime = 0
      else {
        playerRef.current.currentTime = 0.01
      }
    }
    setIntermediaryQuestionsModalVisible(false)

    if (playerRef.current) {
      if (video) video.play()
      else playerRef.current.play()
    }

    /** reset immediately */
    logActivity({
      event_id,
      unit_id: unit._id,
      video_progress: {
        watched_pct: 0,
        last_position: 0,
        deflated_progress_map: null,
      },
    })
  }

  const handleIntermediaryQuestions = () => {
    if (questions.length === 0) return
    const no_of_questions = questions.length

    if (
      answers?.filter((answer) => !!answer.submitted).length === no_of_questions
    )
      return

    if (questionsAnswered) return

    if (playerRef.current) {
      /** Pause video */
      if (video) video.pause()
      else playerRef.current.pause()

      /** Exit fullscreen */
      try {
        playerRef.current?.exitFullscreen()
        // eslint-disable-next-line no-empty
      } catch {}

      try {
        // @ts-ignore
        if (playerRef.current?.webkitExitFullscreen)
          // @ts-ignore
          playerRef.current?.webkitExitFullscreen()
        // eslint-disable-next-line no-empty
      } catch {}

      try {
        exitFullscreen()
        // eslint-disable-next-line no-empty
      } catch {}
    }

    setIntermediaryQuestionsModalVisible(true)
  }

  const finishUpIntermediaryQuestions = () => {
    setQuestionsAnswered(true)
    setIntermediaryQuestionsModalVisible(false)

    if (playFromBackups) {
      console.log('go to unwatched part (using back-ups)')
      goToUnwatchedPart()
    } else {
      console.log('force play (not using back-ups)')
      playerRef.current?.play()
    }
  }

  useEffect(() => {
    if (watchedPercentage >= unit.questions_trigger_pct) {
      handleIntermediaryQuestions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedPercentage])

  const handlePlayerReady = (video?: HTMLVideoElement) => {
    posthog.capture('video_player_ready', {
      unit_id: unit._id,
      unit_name: unit.name,
      course_id: unit.course_id,
    })
    if (playerRef.current) {
      watchedPercentage === 0 &&
        setSecondViewed({ reset: playerRef.current.duration })
      if (position > 0) {
        if (video) setTimeout(() => (video.currentTime = position), 0)
        else playerRef.current.currentTime = position
      }
    }
  }

  const handleIOSPlayerReady = (video?: HTMLVideoElement) => {
    posthog.capture('video_player_ready', {
      unit_id: unit._id,
      unit_name: unit.name,
      course_id: unit.course_id,
      platform: 'ios',
    })
    if (playerRef.current) {
      if (watchedPercentage === 0) {
        if (video) setSecondViewed({ reset: video.duration })
        else setSecondViewed({ reset: playerRef.current.duration })
      }

      if (position > 0) {
        if (video) video.currentTime = position
        else playerRef.current.currentTime = position
      }
    }
  }

  const handleProgress = ({ playedSeconds }: { playedSeconds: number }) => {
    const _currentTime = playedSeconds
    const _secondViewed = Math.max(0, Math.ceil(_currentTime) - 1)
    if (_secondViewed !== prevSecondRef.current)
      setSecondViewed({
        second: _secondViewed,
      })
    prevSecondRef.current = _secondViewed
    setPosition(_currentTime)
  }

  const handleEnded = () => {
    posthog.capture('video_player_ended', {
      unit_id: unit._id,
      unit_name: unit.name,
      course_id: unit.course_id,
    })
    const _currentTime = playerRef.current?.currentTime
    if (_currentTime) {
      const _secondViewed = Math.max(0, Math.floor(_currentTime))
      if (_secondViewed !== prevSecondRef.current)
        setSecondViewed({ second: _secondViewed })
      setPosition(_currentTime)
    }
  }

  useEffect(() => {
    if (playerRef.current && !playerRef.current.playing && position > 0) ping()
  }, [playerRef, position, ping])

  useInterval(
    () => ping(),
    playerRef.current && playerRef.current.playing ? 3000 : null
  )

  const goToUnwatchedPart = () => {
    const unwatchedPart = viewMap.indexOf(0)
    playerRef.current?.pause()
    setPosition(unwatchedPart - 1)
    if (playerRef.current) playerRef.current.currentTime = unwatchedPart - 1
    playerRef.current?.play()
  }

  return (
    <Col
      xs={24}
      style={{ textAlign: 'center', maxWidth: '100%', overflow: 'hidden' }}
    >
      <Space>
        <PageHeader
          title={unit.name}
          style={{ paddingLeft: 0, paddingRight: 0 }}
        />
        <Tooltip
          placement="topLeft"
          title={t({
            id: 'unit.viewer.video.ask_question.tooltip',
            message: 'Een vraag stellen',
          })}
          arrowPointAtCenter
        >
          <QuestionCircleOutlined onClick={() => setQuestionModalOpen(true)} />
        </Tooltip>
      </Space>
      <Space direction="vertical" size={'large'} style={{ width: '100%' }}>
        {(unit.cf_stream?.uid || unit.external_url) && (
          <Player
            key={`sequentiality-type-${allowChangingPlaybackRate}`}
            playerRef={playerRef}
            src={
              unit.cf_stream?.signedBucketURL && playFromBackups
                ? {
                    url: unit.cf_stream?.signedBucketURL,
                  }
                : unit.cf_stream?.uid
                ? {
                    hls: unit.cf_stream?.playback.hls,
                  }
                : {
                    url: unit.external_url || '',
                  }
            }
            enablePlaybackRates={allowChangingPlaybackRate}
            onReady={handlePlayerReady}
            onIOSReady={handleIOSPlayerReady}
            onProgress={handleProgress}
            onEnded={handleEnded}
          ></Player>
        )}
        {unit.annotation && (
          <article style={{ textAlign: 'left' }}>
            {ReactHtmlParser(unit.annotation, {
              transform: (node, index: number) =>
                transformTimestampsInVideoAnnotation(node, index, (seconds) => {
                  if (playerRef.current) {
                    playerRef.current.currentTime = seconds
                  }
                }),
            })}
          </article>
        )}
        {watchedPercentage >= VIDEO_UNIT_COMPLETE_PCT ||
        unit.my_activity?.completed ? (
          <>
            {unit.next ? (
              <Button
                type="primary"
                loading={loading}
                onClick={() => {
                  setLoading(true)
                  logActivity({
                    event_id,
                    unit_id: unit._id,
                    video_progress: {
                      watched_pct: watchedPercentage,
                      last_position: position,
                      deflated_progress_map: deflate(Uint8Array.from(viewMap)),
                    },
                  })
                    .then(() => unit.next && navigateToUnit(unit.next))
                    .finally(() => setLoading(false))
                }}
              >
                <Trans id="unit.viewer.action.video_complete">
                  Afgerond. Laten we verder gaan.
                </Trans>
              </Button>
            ) : (
              <Button
                type="primary"
                loading={loading}
                onClick={() => {
                  setLoading(true)
                  logActivity({
                    event_id,
                    unit_id: unit._id,
                    video_progress: {
                      watched_pct: watchedPercentage,
                      last_position: position,
                      deflated_progress_map: deflate(Uint8Array.from(viewMap)),
                    },
                  })
                    .then(() =>
                      navigate(
                        `${parent}/${encodeURIComponent(
                          unit.course?.slug || ''
                        )}`
                      )
                    )
                    .finally(() => setLoading(false))
                }}
              >
                <Trans id="unit.viewer.action.complete_course">
                  Opleiding voltooien
                </Trans>
              </Button>
            )}
          </>
        ) : (
          <>
            <Button type="primary" disabled>
              <Trans id="unit.viewer.action.go_to_next_unit">
                Naar het volgende deel
              </Trans>
            </Button>
            <ALink
              style={{ fontSize: 14, cursor: 'pointer', marginTop: -12 }}
              onClick={() => setProgressModalVisible(true)}
            >
              <QuestionCircleOutlined
                style={{ fontSize: 12, marginRight: 6 }}
              />
              <Trans id="unit.viewer.video.action.open_progress_modal">
                Niet duidelijk waarom je nog niet verder kan?
              </Trans>
            </ALink>
            <VideoProgressModal
              open={progressModalVisible}
              onClose={() => setProgressModalVisible(false)}
              allowChangingPlaybackRate={allowChangingPlaybackRate}
              seekToUnwatchedPart={goToUnwatchedPart}
              viewMap={viewMap}
            />
          </>
        )}
      </Space>
      {event_id && (
        <RemoteIntermediaryQuestionsModal
          event_id={event_id}
          open={intermediaryQuestionsModalVisible}
          unit={unit}
          onFinish={finishUpIntermediaryQuestions}
          questions={questions}
        />
      )}
      {!event_id && (
        <IntermediaryQuestionsModal
          open={intermediaryQuestionsModalVisible}
          unit={unit}
          refetchUnit={refetchUnit}
          onFinish={finishUpIntermediaryQuestions}
          onResetRequested={handleProgressReset}
          questions={questions}
        />
      )}
      <VideoQuestionModal
        open={questionModalOpen}
        onClose={() => setQuestionModalOpen(false)}
        unit={unit}
      />
    </Col>
  )
}
