import { Trans } from '@lingui/macro'
import { Avatar, Space, Tag } from 'antd'
import dayjs from 'dayjs'

import { Image } from 'apps/lms-front/src/generated/graphql'

import {
  Author,
  Card,
  Description,
  Flex,
  Image as ArticleImage,
  Link,
  Meta,
  Title,
} from './ArticleCard.style'

interface ArticleCardProps {
  date: Date
  time: number
  children: React.ReactNode
  user: {
    name: string
    avatar?: string
  }
  title: string
  image?: Image
  url?: string
}

export const ArticleCard = ({
  title,
  user,
  children,
  date,
  time,
  image,
  url = '#',
}: ArticleCardProps) => {
  return (
    <Card bodyStyle={{ height: '100%', opacity: date ? 1 : 0.5 }}>
      <Flex>
        <Link to={url} style={{ textDecoration: 'none' }}>
          {image && (
            <ArticleImage
              width={image.width}
              height={image.height}
              loading="lazy"
              src={image.url}
              alt={image.alt || title}
            />
          )}
          <Title>{title}</Title>
        </Link>
        <Description>{children}</Description>
        <Space size={'middle'}>
          <Avatar
            src={user.avatar}
            icon={user.name.slice(0, 1).toUpperCase()}
            size={'large'}
          />
          <div>
            <Author>{user.name}</Author>
            <Meta>
              {date ? (
                dayjs(date).format('DD MMMM YYYY')
              ) : (
                <Tag color="blue">
                  <Trans id="article.tag.draft">Concept</Trans>
                </Tag>
              )}{' '}
              · <Trans id="article.duration">{time} min</Trans>
            </Meta>
          </div>
        </Space>
      </Flex>
    </Card>
  )
}
