/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

export const exitFullscreen = () => {
  const elem = document.documentElement
  try {
    if (elem.exitFullscreen) {
      elem.exitFullscreen()
    } else if (elem.mozCancelFullScreen) {
      elem.mozCancelFullScreen()
    } else if (elem.webkitExitFullscreen) {
      elem.webkitExitFullscreen()
    } else if (elem.msExitFullscreen) {
      elem.msExitFullscreen()
    }
  } catch {
    //
  } finally {
    //
  }
}
