import { t } from '@lingui/macro'

const timestampToSeconds = (timestamp: string): number => {
  const parts = timestamp.split(':').map(Number)

  if (parts.length === 3) {
    const [hours, minutes, seconds] = parts
    return hours * 3600 + minutes * 60 + seconds
  } else if (parts.length === 2) {
    const [minutes, seconds] = parts
    return minutes * 60 + seconds
  } else {
    throw new Error(
      t({
        id: 'error.invalid_timestamp',
        message: 'Foutief formaat van tijdstip',
      })
    )
  }
}

export const transformTimestampsInVideoAnnotation = (
  node,
  index: number,
  onSeekTo: (seconds: number) => void
) => {
  const regex = /\b(?:(\d{1,2}):)?(\d{1,2}):(\d{2})\b/g

  if (node.type !== 'text') return

  const segments: React.ReactNode[] = []
  let lastIndex = 0
  let match: RegExpExecArray | null

  while ((match = regex.exec(node.data)) !== null) {
    // eslint-disable-next-line unicorn/prefer-string-slice
    const textBefore = node.data.substring(lastIndex, match.index)
    if (textBefore) {
      segments.push(textBefore)
    }

    // Construct the timestamp based on the matched groups
    const timestamp = match[1]
      ? `${match[1]}:${match[2]}:${match[3]}` // h:mm:ss format
      : `${match[2]}:${match[3]}` // mm:ss format

    // Add the timestamp wrapped in an <a> tag
    segments.push(
      <a
        key={index + lastIndex}
        href={`#time=${timestamp}`}
        onClick={(e) => {
          e.preventDefault()
          onSeekTo(timestampToSeconds(e.currentTarget.textContent || '00:00'))
        }}
      >
        {timestamp}
      </a>
    )

    lastIndex = regex.lastIndex
  }

  const textAfter = node.data.slice(Math.max(0, lastIndex))
  if (textAfter) {
    segments.push(textAfter)
  }

  return <>{segments}</>
}
