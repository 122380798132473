import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import {
  Form,
  Input,
  Button,
  PageHeader,
  notification,
  Upload,
  Card,
  Row,
  Col,
  Alert,
  Select,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Store } from 'antd/lib/form/interface'
import { UploadChangeParam } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  UpdateProfileMutation,
  UpdateProfileInput,
  FieldsQuery,
  FieldParent,
} from 'apps/lms-front/src/generated/graphql'
import { useAuth } from 'apps/lms-front/src/modules/auth/hooks/use-auth'

import { CustomFieldsFormSection } from '../../../shared/components/custom-fields-form-section/CustomFieldsFormSection'
import { errorNotifierFn } from '../../../shared/helpers/error-notifier'
import { PageProps } from '../../../shared/interfaces/page.interface'
import { Content } from '../../../shared/layout/Layout.style'
import { getBase64 } from '../../../shared/utils/get-base64'
import {
  FileType,
  uploadValidator,
} from '../../../shared/validators/upload-file-validator'
import UPDATE_PROFILE_MUTATION from '../../mutations/update-profile.graphql'

import FIELDS_QUERY from './../../../settings/queries/fields.graphql'

export const Profile = ({ route }: PageProps) => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const [form] = useForm()
  const [formDirty, setFormDirty] = useState<boolean>(false)
  const [uploading, setUploading] = useState<boolean>(false)
  const [updateProfile, { loading: updating }] =
    useMutation<UpdateProfileMutation>(UPDATE_PROFILE_MUTATION, {
      onCompleted: (user) => {
        if (user.updateProfile.lang)
          window.LMS.setLanguage(user.updateProfile.lang)
      },
    })

  const { data: fields } = useQuery<FieldsQuery>(FIELDS_QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  const client = useApolloClient()

  const handleChange = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setUploading(true)
      return
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, () => {
        setUploading(false)
        client.refetchQueries({ include: ['me'] })
      })
    }
  }

  const uploadButton = (
    <div>
      {uploading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>
        {uploading ? (
          <Trans id="action.uploading">Uploading</Trans>
        ) : (
          <Trans id="action.upload">Upload</Trans>
        )}
      </div>
    </div>
  )

  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={route.description}
        extra={[
          <Button onClick={() => navigate('/')} key="2">
            <Trans id="profile.go_back">Ga terug</Trans>
          </Button>,
          <Button
            disabled={!formDirty || updating}
            onClick={() => form.submit()}
            key="1"
            type="primary"
          >
            <Trans id="action.save">Opslaan</Trans>
          </Button>,
        ]}
      />
      <Content>
        <Card>
          <Form
            form={form}
            layout={'vertical'}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={user as Store}
            onFinish={(variables: UpdateProfileInput) => {
              updateProfile({
                variables,
                refetchQueries: ['me'],
                update(cache) {
                  cache.evict({ id: 'fetchUsers' })
                },
              })
                .then(() => {
                  notification.success({
                    message: t({
                      id: 'profile.update.success',
                      message: 'Profiel succesvol opgeslagen.',
                    }),
                  })
                })
                .catch(errorNotifierFn)
                .finally(() => setFormDirty(false))
            }}
            onFieldsChange={() => setFormDirty(true)}
            autoComplete="off"
          >
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item>
                  <Upload
                    name="file"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action={`${
                      import.meta.env.NX_BACKEND_URL
                    }/api/files/uploadProfilePicture`}
                    beforeUpload={uploadValidator(2, [
                      FileType.jpg,
                      FileType.png,
                      FileType.gif,
                    ])}
                    onChange={handleChange}
                    headers={{
                      Authorization: `Bearer ${localStorage.getItem(
                        'aa_lms_at'
                      )}`,
                      'x-academy-host': window.location.hostname,
                    }}
                  >
                    {user?.picture?.url && !uploading ? (
                      <img
                        src={user?.picture?.url}
                        alt="avatar"
                        style={{
                          width: '100%',
                        }}
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
              >
                <Form.Item
                  label={t({
                    id: 'profile.form.label.first_name',
                    message: 'Voornaam',
                  })}
                  name="firstName"
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: t({
                        id: 'profile.form.validation.first_name',
                        message: 'Gelieve je voornaam in te vullen',
                      }),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
              >
                <Form.Item
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                  label={t({
                    id: 'profile.form.label.name',
                    message: 'Naam',
                  })}
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: t({
                        id: 'profile.form.validation.name',
                        message: 'Gelieve je naam in te vullen',
                      }),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                lg={{ span: 8 }}
                xl={{ span: 12 }}
              />
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
              >
                <Form.Item
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                  label={t({
                    id: 'profile.form.label.email',
                    message: 'E-mailadres',
                  })}
                  name="email"
                  rules={[
                    {
                      type: 'email',
                      required: true,
                      message: t({
                        id: 'profile.form.validation.email',
                        message: 'Gelieve je e-mailadres in te geven',
                      }),
                    },
                  ]}
                >
                  <Input disabled readOnly autoComplete="off" />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
              >
                <Form.Item
                  label={t({
                    id: 'profile.form.label.password',
                    message: 'Wachtwoord',
                  })}
                  name="password"
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                >
                  <Input.Password
                    placeholder={t({
                      id: 'profile.form.placeholder.password',
                      message: 'Laat leeg om ongewijzigd te laten.',
                    })}
                    autoComplete="new-password"
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                lg={{ span: 8 }}
                xl={{ span: 12 }}
              />
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                lg={{ span: 8 }}
                xl={{ span: 12 }}
              >
                <Form.Item
                  label={t({
                    id: 'profile.form.label.language',
                    message: 'Taal',
                  })}
                  name="lang"
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                >
                  <Select
                    options={[
                      {
                        label: 'English',
                        value: 'en',
                      },
                      {
                        label: 'Nederlands',
                        value: 'nl-BE',
                      },
                      {
                        label: 'Français',
                        value: 'fr',
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                lg={{ span: 8 }}
                xl={{ span: 12 }}
              />
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                lg={{ span: 8 }}
                xl={{ span: 12 }}
              >
                <CustomFieldsFormSection
                  parent={FieldParent.User}
                  fields={fields?.fetchFields}
                  initialValues={user?.meta}
                  admin={false}
                  readonly
                />
              </Col>
            </Row>
            <Alert
              type="warning"
              showIcon={true}
              message={
                <Trans id="profile.form.warning">
                  <strong>Let op</strong>: sommige browsers overschrijven
                  automatisch naam- en e-mailvelden. Kijk dit formulier dus goed
                  na voor je een wijziging doorvoert.
                </Trans>
              }
            />
            <Row>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
                style={{ marginTop: 24 }}
              >
                <Form.Item wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
                  <Button
                    disabled={!formDirty || updating}
                    type="primary"
                    htmlType={'submit'}
                  >
                    <Trans id="action.save">Opslaan</Trans>
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Content>
    </>
  )
}
