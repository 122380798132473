import styled from 'styled-components'

const Bar = styled.div`
  position: sticky;
  z-index: 9999;
  top: 0;
  background-color: red;
  width: 100%;
  padding: 0.125rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  color: white;
  font-size: 12px;
`
export const EnvironmentBar = () => {
  if (APP_ENV === 'production') return
  if (APP_ENV === 'staging')
    return (
      <Bar style={{ backgroundColor: '#FFD700', color: '#1e2a78' }}>
        Staging environment - showing live data
      </Bar>
    )
  if (APP_ENV === 'development')
    return (
      <Bar style={{ backgroundColor: '#0074E4' }}>Development environment</Bar>
    )
  return APP_ENV === 'test' ? (
    <Bar style={{ backgroundColor: '#0074E4' }}>Test environment</Bar>
  ) : (
    <Bar>Unknown environment: be cautious</Bar>
  )
}
