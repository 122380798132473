/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/no-named-as-default */
import { ApolloProvider } from '@apollo/client'
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { Worker } from '@react-pdf-viewer/core'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import { APIProvider as MapsAPIProvider } from '@vis.gl/react-google-maps'
import { ConfigProvider } from 'antd'
import en_GB from 'antd/es/locale/en_GB'
import fr_FR from 'antd/es/locale/fr_FR'
import nl_BE from 'antd/es/locale/nl_BE'
import { locale } from 'dayjs'
import 'dayjs/locale/fr'
import 'dayjs/locale/nl-be'
import qs from 'query-string'
import { useEffect, useState } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { BrowserRouter } from 'react-router-dom'
import { ReactFlowProvider } from 'reactflow'
import 'slick-carousel/slick/slick.css'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

import { defaultLocale, dynamicActivate } from './i18n'
import './main.css'
import { TermsConditions } from './modules/auth/components/TermsConditions'
import { AuthProvider } from './modules/auth/providers/auth.provider'
import { EnvironmentBar } from './modules/core/components/EnvironmentBar'
import {
  ApolloCache,
  ApolloCacheContext,
  ApolloCachePersistor,
  GraphQLClient,
} from './modules/core/context/ApolloCache.context'
import { TitleProvider } from './modules/core/context/Title.context'
import { Router } from './modules/core/routes/router'

declare global {
  interface Window {
    LMS: {
      setLanguage: (language: string) => void
    }
  }
}

window.LMS = window.LMS || {}

const antdLocale = {
  'nl-BE': nl_BE,
  en: en_GB,
  fr: fr_FR,
}

export const Root = () => {
  const [lang, setLang] = useState<'nl-BE' | 'en' | 'fr' | 'dev'>(defaultLocale)

  useEffect(() => {
    // With this method we dynamically load the catalogs
    dynamicActivate(lang)
    locale(lang)
  }, [lang])

  useEffect(() => {
    window.LMS.setLanguage = (language: string) => {
      if (['nl-BE', 'en', 'fr', 'dev'].includes(language)) {
        setLang(language as 'nl-BE' | 'en' | 'fr' | 'dev')
        sessionStorage.setItem('aa_lms_lang', language)
      } else console.warn(`Language (${language}) not supported yet.`)
    }
  }, [setLang])

  return (
    <I18nProvider i18n={i18n}>
      <EnvironmentBar />
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
        <ApolloProvider client={GraphQLClient}>
          <ApolloCacheContext.Provider
            value={{
              cache: ApolloCache,
              cachePersistor: ApolloCachePersistor,
            }}
          >
            <HelmetProvider>
              <AuthProvider>
                <BrowserRouter>
                  <ConfigProvider locale={antdLocale[lang]}>
                    <SkeletonTheme baseColor="#e6e8ea" highlightColor="#e1e2e5">
                      <TitleProvider>
                        <QueryParamProvider
                          adapter={ReactRouter6Adapter}
                          options={{
                            searchStringToObject: qs.parse,
                            objectToSearchString: qs.stringify,
                          }}
                        >
                          <ReactFlowProvider>
                            <MapsAPIProvider
                              apiKey={import.meta.env.NX_GOOGLE_MAPS_API_KEY}
                            >
                              <Router />
                            </MapsAPIProvider>
                          </ReactFlowProvider>
                        </QueryParamProvider>
                        <TermsConditions />
                      </TitleProvider>
                    </SkeletonTheme>
                  </ConfigProvider>
                </BrowserRouter>
              </AuthProvider>
            </HelmetProvider>
          </ApolloCacheContext.Provider>
        </ApolloProvider>
      </Worker>
    </I18nProvider>
  )
}
